import React from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import { Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiGetNoAuth, ApiPost, ApiPut, ApiUpload, Bucket } from '../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import Swal from 'sweetalert2'
import moment from 'moment'
import logo from '../../../_metronic/assets/img/WhatsAppImage.jpeg'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


const ViewGenerateReceiptData = () => {
    const navigate = useNavigate()
    const [addVendor, setAddVendor] = React.useState<any>()
    const { register, handleSubmit, watch, control, reset, formState: { errors } } = useForm<any>();
    const [uploadedFile, setUploadedFile] = React.useState<any>("")
    const [countryName, setCountryName] = React.useState<any>("")
    const [city, setCity] = React.useState([])
    const [country, setCountry] = React.useState([])
    const [invoiceData, setInvoiceData] = React.useState<any>()
    const [isEdit, setIsEdit] = React.useState<any>(false)
    const [addrow, setAddRow] = React.useState<any>([{
        id: 1,
        description: '',
        qty: '',
        unitPrice: '',
        amount: '',
        discount: '',
        taxableAmount: '',
        VATRate: '',
        VATAmount: '',
        totalAmount: ''
    }])
    const [countryId, setCountryId] = React.useState<any>()
    const [accountDetailData, setAccountDetailData] = React.useState<any>()

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setAddVendor({ ...addVendor, [name]: value })
    }
    console.log(invoiceData)
    const handleSaveInvoice = async (i:any) => {
        let body:any = {
            id:invoiceData?._id,
            purchaseOrder : invoiceData?.purchaseOrder,
            billTo:accountDetailData,
            currency:invoiceData?.currency,
            TNC:invoiceData?.TNC,
            authorizedName:invoiceData?.authorizedName,
            authorizedDesignation:invoiceData?.authorizedDesignation,
            saveStatus:i,
            VATPercentage:Number(invoiceData?.VATPercentage),
            totalVAT:Number((invoiceData?.subTotal*invoiceData?.VATPercentage)/100).toFixed(2),
            outstandingAmount:Number(invoiceData?.subTotal + ((invoiceData?.subTotal*invoiceData?.VATPercentage)/100)).toFixed(2)
        }
        if(invoiceData.dueDate) body.dueDate = invoiceData.dueDate
        if(uploadedFile) body.signatureURL = uploadedFile
        await ApiPut(`admin/corporate_invoice`, body)
            .then((res) => {
                SuccessToast(res.data.message)
                navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }


    const onSubmit = async () => {
        const body = {
            id: window.location.pathname?.split('/')[3],
            purchaseOrder: invoiceData?.purchaseOrder,
            TNC: invoiceData?.TNC,
            authorizedName: invoiceData?.authorizedName,
            authorizedDesignation: invoiceData?.authorizedDesignation,
            issueDate: new Date(),
            dueDate: moment().add(14, 'days').format('YYYY-MM-DD'),
            totalAmount: totalAmountDue,
            subTotal: subTotal,
            totalVAT: totalVatAmount,
            totalDiscount: totalDiscount,
            outstandingAmount: totalAmountDue,
            saveStatus: 1,
            paymentStatus: Number(accountDetailData?.paymentStatus) ? Number(accountDetailData?.paymentStatus) : invoiceData?.paymentStatus,
            corporateId: accountDetailData?._id,
            pdfURL: "63c3b5c71570883e14e6b317/payment_receipt/1674013797626.jpg",
            signatureURL: uploadedFile,
            currency: "AED",
            startDate: accountDetailData?.corporate_subscription[0]?.startDate,
            endDate: accountDetailData?.corporate_subscription[0]?.endDate,
            paidAmount: 0,
            items: addrow,
            html: `<!DOCTYPE html>
            <html>
            
            <head>
                <style>
                    html {
                        zoom: 0.65;
                    }
                </style>
            </head>
            
            <body
                style="background-color:#e2e1e0;font-family: Open Sans, sans-serif;font-size:100%;font-weight:400;line-height:1.4;color:#000;">
                <div class="table-responsive"
                    style="max-width:900px;margin:50px auto 10px;background-color:#fff;padding:50px 30px;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;-webkit-box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);-moz-box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);">
            
            
                    <table style="width:100%;">
                        <tbody style="width:100%;">
                            <tr style="width:100%;">
                                <td style="width:50%;padding:20px; text-align: left;"><img style="max-width: 300px;"
                                        src="https://properties-storage-files.s3.me-south-1.amazonaws.com/pdf_logo.jpeg"
                                        alt="Propertise Logo"></td>
                                <td colspan="2" style="width:50%;padding:20px;vertical-align:top;text-align: right;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px">Propertise</span> FZCO</p>
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;">Address</span>
                                            Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</p>
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;">Tel:</span> +971 50 351 59 74
                                    </p>
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;">Email:</span>
                                            Admin@propertise.com</p>
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;">Website:</span>
                                            www.propertise.com</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            
                    <table style="width:100%; margin-bottom: 20px;">
                        <tbody style="width:100%;">
                            <tr>
                                <td colspan="2" style="padding:0 5px; width:42%; display: inline-block;">
                                    <p style="font-size:14px;margin:0 0 6px 0;"><span
                                            style="font-weight:bold;display:block;min-width:150px">Billed To</span></p>
                                    <p style="font-size:14px;margin:0 0 6px 0;"><span
                                            style="font-weight:bold;display:inline-block;">Company Name :</span>
                                        abcd1234567890</p>
                                    <p style="font-size:14px;margin:0 0 0 0;"><span
                                            style="font-weight:bold;display:inline-block;">City :</span> xyz</p>
                                    <p style="font-size:14px;margin:0 0 0 0;"><span
                                            style="font-weight:bold;display:inline-block;">Country :</span> xyz</p>
                                    <p style="font-size:14px;margin:0 0 0 0;"><span
                                            style="font-weight:bold;display:inline-block;">Address :</span> xyz</p>
                                    <p style="font-size:14px;margin:0 0 0 0;"><span
                                            style="font-weight:bold;display:inline-block;">TRN :</span> xyz</p>
                                    <p style="font-size:14px;margin:0 0 0 0;"><span
                                            style="font-weight:bold;display:inline-block;">Phone :</span> xyz</p>
                                </td>
                                <td colspan="2" style="padding:0 5px; width:20%; display: inline-block;">
                                    <p style="font-size:14px;margin:0 0 6px 0;"><span
                                            style="font-weight:bold;display:inline-block;min-width:150px">Invoice Issue
                                            Date</span><b style="font-weight:normal;margin:0">31/12/2022</b></p>
                                    <p style="font-size:14px;margin:0 0 6px 0;"><span
                                            style="font-weight:bold;display:inline-block;">Payment Due Date</span>
                                        31/12/2022</p>
                                </td>
                                <td colspan="2" style="padding:0 5px; width:18%; display: inline-block;">
                                    <p style="font-size:14px;margin:0 0 6px 0;"><span
                                            style="font-weight:bold;display:inline-block;min-width:150px">Invoice</span><b
                                            style="font-weight:normal;margin:0">ACCOUNT ID_</b></p>
                                    <p style="font-size:14px;margin:0 0 6px 0;"><span
                                            style="font-weight:bold;display:inline-block;min-width:146px">Purchase Order</span>
                                        abcd1234567890</p>
                                </td>
                                <td colspan="2" style="padding:0 5px; width:11%; display: inline-block;">
                                    <p style="font-size:14px;margin:0 0 6px 0;"><span
                                            style="font-weight:bold;display:inline-block;min-width:150px">Amount Due</span><b
                                            style="color:#007a59;font-weight:bold;margin:0;font-size: 20px;">5000 AED</b></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            
                    <table style="width:100%;margin-top: 10px;border-bottom: 2px solid #007a59;">
            
                        <tbody style="width:100%;">
                            <p style="font-size:14px;margin:0 0 6px 0;"><span
                                    style="font-weight:bold;display:block;min-width:150px">Invoice Detail</span></p>
                            <tr>
                                <td style="width:30%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px">Subscription Type : </span>
                                        Free Demo</p>
            
                                </td>
                                <td style="width:30%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;">Start Date : </span>
                                        11/1/2013</p>
            
                                <td style="width:30%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;">End Date : </span> 11/1/2013
                                    </p>
            
                                </td>
                            </tr>
                            <tr>
                                <td style="width:50%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px;"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px">Payment Status : </span>
                                        Partially Paid</p>
            
                                </td>
                            </tr>
                        </tbody>
                    </table>
            
            
                    <table style="width:100%;margin-bottom: 20px;">
            
                        <tbody style="width:100%;">
                            <tr>
                                <td style="width:6%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">ITEM NO</span>1</p>
                                </td>
                                <td style="width:10%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">DESCRIPTION</span> Khudiram</p>
                                </td>
                                <td style="width:4%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">QTY.</span> 200</p>
                                </td>
                                <td style="width:9%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">UNIT PRICE (AED)</span> 200 AED</p>
                                </td>
                                <td style="width:7%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">AMOUNT (AED)</span> 200 AED</p>
                                </td>
                                <td style="width:9%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">DISCOUNT (%)</span> 200</p>
                                </td>
                                <td style="width:11%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">TAXABLE AMOUNT(AED)</span> 200 AED
                                    </p>
                                </td>
                                <td style="width:10%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">VAT RATE(%)</span> 200</p>
                                </td>
                                <td style="width:10%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">VAT AMOUNT (AED)</span> 200 AED</p>
                                </td>
                                <td style="width:9%; padding:0 5px;display: inline-block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:13px;"><span
                                            style="display:block;font-weight:bold;font-size:10px;">TOTAL AMOUNT (AED) </span> 200 AED
                                    </p>
                                </td>
            
                            </tr>
                        </tbody>
                    </table>
            
                    <table style="width:100%;">
            
                        <tbody style="width:100%;">
                            <tr>
                                <td style="text-align: right; padding:0 5px;display: block;">
                                    <p style="margin:0 0 10px 0;padding:0 10px 0 0;font-size:14px"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;padding-right: 25px;">Sub
                                            Total</span>2000 AED</p>
            
                                </td>
                                <td style="text-align: right; padding:0 5px;display: block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;padding-right: 25px;">Total
                                            VAT</span> 2000 AED</p>
            
                                <td style="text-align: right; padding:0 5px;display: block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;padding-right: 25px;">Total
                                            Discount</span> 2000 AED</p>
            
                                </td>
                                <td style="text-align: right; padding:0 5px;display: block;">
                                    <p style="margin:0 0 10px 0;padding:0;font-size:14px"><span
                                            style="display:inline-block;font-weight:bold;font-size:13px;padding-right: 25px;">Total
                                            Amount Due</span> 2000 AED</p>
            
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style="width:100%;">
            
                        <tbody style="width:100%;">
                            <tr>
                                <td colspan="2" style="font-size:14px;padding:50px 15px 0 15px;">
                                    <strong style="display:block;margin:0 0 10px 0;">Terms & conditions</strong> Gorubathan, Pin/Zip
                                    - 735221, Darjeeling, West bengal, India<br>
                                </td>
                                <td colspan="2" style="font-size:14px;padding:50px 15px 0 15px;">
                                    <strong style="display:block;margin:0 0 10px 0;">Authorised Signatory</strong> Gorubathan<br>
                                    <strong style="display:block;margin:10px 0 10px 0;">Designation</strong> Gorubathan<br>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </body>
            
            </html>`
        }
        await ApiPut(`admin/corporate_invoice`, body)
            .then((res) => {
                SuccessToast(res.data.message)
                getDataByID()
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const handleAddRow = () => {
        setAddRow([...addrow, {
            id: addrow?.length + 1,
            description: '',
            qty: '',
            unitPrice: '',
            amount: '',
            discount: '',
            taxableAmount: '',
            VATRate: '',
            VATAmount: '',
            totalAmount: ''
        }]

        )
    }
    const getCountry = async () => {
        await ApiGetNoAuth('country')
            .then((res) => {
                setCountry(res?.data?.data)
                // const data = res?.data?.data?.filter((i: any) => i?.country === countryName).map((item: any) => item?._id)
                // setCountryId(data[0])
                // if (data[0]) {
                //     ApiGet(`country/cities?countryName=${data[0]}`)
                //         .then((res) => {
                //             setCity(res?.data?.data)
                //         })
                //         .catch((e) => {
                //             console.log('e', e)
                //         })
                // }

            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const handleCountryChnage = async (e: any) => {
        const { name, value } = e.target
        setAccountDetailData({ ...accountDetailData, 'country': e.target.value })
        callcountry(e.target.value)
        // const data = value.split('/')
        // setAccountDetailData()
        // await ApiGet(`country/cities?countryId=${data[0]}`)
        //     .then((res) => {
        //         setCity(res?.data?.data)
        //     })
        //     .catch((e) => {
        //         console.log('e', e)
        //     })
    }
    const callcountry = async(e: any) =>{
        await ApiGet(`country/cities?countryName=${e}`)
        .then((res) => {
            setCity(res?.data?.data)
        })
        .catch((e) => {
            console.log('e', e)
        })
    }

    const uploadFilelHandler = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setUploadedFile(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }
    const handleNumber = (Phone: any, e: any) => {
        let CountryCode = e.dialCode
        let PhoneNumber = Phone.split(CountryCode)[1]
        setAccountDetailData({
            ...accountDetailData,
            ['countryCode']: parseInt(CountryCode),
            ['phoneNumber']: parseInt(PhoneNumber),
        })
    }

    // React.useEffect(() => {
    //     reset(accountDetailData)
    // }, [accountDetailData])
    React.useEffect(() => {
        getCountry()

    }, [countryName])

    React.useEffect(() => {
        getDataByID()
        getCountry()
    }, [window.location.pathname?.split('/')[3]])

    const getDataByID = async () => {
        await ApiGet(`admin/corporate_invoice/${window.location.pathname?.split('/')[3]}`)
            .then((res) => {
                setInvoiceData(res?.data?.data)
                setAccountDetailData(res?.data?.data?.billTo)
                callcountry(res?.data?.data?.billTo?.country)
                // viewAccountDetail(res?.data?.data?.billTo)
                setAddRow(res?.data?.data?.items)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const viewAccountDetail = async (id: any) => {
        await ApiGet(`admin/corporate/${id}`)
            .then((res) => {
                setAccountDetailData(res?.data?.data[0])
                setCountryName(res?.data?.data[0]?.country?.charAt(0).toUpperCase() + res?.data?.data[0]?.country.slice(1))
            })
            .catch((e) => {
                console.log('e', e)
            })
    }


    const handleChange = (i: any, e: any) => {
        let newFormValues = [...addrow];
        newFormValues[i][e.target.name] = e.target.value;
        newFormValues[i]['id'] = i + 1
        newFormValues[i]['amount'] = (newFormValues[i]['qty'] * newFormValues[i]['unitPrice'] || 0);
        newFormValues[i]['taxableAmount'] = ((newFormValues[i]['amount'] - (newFormValues[i]['amount'] * newFormValues[i]['discount'] / 100)) || 0);
        newFormValues[i]['VATAmount'] = (((newFormValues[i]['taxableAmount'] * newFormValues[i]['VATRate'] / 100)) || 0);
        setAddRow(newFormValues);
    }

    const removeFeild = (i: any) => {
        let newFormValues = [...addrow];
        newFormValues.splice(i, 1);
        setAddRow(newFormValues)
    }

    const subTotal = addrow?.reduce(function (previousValue: any, currentValue: any) {
        return previousValue + currentValue.taxableAmount
    }, 0);

    const totalVatAmount = addrow?.reduce(function (previousValue: any, currentValue: any) {
        return previousValue + currentValue.VATAmount
    }, 0);

    const totalDiscount = addrow?.reduce(function (previousValue: any, currentValue: any) {
        return previousValue + currentValue.amount * currentValue.discount / 100
    }, 0)

    const totalAmountDue = parseInt(subTotal) + parseInt(totalVatAmount) + parseInt(totalDiscount)

    const deleteRow = async () => {
        await ApiDelete(`admin/corporate_invoice/${window.location.pathname?.split('/')[3]}`)
            .then((res) => {
                SuccessToast(res.data.message)
                navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    return (
        <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='mb-6'>
                    <a
                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_app'
                        onClick={() => navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)}
                    >
                        Back
                    </a>
                </div>
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div id='' className='app-container container-xxl pt-0 mt-0'>

                            <div className='mb-5 min-w-375px d-flex justify-content-between w-auto'>

                            </div>
                        </div>
                        <div className='d-flex align-items-center mt-5 mb-5'>
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                {invoiceData?.saveStatus === 0 && <div
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                    onClick={()=>handleSaveInvoice(0)}
                                >
                                    Save
                                </div>}
                                {/* <div
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                    onClick={() => {
                                        Swal
                                            .fire({
                                                text: '<p class="text-start m-0"> Are you sure you want to delete this invoice?</p>',
                                                html: '<p class="text-start m-0"> Are you sure you want to delete this invoice?</p>',
                                                icon: 'warning',
                                                showConfirmButton: true,
                                                confirmButtonColor: '#D72852',
                                                confirmButtonText: 'Yes',
                                                showCancelButton: true,
                                                // cancelButtonColor: "transparent",
                                                cancelButtonText: 'Cancel',
                                            })
                                            .then((res) => {
                                                if (res.isConfirmed) {
                                                    deleteRow()
                                                }
                                            })
                                    }}
                                >
                                    Delete
                                </div> */}
                                {invoiceData?.saveStatus === 0 && <button
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                    type='submit'
                                    onClick={()=>handleSaveInvoice(1)}
                                >
                                    Submit Invoice
                                </button>}
                                {invoiceData?.saveStatus  === 1  ? <div
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                    onClick={() => navigate(`/corporate/issue-invovice/${invoiceData?._id}`)}
                                >
                                    Issue Receipt
                                </div> : ''}
                            </div>
                        </div>
                        {invoiceData?.saveStatus === 0 ? <div className='card card-flush '>
                            <>
                                <Row className='py-2'>
                                    <Col lg={8}>
                                        <div className='mx-10 mb-10 mt-10' >
                                            <img src={Bucket+"pdf_logo.jpeg"} style={{ height: '150px' }} />
                                            {/* <img src='/media/logos/BrandW1.svg' style={{ width: '150px', height: '150px' }} /> */}
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <h1 className='mx-10 mb-10 mt-10'> TAX INVOICE</h1>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item mb-5'>
                                                <div className='d-flex'>
                                                    <div className='me-10'>
                                                        <p className='m-0'>Propertise: <b>FZCO</b></p>
                                                        <p className='m-0'>Address: <b> Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</b></p>
                                                        <p className='m-0'>Tel:<b>+971 50 351 59 74 </b></p>
                                                        <p className='m-0'>  Email:  <b> Admin@propertise.com </b></p>
                                                        <p className='m-0'>Website: <b>www.propertise.com </b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col lg={8}>
                                        <h3 className='mx-10 mb-10 mt-10'>
                                            Bill To
                                        </h3>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Company Name
                                                </h6>
                                                <div className='min-w-200px'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        name='companyName'
                                                        value={accountDetailData?.companyName}                                                            
                                                        onChange={(e) => setAccountDetailData({ ...accountDetailData, 'companyName': e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <div className='pe-5 w-50 d-flex align-items-center'>
                                                    <div>
                                                        <h6 className='text-muted m-0 me-2  min-w-150px'>
                                                            Country
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <select
                                                            name='country'
                                                            className='form-select form-select-solid'
                                                            onChange={handleCountryChnage}
                                                            value={accountDetailData?.country}
                                                        >
                                                            <option value='' disabled selected>
                                                                Select country
                                                            </option>
                                                            {country?.map((v: any) => (
                                                                <option value={v.country}>{v.country}</option>
                                                            ))}
                                                        </select>
                                                        {errors.country && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    </div>
                                                </div>
                                                <div className='w-50 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        City
                                                    </h6>
                                                    <div>
                                                        <select
                                                            name='city'
                                                            className='form-select form-select-solid'
                                                            value={accountDetailData?.city}
                                                            onChange={(e) => setAccountDetailData({ ...accountDetailData, 'city': e.target.value })}

                                                        >
                                                            <option value='' disabled selected>
                                                                Select city
                                                            </option>
                                                            {city?.map((v: any) => (
                                                                <option value={v.name}>{v.city}</option>
                                                            ))}
                                                        </select>
                                                        {errors.city && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    </div>

                                                </div>
                                            </div>
                                            {errors.companyName && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Address
                                                </h6>
                                                <div className='min-w-200px'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        name='address'
                                                        value={accountDetailData?.address}
                                                        onChange={(e) => setAccountDetailData({ ...accountDetailData, 'address': e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    TRN
                                                </h6>
                                                <div className='min-w-200px'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        name='TRN'
                                                        value={accountDetailData?.TRN}
                                                        onChange={(e) => setAccountDetailData({ ...accountDetailData, 'TRN': e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            {errors.TRN && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            <div className='payment-receipt-item contract_number d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2  min-w-150px'>
                                                    Phone
                                                </h6>
                                                <ReactPhoneInput
                                                    // country={countryName}
                                                    placeholder='Enter phone number'
                                                    onChange={(phone, e) => handleNumber(phone, e)}
                                                    value={`${accountDetailData?.countryCode}${accountDetailData?.phoneNumber}`}
                                                // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>

                                        <div className='min-w-200px mx-10 mb-10 mt-10'>
                                            <div className='payment-receipt-item mb-5'>
                                                <div className='d-flex'>
                                                    <div className='me-10'>
                                                        <p className='m-0'>Invoice: <b>{invoiceData?.id}</b></p>
                                                        <p className='m-0'>Invoice Issue Date: <b> {invoiceData?.saveStatus === 0 ? 'Draft' : moment(invoiceData?.issueDate).format('DD/MM/YYYY')}  </b></p>
                                                        <div className='d-flex mb-5'>   <p className='m-0'>Purchase Order </p>: <input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        name='TRN'
                                                        value={invoiceData?.purchaseOrder}
                                                        onChange={(e) => setInvoiceData({ ...invoiceData, 'purchaseOrder': e.target.value })}
                                                    />
                                                        </div>
                                                        {errors.purchaseOrder && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}

                                                        <div className='d-flex mb-5'> <p className='m-0'>   Payment Due Date </p>:  {isEdit === false ? <b> {moment(invoiceData?.dueDate).format('DD/MM/YYYY')}</b> : <input
                                                            type='date'
                                                            {...register("dueDate", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='dueDate'
                                                            onChange={(e) => setInvoiceData({ ...invoiceData, 'dueDate': e.target.value })}

                                                        />}
                                                            <AiFillEdit color='#007a59' style={{ cursor: 'pointer' }}
                                                                fontSize={20} onClick={() => setIsEdit(true)} /></div>
                                                        <p className='m-0'>Amount Due:  <b> {invoiceData?.outstandingAmount} </b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <h3 className='mx-10 mb-10 mt-10'>
                                    Invoice Details
                                </h3>
                                <Row className='py-2'>
                                    <div className='d-flex justify-content-start mb-5'>
                                        <span className='mx-10 mt-5'><b>Subscription Type: </b>{invoiceData?.corporate_subscription[0]?.subscriptionType === 0 ? 'Free Demo' : 'Paid Subscription'}</span>
                                        <span className='mx-10 mt-5'><b>Start Date: </b>{moment(invoiceData?.startDate).format('DD/MM/YYYY')}  </span>
                                        <span className='mx-10 mt-5'><b> End Date: </b>{moment(invoiceData?.endDate).format('DD/MM/YYYY')} </span>
                                        {invoiceData?.items?.length>0 && invoiceData?.items[0].description=="Subscription Price" ? <span className='mx-10 mt-5'><b> Duration: </b>{invoiceData?.items[0]?.dayDuration}  Days </span>:null}
                                    </div>
                                    <div className='d-flex justify-content-start'>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5 mx-10'>

                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Payment Status
                                            </h6>
                                            <select
                                                {...register("paymentStatus", { required: true })}
                                                className='form-select form-select-solid'
                                                name='paymentStatus'
                                                onChange={(e) => setAccountDetailData({ ...accountDetailData, 'paymentStatus': e.target.value })}
                                                value={invoiceData?.paymentStatus
                                                }
                                                disabled
                                            >
                                                <option selected value=''>
                                                    Select
                                                </option>
                                                <option value={0}>
                                                    Not Paid
                                                </option>
                                                <option value={1}>
                                                    Partially Paid
                                                </option>
                                                <option value={2}>
                                                    Paid
                                                </option>
                                            </select>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5 mx-10'>

                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Currency 
                                            </h6>
                                            <select
                                                {...register("currency", { required: true })}
                                                className='form-select form-select-solid'
                                                name='currency'
                                                onChange={(e) => setInvoiceData({ ...invoiceData, 'currency': e.target.value })}
                                                value={invoiceData?.currency}
                                                // disabled
                                            >
                                                <option selected value=''>
                                                    Select
                                                </option>
                                                <option value={"AED"}>
                                                    AED
                                                </option>
                                                <option value={"USD"}>
                                                    USD
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className='d-flex justify-content-start mb-5 mx-10'>
                                        <div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={handleAddRow}

                                        >
                                            + Add Item
                                        </div>
                                    </div> */}
                                    <div className='card-body pt-0 table-responsive mt-5'>
                                        <table
                                            className='table align-middle table-row-dashed fs-6 gy-5'
                                            id='kt_ecommerce_sales_table'
                                        >
                                            <thead>
                                                <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                                    <th className='min-w-100px'>Item No.</th>
                                                    <th className='min-w-100px'>Description</th>
                                                    <th className='min-w-100px'>Qty.</th>
                                                    <th className='min-w-100px'>Unit Price</th>
                                                    <th className='min-w-100px'>Amount</th>
                                                    <th className='min-w-100px'> Discount (%)</th>
                                                    {/* <th className='min-w-100px'> Duration</th> */}
                                                    <th className='min-w-100px'> Taxable Amount</th>
                                                    {/* <th className='min-w-100px'> VAT Rate</th>
                                                    <th className='min-w-100px'> VAT Amount (AED)</th>
                                                    <th className='min-w-100px'> Total Amount (AED)</th> */}
                                                </tr>
                                            </thead>
                                            {addrow?.map((i: any, index: any) => {
                                                return (<tr className='mb-5'>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            placeholder=''
                                                            name='id'
                                                            value={index + 1}
                                                            disabled={true}
                                                            style={{ width: '100px' }}
                                                            onChange={e => handleChange(index, e)}
                                                            
                                                        />
                                                    </td>
                                                    <td className=''>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            placeholder=''
                                                            name='description'
                                                            value={i?.description}
                                                            style={{ width: '100px' }}
                                                            onChange={e => handleChange(index, e)}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='number'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            value={i?.qty}
                                                            name='qty'
                                                            onChange={e => handleChange(index, e)} style={{ width: '100px' }}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='number'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            value={i?.unitPrice}
                                                            name='unitPrice'
                                                            onChange={e => handleChange(index, e)} style={{ width: '100px' }}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            name='amount'
                                                            value={(i?.qty * i?.unitPrice)}
                                                            // onChange={e => handleChange(index, e)}
                                                            style={{ width: '100px' }}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            name='discount'
                                                            value={i?.discountInPercentage}
                                                            onChange={e => handleChange(index, e)}
                                                            style={{ width: '100px' }}
                                                            disabled
                                                        />
                                                    </td>
                                                    {/* <td>
                                                        <input
                                                            type='text'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            name='dayDuration'
                                                            value={i?.dayDuration}
                                                            onChange={e => handleChange(index, e)}
                                                            style={{ width: '100px' }}
                                                            disabled
                                                        />
                                                    </td> */}
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            name='taxableAmount'
                                                            style={{ width: '100px' }}
                                                            value={i?.taxableAmount}
                                                            disabled
                                                        />
                                                    </td>
                                                    {/* <td>
                                                        <input
                                                            type='text'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            name='VATRate'
                                                            value={i?.VATRate}
                                                            onChange={e => handleChange(index, e)}
                                                            style={{ width: '100px' }}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            name='VATAmount'
                                                            style={{ width: '100px' }}
                                                            value={(i?.taxableAmount * i?.VATRate / 100)}
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type='text'
                                                            className='form-control mx-2'
                                                            placeholder=''
                                                            name='totalAmount'
                                                            style={{ width: '100px' }}
                                                            value={i?.VATAmount && i?.taxableAmount + i?.VATAmount}
                                                            disabled
                                                        />
                                                    </td> */}
                                                    {/* <td>
                                                        <div>
                                                            <AiFillDelete color='#007a59' style={{ cursor: 'pointer' }}
                                                                fontSize={20} onClick={() => removeFeild(i)} />
                                                        </div>
                                                    </td> */}
                                                </tr>)
                                            })}

                                        </table>
                                    </div>
                                </Row>
                                <Row className='py-2 mx-10'>
                                    <Col lg={8}>

                                    </Col>
                                    <Col lg={4}>
                                        <div className='min-w-200px mx-10 mb-10 mt-10'>
                                            <div className='payment-receipt-item mb-5'>

                                                <div className='me-10'>
                                                    <div className='d-flex justify-content-between' >  <p className='m-0'>Sub Total</p> <span> {invoiceData?.subTotal}</span> </div>
                                                    <div className='d-flex justify-content-between' > <p className='m-0 d-flex justify-content-between'>Total VAT(<input
                                                        type='text'
                                                        className='form-control form-control-solid'
                                                        style={{"height": "0", "width": "70px"}}
                                                        name='VATPercentage'
                                                        value={invoiceData?.VATPercentage}
                                                        min={0}
                                                        max={100}
                                                        onChange={(e) => {
                                                            if(parseInt(e.target.value)>100){
                                                                ErrorToast("Vat percentage is more than 100")
                                                            }else{
                                                                setInvoiceData({ ...invoiceData, 'VATPercentage': e.target.value })}}
                                                            }
                                                            
                                                    />)%
                                                    {/* ({invoiceData?.VATPercentage}%)  */}
                                                    </p> <span>{((invoiceData?.subTotal*invoiceData?.VATPercentage)/100)?.toFixed(2)}</span></div>
                                                    <div className='d-flex justify-content-between mb-4' > <p className='m-0'> Total Discount </p><span>{invoiceData?.totalDiscount}</span></div>
                                                    <div className='d-flex justify-content-between' >  <p className='m-0'>  Total Amount Due</p> <span>{(invoiceData?.subTotal + ((invoiceData?.subTotal*invoiceData?.VATPercentage)/100)).toFixed(2)}</span> </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='py-2 mx-10'>
                                    <Col lg={8}>
                                        <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                            Terms & Conditions
                                        </h6>
                                        <textarea
                                            aria-rowspan={3}
                                            className='form-control form-control-solid'
                                            name='TNC'
                                            value={invoiceData?.TNC}
                                            onChange={(e) => setInvoiceData({ ...invoiceData, 'TNC': e.target.value })}
                                        />
                                    </Col>
                                    <Col lg={4}>
                                        <div className='min-w-200px mx-10 mb-10 '>
                                            <div className='payment-receipt-item mb-5'>

                                                <div className=''>
                                                    <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                        Authorised Signatory
                                                    </h6>
                                                    <div className='mb-2'>
                                                        <input
                                                            type='text'
                                                            {...register("name", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='name'
                                                            placeholder='Name'
                                                            value={invoiceData?.authorizedName}
                                                            onChange={(e) => setInvoiceData({ ...invoiceData, 'authorizedName': e.target.value })}
                                                        />
                                                        {errors.name && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    </div>
                                                    <div className='mb-2'>
                                                        <input
                                                            type='text'
                                                            {...register("designation", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='designation'
                                                            placeholder='Designation'
                                                            value={invoiceData?.authorizedDesignation}
                                                            onChange={(e) => setInvoiceData({ ...invoiceData, 'authorizedDesignation': e.target.value })}
                                                        />
                                                    </div>
                                                    {errors.designation && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    <button
                                                        className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-200px'>
                                                        Upload Signature
                                                        <input
                                                            type='file'
                                                            {...register("contract", { required: true })}
                                                            className='form-control form-control-solid position-absolute '
                                                            placeholder=''
                                                            name='contract'
                                                            onChange={uploadFilelHandler}
                                                            // onChange={uploadFilelHandler}
                                                            style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                                        />
                                                    </button>
                                                    {errors.contract && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        </div>
                            : 
                            <div className='card card-flush '>
                                <>
                                    <Row className='py-2'>
                                        <Col lg={8}>
                                            <div className='mx-10 mb-10 mt-10' >
                                                <img src={Bucket+"pdf_logo.jpeg"} style={{ height: '150px' }} />
                                                {/* <img src='/media/logos/BrandW1.svg' style={{ width: '150px', height: '150px' }} /> */}
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <h1 className='mx-10 mb-10 mt-10'> TAX INVOICE</h1>
                                            <div className='min-w-200px mx-10'>
                                                <div className='payment-receipt-item mb-5'>
                                                    <div className='d-flex'>
                                                        <div className='me-10'>
                                                            <p className='m-0'>Propertise: <b>FZCO</b></p>
                                                            <p className='m-0'>Address: <b> Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</b></p>
                                                            <p className='m-0'>Tel:<b>+971 50 351 59 74 </b></p>
                                                            <p className='m-0'>  Email:  <b> Admin@propertise.com </b></p>
                                                            <p className='m-0'>Website: <b>www.propertise.com </b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='py-2'>
                                        <Col lg={8}>
                                            <h3 className='mx-10 mb-10 mt-10'>
                                                Bill To
                                            </h3>
                                            <div className='min-w-200px mx-10'>
                                                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                        Company Name
                                                    </h6>
                                                    <div className='min-w-200px'>
                                                        <b>{accountDetailData?.companyName}</b>

                                                    </div>
                                                </div>
                                                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                    <div className='pe-5 w-50 d-flex align-items-center'>
                                                        <div>
                                                            <h6 className='text-muted m-0 me-2  min-w-150px'>
                                                                Country
                                                            </h6>
                                                        </div>
                                                        <div>
                                                            <b>{accountDetailData?.country}</b>
                                                        </div>
                                                    </div>
                                                    <div className='w-50 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 me-2'>
                                                            City
                                                        </h6>
                                                        <div>
                                                            <b>{accountDetailData?.city}</b>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                        Address
                                                    </h6>
                                                    <div className='min-w-200px'>
                                                        <b>{accountDetailData?.address}</b>
                                                    </div>
                                                </div>
                                                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                        TRN
                                                    </h6>
                                                    <div className='min-w-200px'>
                                                        <b>{accountDetailData?.TRN}</b>
                                                    </div>
                                                </div>
                                                <div className='payment-receipt-item contract_number d-flex align-items-center mb-5'>
                                                    <h6 className='text-muted m-0 me-2  min-w-150px'>
                                                        Phone
                                                    </h6>
                                                    <b>+{`${accountDetailData?.countryCode} ${accountDetailData?.phoneNumber}`}</b>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4}>

                                            <div className='min-w-200px mx-10 mb-10 mt-10'>
                                                <div className='payment-receipt-item mb-5'>
                                                    <div className='d-flex'>
                                                        <div className='me-10'>
                                                            <p className='m-0'>Invoice456: <b>ACCOUNT ID_{invoiceData?.id}</b></p>
                                                            <p className='m-0'>Invoice Issue Date: <b> {invoiceData?.saveStatus === 0 ? 'Draft' : moment(invoiceData?.issueDate).format('DD/MM/YYYY')}  </b></p>
                                                            <div className='d-flex mb-5'>   <p className='m-0'>Purchase Order </p>:
                                                                <b>{invoiceData?.purchaseOrder}</b>
                                                            </div>
                                                            <div className='d-flex mb-5'> <p className='m-0'>   Payment Due Date </p>:  {isEdit === false ? <b> {moment(invoiceData?.dueDate).format('DD/MM/YYYY')}</b> : <input
                                                                type='date'
                                                                {...register("companyName", { required: true })}
                                                                className='form-control form-control-solid'
                                                                name='companyName'
                                                                onChange={handleChnage}

                                                            />}
                                                                <AiFillEdit color='#007a59' style={{ cursor: 'pointer' }}
                                                                    fontSize={20} onClick={() => setIsEdit(true)} /></div>
                                                            <p className='m-0'>Amount Due:  <b>   {invoiceData?.totalAmount} </b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <h3 className='mx-10 mb-10 mt-10'>
                                        Invoice Details
                                    </h3>
                                    <Row className='py-2'>
                                        <div className='d-flex justify-content-start mb-5'>
                                            <span className='mx-10 mt-5'><b>Subscription Type: </b>{invoiceData?.corporate_subscription?.subscriptionType === 0 ? 'Free Demo' : 'Paid Subscription'}</span>
                                            <span className='mx-10 mt-5'><b>Start Date: </b>{moment(invoiceData?.startDate).format('DD/MM/YYYY')}  </span>
                                            <span className='mx-10 mt-5'><b> End Date: </b>{moment(invoiceData?.endDate).format('DD/MM/YYYY')} </span>
                                            {invoiceData?.items?.length>0 && invoiceData?.items[0].description=="Subscription Price" ? <span className='mx-10 mt-5'><b> Duration: </b>{invoiceData?.items[0]?.dayDuration}  Days </span>:null}
                                        </div>
                                        <div className='d-flex justify-content-start'>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5 mx-10'>

                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Payment Status
                                                </h6>
                                                <b>{invoiceData?.paymentStatus
                                                    === 0 ? ' Not Paid' : invoiceData?.paymentStatus
                                                        === 1 ? ' Partially Paid' : invoiceData?.paymentStatus === 2 ? 'Paid' : '-'}</b>
                                            </div>

                                            <div className='payment-receipt-item d-flex align-items-center mb-5 mx-10'>

                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Currancy
                                                </h6>
                                                <b>{invoiceData?.currency}</b>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start mb-5 mx-10'>

                                        </div>
                                        <div className='card-body pt-0 table-responsive mt-5'>
                                            <table
                                                className='table align-middle table-row-dashed fs-6 gy-5'
                                                id='kt_ecommerce_sales_table'
                                            >
                                                <thead>
                                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                                    <th className='min-w-100px'>Item No.</th>
                                                        <th className='min-w-100px'>Description</th>
                                                        <th className='min-w-100px'>Qty.</th>
                                                        <th className='min-w-100px'>Unit Price </th>
                                                        <th className='min-w-100px'>Amount </th>
                                                        <th className='min-w-100px'> Discount (%)</th>
                                                        {/* <th className='min-w-100px'> Duration</th> */}
                                                        <th className='min-w-100px'> Taxable Amount</th>
                                                        {/* <th className='min-w-100px'> VAT Rate</th>
                                                        <th className='min-w-100px'> VAT Amount (%)</th>
                                                        <th className='min-w-100px'> Total Amount (AED)</th> */}
                                                    </tr>
                                                </thead>
                                                {addrow?.map((i: any, index: any) => {
                                                    return (<tr className='mb-5'>
                                                        <td data-kt-ecommerce-order-filter='order_id' className=''>
                                                            {index + 1}
                                                        </td>
                                                        <td >
                                                            {i?.description}

                                                        </td>
                                                        <td>
                                                            {i?.qty}
                                                        </td>
                                                        <td>
                                                            {i?.unitPrice}

                                                        </td>
                                                        <td>
                                                            {(i?.qty * i?.unitPrice)}

                                                        </td>
                                                        <td>
                                                            {i?.discountInPercentage}
                                                        </td>
                                                        {/* <td>
                                                            {i?.dayDuration}
                                                        </td> */}
                                                        <td>
                                                            {i?.taxableAmount}

                                                        </td>
                                                        {/* <td>
                                                            {i?.VATRate}
                                                        </td>
                                                        <td>
                                                            {(i?.taxableAmount * i?.VATRate / 100).toFixed(2)}
                                                        </td>
                                                        <td>
                                                            {i?.VATAmount && i?.taxableAmount + i?.VATAmount}
                                                        </td> */}
                                                    </tr>)
                                                })}
                                            </table>
                                        </div>
                                    </Row>
                                    <Row className='py-2 mx-10'>
                                        <Col lg={8}>

                                        </Col>
                                        <Col lg={4}>
                                            <div className='min-w-200px mx-10 mb-10 mt-10'>
                                                <div className='payment-receipt-item mb-5'>

                                                <div className='me-10'>
                                                    <div className='d-flex justify-content-between' >  <p className='m-0'>Sub Total</p> <span> {invoiceData?.subTotal}</span> </div>
                                                    <div className='d-flex justify-content-between' > <p className='m-0'>Total VAT({invoiceData?.VATPercentage}%) </p> <span>{invoiceData?.totalVAT}</span></div>
                                                    <div className='d-flex justify-content-between mb-4' > <p className='m-0'> Total Discount </p><span>{invoiceData?.totalDiscount}</span></div>
                                                    <div className='d-flex justify-content-between' >  <p className='m-0'>  Total Amount Due</p> <span>{invoiceData?.outstandingAmount}</span> </div>

                                                </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='py-2 mx-10'>
                                        <Col lg={8}>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                Terms & Conditions
                                            </h6>
                                            <b>{invoiceData?.TNC}</b>

                                        </Col>
                                        <Col lg={4}>
                                            <div className='min-w-200px mx-10 mb-10 '>
                                                <div className='payment-receipt-item mb-5'>
                                                    <div className=''>
                                                        <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                            Authorised Signatory
                                                        </h6>
                                                        <div className='mb-2'>
                                                            <b> {invoiceData?.authorizedName}</b>
                                                        </div>
                                                        <div className='mb-2'>
                                                            {invoiceData?.authorizedDesignation}
                                                        </div>
                                                        <a
                                                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                            href={`${Bucket}${invoiceData?.signatureURL
                                                                }`} target="_blank">
                                                            View Signature
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            </div>
                        }
                    </form>
                </div >
            </div>

        </div>
    )
}

export default ViewGenerateReceiptData
