import React from 'react'

const Troubleshooting = () => {
  return (
    <div>
      Troubleshooting
    </div>
  )
}

export default Troubleshooting
