import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers';
interface ButtonProps {
    show: any;
    handleClose: any
    accountFeature: any
    CheckAll: any
    accountfeature: any
    checkAll: any
    setVendor: any
    vendor: any
    setFacility: any
    facility: any
    setService: any
    service: any
    selectServices: any
    allService:any
    selectAllService:any
    vendorext:any
    venderAll:any
    facilityAll:any
                facilityext:any
                permissionflag:any
}

const AccountFeaturePopup = ({ show,
    permissionflag,facilityAll,facilityext,handleClose, accountFeature, CheckAll, accountfeature, checkAll, setVendor, vendor, setFacility, facility, setService, service, selectServices,allService,selectAllService,vendorext,venderAll }: ButtonProps) => {
    
    return (
        <div>
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-600px'
                show={show}
                onHide={() => {
                    handleClose()
                }}
                backdrop={true}
                size='sm'
            >
                <div className='modal-header'>
                    <h2>Set Account Access Features</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className='modal-body py-lg-5 px-lg-5'>
                    <div className='d-flex justify-content-between mb-2'>
                        <h5> Property Management  </h5>
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input className="form-check-input" type="checkbox" name='propertyManagement' checked={checkAll} disabled={permissionflag} onChange={(e) => CheckAll(e)} value='propertyManagement' id="flexCheckDefault" style={{ cursor: 'pointer' }} /> All
                        </div>
                    </div>
                    <div >
                        <ul >
                            {
                                accountfeature?.map((topping: any, index: any) => {
                                    return (
                                        <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                            <li>  {topping?.title}</li>
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" name={topping?.name} value={index} disabled={permissionflag} checked={topping.checked} onChange={(e) => accountFeature(index, e)} type="checkbox" id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    )

                                })
                            }
                            {/* <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                <li>  Property Registration</li>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" name='property' checked={accountfeature?.property ? true :false} onChange={(e)=>accountFeature(e)} type="checkbox"  id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                <li> Tenancy Management</li>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" name='tenancy' checked={accountfeature?.tenancy  ? true :false} type="checkbox" onChange={(e)=>accountFeature(e)}  id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                <li> Tenant Management </li>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" name='tenant' type="checkbox"  onChange={(e)=>accountFeature(e)} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                            </div>

                            <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                <li> Rent Management</li>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" name='rent' type="checkbox"  onChange={(e)=>accountFeature(e)} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                <li>  Announcement Management </li>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" name='announcement' type="checkbox" onChange={(e)=>accountFeature(e)}  id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                            </div> */}
                        </ul>
                    </div>
                </div>
                <div className='modal-body py-lg-5 px-lg-5'>
                    <div className='d-flex justify-content-between mb-2'>
                        <h5> Vendor Management  </h5>
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input className="form-check-input" name='vendorList' type="checkbox" checked={venderAll} disabled={permissionflag} onChange={(e) => vendorext(e)} id="flexCheckDefault" style={{ cursor: 'pointer' }} /> All
                        </div>
                    </div>
                    <div >
                        <ul >
                            <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                <li>  Vendor List Management</li>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" name='vendorList' type="checkbox" checked={venderAll} disabled={permissionflag} onChange={(e) => vendorext(e)} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='modal-body py-lg-5 px-lg-5'>
                    <div className='d-flex justify-content-between mb-2'>
                        <h5> Facility Management  </h5>
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input className="form-check-input" name='service' type="checkbox" checked={facilityAll} disabled={permissionflag} onChange={(e) => facilityext(e)} id="flexCheckDefault" style={{ cursor: 'pointer' }} /> All
                        </div>
                    </div>
                    <div >
                        <ul >
                            <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                <li>   Services List Management</li>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" name='service' type="checkbox" checked={facilityAll} disabled={permissionflag} onChange={(e) => facilityext(e)} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='modal-body py-lg-5 px-lg-5'>
                    <div className='d-flex justify-content-between mb-2'>
                        <h5> Request Management  </h5>
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={allService} disabled={permissionflag}  onChange={(e) => selectAllService(e)} style={{ cursor: 'pointer' }} /> All
                        </div>
                    </div>
                    <div >
                        <ul >
                            {
                                service?.map((topping: any, index: any) => {
                                    return (
                                        <div className='d-flex align-items-center justify-content-between gap-2 mb-3 gap-lg-3'>
                                            <li>  {topping?.title   }</li>
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" name={topping?.name} value={index} disabled={permissionflag} checked={topping.checked} onChange={(e) => selectServices(index, e)} type="checkbox" id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    )

                                })
                            }
                        </ul>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AccountFeaturePopup
