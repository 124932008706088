import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';

const Job = () => {
    const navigate = useNavigate()
    const [page, setPage] = useState<any>(1)
    const [pageLimit, setPageLimit] = useState<any>(1)
    const [jobListData, setJobListData] = useState<any>()
    const [requestId, setRequestId] = React.useState<any>()
    const [requestSource, setRequestSource] = React.useState<any>()
    const [requestStatus, setRequestStatus] = React.useState<any>()
    const [jobId, setJobId] = React.useState<any>()
    const [count, setCount] = React.useState<any>()

    const getJobData = async () => {
        const body = {
            page: page,
            limit: 10,
            serviceRequestId: requestId ? requestId : null,
            jobId: jobId ? jobId : null,
            corporateId: window.location.pathname?.split('/')[4],
            requestType: requestSource ? Number(requestSource) : null,
            requestStatus: requestStatus ? Number(requestStatus) : null
        }
        await ApiPost(`admin/service_job/overview`, body)
            .then((res) => {
                setJobListData(res?.data?.data?.service_job_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((e) => {
                console.log(e)
            })
    }
    const getCountData = async () => {
        await ApiGet(`admin/service_job/overview/count?corporateId=${window.location.pathname?.split('/')[4]}`)
            .then((res) => {
                setCount(res?.data?.data)
            })
            .catch((e) => {
                console.log(e)
            })
    }


    React.useEffect(() => {
        getJobData()
        getCountData()
    }, [page, jobId, requestId, requestSource, requestStatus])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }

    function timeConvert(n: any) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ":" + rminutes
    }
    return (
        <div>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/account-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Account Details
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/payment-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4  ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Payments
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/user-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Users
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/property-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Propertise
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenancy-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenancies
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenant-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenants
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {

                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Troubleshooting
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[4]}`)

                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/job/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4  active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Job
                                </a>
                            </li>
                        </ul>
                    </div>
                    <Row>
                        <Col lg={8}>
                            <div className='card-header align-items-center py-5  gap-md-2 d-flex' style={{ justifyContent: 'space-between' }}>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        Total Jobs:
                                        <span className='' style={{ color: 'black' }}>
                                            {count?.serviceJobRequestOverallCount}
                                        </span>
                                    </h5>
                                </div>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        Not Started:
                                        <span className='' style={{ color: 'black' }}>
                                            {count?.serviceJobNotStarted}
                                        </span>
                                    </h5>
                                </div>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        In-Progress:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {count?.serviceJobStarted}
                                        </span>
                                    </h5>
                                </div>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        Completed:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {count?.serviceCompleted}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='mb-10'>
                        <Row>
                            <Col lg={3} md={3} className="d-flex align-items-center">
                                <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                    Request ID
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-solid solid'
                                    placeholder='Request ID'
                                    name='request_id'
                                    onChange={(e) => setRequestId(e.target.value)}
                                    style={{ backgroundColor: "#fff" }}
                                />
                            </Col>
                            <Col lg={3} md={3} className="d-flex align-items-center">
                                <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                    Job ID
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-solid solid'
                                    placeholder='Job ID'
                                    name='job_id'
                                    onChange={(e) => setJobId(e.target.value)}
                                    style={{ backgroundColor: "#fff" }}
                                />
                            </Col>
                            <Col lg={3} md={3} className="d-flex align-items-center">
                                <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                    Request Source
                                </label>
                                <select
                                    name='request_source'
                                    className='form-select form-control-solid'
                                    onChange={(e) => setRequestSource(e.target.value)}
                                >
                                    <option selected value=''>
                                        Select
                                    </option>
                                    <option value={2}>
                                        Internal
                                    </option>
                                    <option value={1}>
                                        External
                                    </option>
                                    <option value={3}>
                                        Marketplace
                                    </option>
                                    <option value={0}>
                                        Tenant
                                    </option>
                                </select>
                            </Col>
                            <Col lg={3} md={3} className="d-flex align-items-center">
                                <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                    Request Status
                                </label>
                                <select
                                    name='request_status '
                                    className='form-select form-control-solid'
                                    onChange={(e) => setRequestStatus(e.target.value)}
                                >
                                    <option selected value=''>
                                        Select
                                    </option>
                                    <option value={0}>
                                        Draft
                                    </option>
                                    <option value={1}>
                                        Under Approval
                                    </option>
                                    <option value={1}>
                                        Pending Approval
                                    </option>
                                    <option value={2}>
                                        Recalled
                                    </option>
                                    <option value={3}>
                                        Rejected
                                    </option>
                                    <option value={4}>
                                        Approved
                                    </option>
                                </select>
                            </Col>
                        </Row>
                    </div>
                    <div className='card card-flush'>

                        <div className='card-body pt-0 table-responsive mt-5'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                        <th className='min-w-100px'> Request ID</th>
                                        <th className='min-w-100px'>Job ID</th>
                                        <th className='min-w-70px'>Service Name</th>
                                        <th className='min-w-70px'> Development </th>
                                        <th className='min-w-70px'>  Floor /Cluster</th>
                                        <th className='min-w-70px'> Unit</th>
                                        <th className='min-w-70px'>Service Status</th>
                                        <th className='min-w-70px'>Assigned To</th>
                                        <th className='min-w-70px'>Job Urgency</th>
                                        <th className='min-w-70px'> Schedule   </th>
                                        <th className='min-w-70px'>Handled By </th>
                                        <th className='min-w-70px'>Request Source</th>

                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {/* <tbody className='fw-semibold text-gray-600'> */}
                                        {jobListData?.map((f: any, i: any) => {
                                            return (
                                                <tr>
                                                    <td className='text-center'>{f?.service_request?.id}</td>
                                                    <td className='text-center'>{f?.id}</td>

                                                    {/* <td className='text-center' style={{ cursor: 'pointer' }} onClick={() => f?.service_request?.requestType === 1 ? navigate(`/request-management/service/external-service-details/${f?.service_request?._id}`) : navigate(`/request-management/service/create-request-service/service-detail/scope-detail/${f?.service_request?.id}`)}><a style={{ textDecoration: 'underline', color: ' #1890ff' }}>{f?.id}</a></td> */}
                                                    <td className='text-center'>{f?.main_service[0]?.name}&{f?.sub_service[0]?.name}</td>
                                                    <td className='text-center'>{f?.building && f?.building[0]?.name}  {f?.community && f?.community[0]?.name}</td>
                                                    <td className='text-center'>{f?.community.length > 0 ? f?.community && f?.community[0]?.cluster[0]?.name : f?.community && f?.building[0]?.floor[0]?.name}</td>
                                                    <td className='text-center'>{f?.unit[0]?.unitNo}</td>
                                                    <td className='text-center'>{f?.service_request
                                                        .requestStatus === 0 ? 'Draft' :
                                                        f?.service_request.requestStatus === 1 ? 'Under Approval & Pending Approval' :
                                                            f?.service_request.requestStatus === 2 ? 'Recalled' :
                                                                f?.service_request.requestStatus === 3 ? 'Rejected' :
                                                                    f?.service_request.requestStatus === 4 ? 'Approved' :
                                                                        f?.service_request.requestStatus === 5 ? 'vendor confirmation' :
                                                                            f?.service_request.requestStatus === 6 ? 'Accepted' :
                                                                                f?.service_request.requestStatus === 7 ? 'Booked' :
                                                                                    f?.service_request.requestStatus === 8 ? 'variation request' : ''
                                                    }</td>
                                                    <td className='text-center'>{f?.staff[0]?.name}</td>
                                                    <td className='text-center'>{f?.jobUrgency === 0 ? 'Standard' : 'Emergency'}</td>
                                                    <td className='text-center'>{f?.schedule?.preferScheduleDate && moment(f?.schedule[0]?.preferScheduleDate).format('DD/MM/YYYY')} - {f?.schedule?.preferScheduleTime && timeConvert(f?.schedule?.preferScheduleTime)}</td>
                                                    <td className='text-center'>{f?.service_request?.requestType === 2 ? 'Intrenal Team' : f?.vendor[0]?.companyName}</td>
                                                    <td className='text-center'>{f?.service_request?.requestType === 2 ? 'Intrenal' : 'External'}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                    {/* </tbody> */}
                                </tbody>
                            </table>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                            </div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='kt_ecommerce_sales_table_paginate'
                                >
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        pageClassName="paginate_button page-item +"
                                        pageLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        previousClassName="paginate_button page-item previous disabled"
                                        nextClassName="paginate_button page-item next"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageLimit}
                                        previousLabel="<"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Job
