import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers';
interface ButtonProps {
    show: any;
    handleClose: any
    suspendAccountBtn:any
    accountId:any
    setSuspendData:any
    suspendData:any
}
const SuspendAccount = ({ show, handleClose,suspendAccountBtn,accountId,setSuspendData,suspendData}: ButtonProps) => {
    return (
        <div>
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-600px'
                show={show}
                onHide={() => {
                    handleClose()
                }}
                backdrop={true}
                size='sm'
            >
                <div className='modal-header'>
                    <h2> Account Suspension  </h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className='modal-body py-lg-5 px-lg-5'>
                    <div className='d-flex justify-content-between mb-2'>
                        <h5> You are about to suspend Account [{accountId}], before you can continue,
                            you must select the reason for suspension:  </h5>

                    </div>
                    <div>
                        <ul style={{listStyle: "none"}}>
                            <div className='d-flex align-items-center justify-content-start gap-2 mb-3 gap-lg-3'>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" type="radio" name='reason' onChange={(e)=>setSuspendData({...suspendData,[e.target.name]:e.target.value})} value={0} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                                <li>  Free Demo Expired   </li>

                            </div>
                            <div className='d-flex align-items-center justify-content-start gap-2 mb-3 gap-lg-3'>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input className="form-check-input" type="radio" name='reason' onChange={(e)=>setSuspendData({...suspendData,[e.target.name]:e.target.value})} value={1} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                </div>
                                <li> Overdue Payment </li>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='d-flex justify-content-center mb-5'>
                    <div
                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                        onClick={() => suspendAccountBtn()}
                    >
                       Suspend Account
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SuspendAccount
