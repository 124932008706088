import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData'
import { Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

const Buildings = () => {
  const [count, setCount] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [propertyType, setPropertyType] = useState<any>()
  const [unitType, setUnitType] = useState<any>()
  const [unitNo, setUnitNo] = useState<any>()
  const [watchType, setWatchtype] = useState<any>()
  const [rating, setRating] = useState<any>()
  const [occupy, setOccupy] = useState<any>()
  const [bedrooms, setBedrooms] = useState<any>()
  const [buildingManager, setBuildingManager] = useState<any>()
  const [buildingId,setBuildingId] = useState<any>()
  const [propertyManager, setPropertyManager] = useState<any>()

  const [page, setPage] = useState<any>(1)
  const intl = useIntl()
  const navigate = useNavigate()
  const getCount = () => {
    ApiGet(`admin/unit/home/count?corporateId=${window.location.pathname?.split('/')[4]}`)
      .then((res) => {
        setCount(res?.data?.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  const getData = () => {
    let body = {
      page: page,
      limit: 10,
      unitType: unitType,
      searchUnitNo: unitNo,
      premiseNo: propertyType,
      watchType: 'building',
      rating: Number(rating),
      occupy: Number(occupy),
      bedrooms: Number(bedrooms),
      buildingManager: buildingManager,
      buildingId: buildingId,
      propertyManager: propertyManager,
      corporateId: window.location.pathname?.split('/')[4],
    }
    ApiPost('admin/unit/home/get', body)
      .then((res) => {
        setTableData(res?.data?.data?.unit_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  useEffect(() => {
    getCount()
    getData()
    setPage(1)
  }, [page, unitType, unitNo, propertyType, rating, occupy, bedrooms, buildingManager, propertyManager,buildingId])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }
  return (
    <div>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/account-detail/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Account Details
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/payment-detail/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4  ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Payments
                </a>
              </li>

              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/user-detail/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Users
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate('/corporate/property-detail')
                }}
              >
                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Propertise
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/tenancy-detail/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Tenancies
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/tenant-detail/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Tenants
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/request/request/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Requests
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {

                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Troubleshooting
                </a>
              </li>
            </ul>
          </div>
          <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/property-detail/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Overall Properties
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/property-detail/buildings/${window.location.pathname?.split('/')[3]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 active ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Buildings
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/corporate/property-detail/communities/${window.location.pathname?.split('/')[4]}`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                  Communities
                </a>
              </li>
            </ul>
          </div>

        </div>

        <div id='kt_app_content' className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className='app-container container-xxl'>
            <Row>
              <Col lg={6}>
                <div
                  className='card-header align-items-center py-5  gap-md-2 d-flex'
                  style={{ justifyContent: 'flex-start' }}
                >
                  <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                    <h6 className='gray-500 mx-10'>
                      Buildings{' '}
                      <span className='mx-2' style={{ color: 'black' }}>
                        {count?.building_count}
                      </span>
                    </h6>
                  </div>
                  {/* <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                      <h6 className='gray-500 mx-10'>
                        Communities : <span className='fw-semibold'>{count[0]?.totalZones}</span>
                      </h6>
                    </div> */}
                  <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                    <h6 className='gray-500 mx-10'>
                      Units{' '}
                      <span className='mx-2' style={{ color: 'black' }}>
                        {count?.unit_count}
                      </span>
                    </h6>
                  </div>
                </div>
              </Col>
              <Col lg={6}></Col>
            </Row>

            <div
              className='card-header align-items-center py-5 gy-3 row'
              style={{ justifyContent: 'flex-start' }}
            >
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <div>
                  <select
                    className='form-select '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-dropdown-parent='#kt_menu_631f08e971923'
                    data-allow-clear='true'
                    onChange={(e) => setBuildingId(e.target.value)}
                  >
                    <option value='' selected>
                      Development
                    </option>
                    {
                      tableData.map((v: any, i: any) => {
                        return (
                          <option value={v?.building[0]?._id}>{v?.building[0]?.name}</option>
                       ) })
                    }
                  
                  </select>
                </div>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <div>
                  <input
                    type='text'
                    className='form-control '
                    placeholder='Property ID'
                    name='unitNo'
                    onChange={(e) => setPropertyType(e.target.value)}
                  />
                </div>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <div>
                  <select
                    className='form-select '
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-dropdown-parent='#kt_menu_631f08e971923'
                    data-allow-clear='true'
                    onChange={(e) => setUnitType(e.target.value)}
                  >
                    <option value='' selected>
                      Unit Type
                    </option>
                    <option value='apartment'>Apartment</option>
                    <option value='penthouse'>Penthouse</option>
                    <option value='commonArea'>Common Area</option>
                    <option value='other'>Other</option>
                  </select>
                </div>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <div>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Unit No.'
                    name='unitNo'
                    onChange={(e) => setUnitNo(e.target.value)}
                  />
                </div>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  onChange={(e) => setBuildingManager(e.target.value)}
                >
                  <option value='' selected>
                    Building Manager
                  </option>
                  <option value='John'>John</option>
                  <option value='Max'>Max</option>
                  <option value='Root'>Root</option>
                </select>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option selected value=''>
                    Rating
                  </option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                </select>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  onChange={(e) => setBedrooms(e.target.value)}
                >
                  <option value='' selected>
                    Bedrooms
                  </option>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                  <option value='5'>5</option>
                  <option value='6'>6</option>
                  <option value='7'>7</option>
                  <option value='8'>8</option>
                  <option value='9'>9</option>
                  <option value='10'>10</option>
                </select>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  onChange={(e) => setPropertyManager(e.target.value)}

                >
                  <option value='' selected>
                    Property Manager
                  </option>
                  <option value='John'>John</option>
                  <option value='Max'>Max</option>
                  <option value='Root'>Root</option>
                </select>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                <select
                  className='form-select'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  onChange={(e) => setOccupy(e.target.value)}
                >
                  <option value='' selected>
                    Occupancy
                  </option>
                  <option value='1'>Occupied</option>
                  <option value='0'>Vaccant</option>
                </select>
              </div>
            </div>
            <div className='card card-flush'>
              <div className='card-body pt-0 table-responsive mt-5'>
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-center min-w-100px'>#</th>
                      <th className='text-center min-w-100px'> Development Name </th>
                      <th className='text-center min-w-100px'>Floor</th>
                      <th className='text-center min-w-70px'>Property ID </th>
                      <th className='text-center min-w-100px'> Unit Type </th>
                      <th className='text-center min-w-100px'> Unit No. </th>
                      <th className='text-center min-w-100px'>Bedrooms</th>
                      <th className='text-center min-w-100px'>Occupancy</th>
                      <th className='text-center min-w-100px'>Building Manager</th>
                      <th className='text-center min-w-100px'>Property Manager</th>
                      <th className='text-center min-w-100px'>Rating</th>
                      <th className='text-end min-w-100px'></th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {tableData?.length ? (
                      tableData.map((v: any, i: any) => {
                        return (
                          <>
                            <tr>
                              <td className='text-center'>{(((page - 1) * 10) + (i + 1))}</td>
                              <td data-kt-ecommerce-order-filter='order_id' className='text-center'>
                                {v?.building[0]?.name}
                              </td>
                              <td className='text-center'>{v?.floor[0]?.name}</td>
                              <td className='text-center' data-order='Denied'>
                                {v?.premiseNo}
                              </td>
                              <td className='text-center'>
                                {v?.unitType === 'town_house'
                                  ? 'TownHouse'
                                  : v?.unitType === 'other'
                                    ? 'Other'
                                    : v?.unitType === 'common_area'
                                      ? 'Common Area'
                                      : v?.unitType === 'villa'
                                        ? 'Villa'
                                        : v?.unitType === 'apartment'
                                          ? 'Apartment'
                                          : v?.unitType === 'penthouse'
                                            ? 'Penthouse'
                                            : ''}
                              </td>
                              <td className='text-center' data-order='2022-09-09'>
                                {v?.unitNo}
                              </td>
                              <td className='text-center' data-order='2022-09-11'>
                                {v?.bedrooms}
                              </td>
                              <td className='text-center' data-order='2022-09-11'>
                                {v?.occupy === 0 ? 'Vacant ' : 'Occupied'}
                              </td>
                              <td className='text-center' data-order='2022-09-11'>
                                {v?.building[0]?.managerId}
                              </td>
                              <td className='text-center' data-order='2022-09-11'>
                                {v?.managerId}
                              </td>
                              <td className='text-center' data-order='2022-09-11'>
                                <span className='svg-icon svg-icon-2' style={{ color: '#ffad0f' }}>
                                  <svg
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                      fill='currentColor'
                                    ></path>
                                  </svg>
                                </span>{' '}
                                {v?.rating}
                              </td>
                            </tr>
                          </>
                        )
                      })
                    ) : (
                      <tr><td colSpan={12} className="100 text-center"> No Data Found</td></tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='row my-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      pageClassName="paginate_button page-item +"
                      pageLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      previousClassName="paginate_button page-item previous disabled"
                      nextClassName="paginate_button page-item next"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageLimit}
                      previousLabel="<"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Buildings
