import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiPost, ApiPut, ApiUpload, Bucket } from '../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast'
import Swal from 'sweetalert2'
import moment from 'moment'
import logo from '../../../_metronic/assets/img/WhatsAppImage.jpeg'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const ViewInvoiceData = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<any>();
    const [uploadedFile, setUploadedFile] = React.useState<any>("")
    const [document, setDocument] = React.useState<any>()
    const [invoiceData, setInvoiceData] = React.useState<any>()
    const [invoice, setInvoice] = React.useState<any>()
    const [receiptData, setReceiptData] = React.useState<any>()


    const onSubmit = async () => {
        if(Number(receiptData?.amountDue) <  Number(receiptData?.paidAmount)) {
            console.log('amountDue', invoiceData?.amountDue)
            return ErrorToast("Paid Amount bigger than Due Amount")
        }

        const body = {
            id: receiptData?._id,
            corporateInvoiceId: receiptData?.corporateInvoiceId,
            corporateId: receiptData?.corporateId,
            authorizedName: receiptData?.authorizedName,
            authorizedDesignation: receiptData?.authorizedDesignation,
            currency: receiptData?.currency,
            saveStatus: 1,
            signatureURL: uploadedFile?uploadedFile:receiptData?.signatureURL,
            comment: receiptData?.comment,
            totalAmount: receiptData?.amountDue,
            paidAmount: Number(receiptData?.paidAmount),
            outstandingAmount: Number(receiptData?.amountDue - receiptData?.paidAmount),
            amountDue: receiptData?.amountDue,
            issueDate: new Date(),
            paymentDate: receiptData?.paymentDate,
            paymentMethod: Number(receiptData?.paymentMethod),
            document: document?[document]:receiptData?.document,
            
        }
        await ApiPut(`admin/corporate_receipt`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)
            })
            .catch((e) => {
                console.log('e', e)
                ErrorToast(e?.message)
            })
    }
    React.useEffect(() => {
        getReceiptDataById()

    }, [])

    const getReceiptDataById = async () => {
        await ApiGet(`admin/corporate_receipt/${window.location.pathname?.split('/')[3]}`)
            .then((res) => {
                console.log('res', res)
                setReceiptData(res?.data?.data)
                getDataByID(res?.data?.data?.corporateInvoiceId)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const getDataByID = async (id: any) => {
        await ApiGet(`admin/corporate_invoice/${id}`)
            .then((res) => {
                setInvoiceData(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const uploadFilelHandler = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setUploadedFile(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }
    const uploadDocument = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setDocument(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }
    const saveReceipt = async () => {
        if(Number(receiptData?.amountDue) <  Number(receiptData?.paidAmount)) {
            console.log('amountDue', invoiceData?.amountDue)
            return ErrorToast("Paid Amount bigger than Due Amount")
        }
        console.log("sdfsdfsdf")

        const body = {
            id: receiptData?._id,
            corporateInvoiceId: receiptData?.corporateInvoiceId,
            corporateId: receiptData?.corporateId,
            authorizedName: receiptData?.authorizedName,
            authorizedDesignation: receiptData?.authorizedDesignation,
            currency: receiptData?.currency,
            saveStatus: 0,
            signatureURL: uploadedFile?uploadedFile:receiptData?.signatureURL,
            comment: receiptData?.comment,
            totalAmount: receiptData?.amountDue,
            paidAmount: Number(receiptData?.paidAmount),
            outstandingAmount: Number(receiptData?.amountDue - receiptData?.paidAmount),
            amountDue: receiptData?.amountDue,
            issueDate: new Date(),
            paymentDate: receiptData?.paymentDate,
            paymentMethod: Number(receiptData?.paymentMethod),
            document: document?[document]:receiptData?.document,
        }
        console.log(body)
        await ApiPut(`admin/corporate_receipt`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)

            })
            .catch((e) => {
                console.log('e', e)
                ErrorToast(e?.message)
            })
    }

    const deleteReceipt = async () => {
        await ApiDelete(`admin/corporate_receipt/${receiptData?._id}`)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)

            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    return (
        <div>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='d-flex align-items-center mt-5 mx-10 mb-5'>
                        <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            onClick={() => navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)}
                        >
                            Back
                        </a>
                    </div>
                    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div id='' className='app-container container-xxl pt-0 mt-0'>

                                <div className='mb-5 min-w-375px d-flex justify-content-between w-auto'>

                                </div>
                            </div>
                            <div className='d-flex align-items-center mt-5 mx-10 mb-5'>
                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                      {receiptData?.saveStatus === 0 &&  <div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={() => saveReceipt()}
                                        >
                                            Save
                                        </div>}
                                        {receiptData?.saveStatus === 0 &&<div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={() => {
                                                Swal
                                                    .fire({
                                                        text: '<p class="text-start m-0"> Are you sure you want to delete this receipt?</p>',
                                                        html: '<p class="text-start m-0"> Are you sure you want to delete this receipt?</p>',
                                                        icon: 'warning',
                                                        showConfirmButton: true,
                                                        confirmButtonColor: '#D72852',
                                                        confirmButtonText: 'Yes',
                                                        showCancelButton: true,
                                                        // cancelButtonColor: "transparent",
                                                        cancelButtonText: 'Cancel',
                                                    })
                                                    .then((res) => {
                                                        if (res.isConfirmed) {
                                                            deleteReceipt()
                                                        }
                                                    })
                                            }}
                                        >
                                            Delete
                                        </div>}
                                      {receiptData?.saveStatus === 0 &&<button
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            type='submit'
                                        >
                                            Submit Receipt
                                        </button>}
                                    </div>
                                </div>
                            <div className='card card-flush '>
                             
                                {receiptData?.saveStatus === 0 ? <>

                                    <Row className='py-2'>
                                        <Col lg={8}>
                                            <div className='mx-10 mb-10 mt-10' >
                                            <img src={Bucket+"pdf_logo.jpeg"} style={{  height: '150px' }} />                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <h3 className='mx-10 mb-10 mt-10'>    PAYMENT RECEIPT     </h3>
                                            <div className='min-w-200px mx-10'>
                                                <div className='payment-receipt-item mb-5'>
                                                    <div className='d-flex'>
                                                        <div className='me-10'>
                                                            <p className='m-0'>Propertise: <b>FZCO</b></p>
                                                            <p className='m-0'>Address: <b> Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</b></p>
                                                            <p className='m-0'>Tel:<b>+971 50 351 59 74 </b></p>
                                                            <p className='m-0'>  Email:  <b> Admin@propertise.com </b></p>
                                                            <p className='m-0'>Website: <b>www.propertise.com </b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='py-2'>
                                        <Col lg={8}>
                                            <div className='min-w-200px mx-10'>
                                                <div className='payment-receipt-item mb-5'>
                                                    <div className='d-flex'>
                                                        <div className='me-10'>
                                                            <p className='m-0'>Receipt #: <b> {receiptData?.id}   </b></p>
                                                            <p className='m-0 mb-6'>Invoice Issue Date: <b>{receiptData?.saveStatus === 0 ? ' Draft' : moment(receiptData?.issueDate).format('DD/MM/YYYY')} </b></p>
                                                            <div className='d-flex mb-5 align-items-center'>    <p className='m-0 min-w-100px'>Payment Date:  </p><input
                                                                type='date'
                                                                {...register("date", { required: true })}
                                                                className='form-control form-control-solid'
                                                                value={moment(receiptData?.paymentDate).format('YYYY-MM-DD')}
                                                                name='date'
                                                                onChange={(e) => setReceiptData({ ...receiptData, 'paymentDate': e.target.value })}

                                                            /></div>
                                                            {errors.date && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                            <div className='d-flex mb-5 align-items-center'>    <p className='m-0 min-w-100px'>Amount Paid :  </p>AED <input
                                                                type='text'
                                                                {...register("amountPaid", { required: true })}
                                                                className='form-control form-control-solid'
                                                                name='amountPaid'
                                                                value={Number(receiptData?.paidAmount)}
                                                                onChange={(e) => setReceiptData({ ...receiptData, 'paidAmount': e.target.value })}

                                                            /></div>
                                                            {errors.amountPaid && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                            <div className='d-flex mb-5 align-items-center'>   <p className='m-0 min-w-150px'>  Payment Method:</p>    <select
                                                                {...register("paymentMethod", { required: true })}
                                                                className='form-select form-select-solid'
                                                                name='paymentMethod'
                                                                value={receiptData?.paymentMethod}
                                                                onChange={(e) => setReceiptData({ ...receiptData, 'paymentMethod': e.target.value })}

                                                            >
                                                                <option selected value=''>
                                                                    Select
                                                                </option>
                                                                <option value={0}>
                                                                    Bank Transfer
                                                                </option>
                                                                <option value={1}>
                                                                    Cheque
                                                                </option>
                                                                <option value={2}>
                                                                    Cash
                                                                </option>
                                                            </select></div>
                                                            {errors.paymentMethod && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                            <p className='m-0'>Outstanding Amount : <b>AED  {receiptData?.amountDue - receiptData?.paidAmount}</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4}>

                                            <div className='min-w-200px mx-10 mb-10 mt-10'>
                                                <div className='payment-receipt-item mb-5'>
                                                    <div className='d-flex'>
                                                        <div className='me-10'>
                                                            <p className='m-0'>Invoice: <b>{invoiceData?.id}</b></p>
                                                            <p className='m-0'>Amount Due:  <b> {receiptData?.amountDue} </b></p>
                                                            <p className='m-0'>Currency:  <b> {receiptData?.currency} </b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='py-2 mx-10'>
                                        <Col lg={8}>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                Comments
                                            </h6>
                                            <textarea
                                                aria-rowspan={3}
                                                className='form-control form-control-solid'
                                                name='comment'
                                                value={receiptData?.comment}
                                                onChange={(e) => setInvoice({ ...invoice, 'comment': e.target.value })}
                                            />
                                            <div className='mt-10'>
                                                <button
                                                    className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-200px'>
                                                    Upload Document
                                                    <input
                                                        type='file'
                                                        // {...register("document", { required: true })}
                                                        className='form-control form-control-solid position-absolute '
                                                        placeholder=''
                                                        name='document'
                                                        onChange={uploadDocument}
                                                        // onChange={uploadFilelHandler}
                                                        style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                                    />
                                                </button>
                                                {errors.document && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className='min-w-200px mx-10 mb-10 '>
                                                <div className='payment-receipt-item mb-5'>

                                                    <div className=''>
                                                        <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                            Authorised Signatory
                                                        </h6>
                                                        <div className='mb-2'>
                                                            <input
                                                                type='text'
                                                                {...register("authorizedName", { required: true })}
                                                                className='form-control form-control-solid'
                                                                name='authorizedName'
                                                                value={receiptData?.authorizedName}
                                                                placeholder='Name'
                                                                onChange={(e) => setReceiptData({ ...receiptData, 'authorizedName': e.target.value })}
                                                            />
                                                        </div>
                                                        {errors.authorizedName && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                        <div className='mb-2'>
                                                            <input
                                                                type='text'
                                                                {...register("authorizedDesignation", { required: true })}
                                                                className='form-control form-control-solid'
                                                                name='authorizedDesignation'
                                                                value={receiptData?.authorizedDesignation}
                                                                placeholder='Authorized Designation'
                                                                onChange={(e) => setReceiptData({ ...receiptData, 'authorizedDesignation': e.target.value })}
                                                            />
                                                        </div>
                                                        {errors.authorizedDesignation && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                        <button
                                                            className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-200px'>
                                                            Upload Signature
                                                            <input
                                                                type='file'
                                                                // {...register("contract", { required: true })}
                                                                className='form-control form-control-solid position-absolute '
                                                                placeholder=''
                                                                name='contract'
                                                                onChange={uploadFilelHandler}
                                                                // onChange={uploadFilelHandler}
                                                                style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                                            />
                                                        </button>
                                                        {errors.contract && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                    : <>

                                        <Row className='py-2'>
                                            <Col lg={8}>
                                                <div className='mx-10 mb-10 mt-10' >
                                                <img src={Bucket+"pdf_logo.jpeg"} style={{  height: '150px' }} />                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <h3 className='mx-10 mb-10 mt-10'>    PAYMENT RECEIPT     </h3>
                                                <div className='min-w-200px mx-10'>
                                                    <div className='payment-receipt-item mb-5'>
                                                        <div className='d-flex'>
                                                            <div className='me-10'>
                                                                <p className='m-0'>Propertise: <b>FZCO</b></p>
                                                                <p className='m-0'>Address: <b> Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</b></p>
                                                                <p className='m-0'>Tel:<b>+971 50 351 59 74 </b></p>
                                                                <p className='m-0'>  Email:  <b> Admin@propertise.com </b></p>
                                                                <p className='m-0'>Website: <b>www.propertise.com </b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='py-2'>
                                            <Col lg={8}>
                                                <div className='min-w-200px mx-10'>
                                                    <div className='payment-receipt-item mb-5'>
                                                        <div className='d-flex'>
                                                            <div className='me-10'>
                                                                <p className='m-0'>Receipt #: <b> {receiptData?.id}   </b></p>
                                                                <p className='m-0 mb-6'>Invoice Issue Date: <b>{receiptData?.saveStatus === 0 ? ' Draft' : moment(receiptData?.issueDate).format('DD/MM/YYYY')} </b></p>
                                                                <div className='d-flex mb-5 align-items-center'>    <p className='m-0 min-w-100px'>Payment Date:  </p>
                                                                    <b>{moment(receiptData?.paymentDate).format('YYYY-MM-DD')}</b></div>

                                                                <div className='d-flex mb-5 align-items-center'>    <p className='m-0 min-w-100px'>Amount Paid :  </p>  <b>{Number(receiptData?.paidAmount)}</b></div>
                                                                <div className='d-flex mb-5 align-items-center'>   <p className='m-0 min-w-150px'>  Payment Method:</p>

                                                                    <b>{receiptData?.paymentMethod === 0 ? 'Bank Transfer' :
                                                                        receiptData?.paymentMethod === 0 ? ' Cheque' : ' Cash'}</b>
                                                                </div>
                                                                <p className='m-0'>Outstanding Amount : <b>  {receiptData?.amountDue - receiptData?.paidAmount}</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>

                                                <div className='min-w-200px mx-10 mb-10 mt-10'>
                                                    <div className='payment-receipt-item mb-5'>
                                                        <div className='d-flex'>
                                                            <div className='me-10'>
                                                                <p className='m-0'>Invoice: <b>{invoiceData?.id}</b></p>
                                                                <p className='m-0'>Amount Due:  <b>   {receiptData?.amountDue} </b></p>
                                                                <p className='m-0'>Currency:  <b>   {receiptData?.currency} </b></p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>



                                        <Row className='py-2 mx-10'>
                                            <Col lg={8}>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                    Comments
                                                </h6>
                                                <b>{receiptData?.comment}</b>

                                                <div className='mt-10'>
                                                    <a
                                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                        href={`${Bucket}${receiptData?.document
                                                            }`} target="_blank">
                                                        View Document
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className='min-w-200px mx-10 mb-10 '>
                                                    <div className='payment-receipt-item mb-5'>

                                                        <div className=''>
                                                            <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                                Authorised Signatory
                                                            </h6>
                                                            <div className='mb-2'>
                                                                <b>{receiptData?.authorizedName}</b>

                                                            </div>
                                                            <div className='mb-2'>
                                                                <b>{receiptData?.authorizedDesignation}</b>

                                                            </div>

                                                            <div className='mt-10'>
                                                                <a
                                                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                                    href={`${Bucket}${receiptData?.signatureURL

                                                                        }`} target="_blank">
                                                                    View Signature
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>}
                            </div>
                        </form>
                    </div >
                </div>

            </div >
        </div >
    )
}

export default ViewInvoiceData
