import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { Col, Row } from 'react-bootstrap';

const Tenancy = () => {
    const [count, setCount] = useState<any>([])
    const [tableData, setTableData] = useState([])
    const [type, setType] = useState('')
    const [page, setPage] = useState<any>(1)
    const [pageLimit, setPageLimit] = useState<any>(1)
    const [contract, setContract] = useState<any>('')
    const navigate = useNavigate()
    const [formData, setFormData] = useState<any>({})
    const [unit, setUnit] = useState<any>('')
    const [occupancy, setOccupancy] = useState<any>()
    const [tenancyStatus, setTenancyStatus] = useState<any>()
    const [unitNo, setUnitNo] = useState<any>()
    const [bedRooms, setBedRooms] = useState<any>()
    const corporateId = window.location.pathname?.split('/')[3]

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
        setType(formData?.unitType)
    }
    const getCount = () => {
        ApiGet(`admin/tenancy/count?corporateId=${window.location.pathname?.split('/')[3]}`)
            .then((res) => {
                // console.log('res', res?.data?.data)
                setCount(res?.data?.data)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }
    const tenancyData = () => {
        let body = {
            page: page,
            limit: 10,
            searchContractNo: contract,
            searchUnitNo: unitNo,
            unitType: unit,
            tenancyStatus: Number(tenancyStatus),
            occupy: Number(occupancy),
            bedrooms: Number(bedRooms),
            corporateId:window.location.pathname?.split('/')[3],
        }
        ApiPost('admin/tenancy/overview', body)
            .then((res) => {
                // console.log('res', res?.data?.data?.tenancy_data)
                setTableData(res?.data?.data?.tenancy_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((err) => console.log('err', err))
    }
    useEffect(() => {
        if(corporateId !== undefined){
            getCount()
            tenancyData()
        }
      
    }, [page, contract, unit, tenancyStatus, unitNo, occupancy,bedRooms])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }
    return (
        <div>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/account-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Account Details
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/payment-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4  ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Payments
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/user-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Users
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/property-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Propertise
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenancy-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenancies
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenant-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenants
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {

                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Troubleshooting
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div id='kt_app_content' className='app-content flex-column-fluid'>
                        <div id='kt_app_content_container' className='app-container container-xxl'>
                            <Row>
                                <Col lg={6}>
                                    <div className='card-header align-items-center py-5  gap-md-2 d-flex'>
                                        <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                            <h5 className='gray-500 mx-10'>
                                                Active :{' '}
                                                <span className='' style={{ color: 'black' }}>
                                                    {count?.activeTenancyCount}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                            <h5 className='gray-500 mx-10'>
                                                Booked :{' '}
                                                <span className='' style={{ color: 'black' }}>
                                                    {count?.bookTenancyCount}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                            <h5 className='gray-500 mx-10'>
                                                Available :{' '}
                                                <span className='' style={{ color: 'black' }}>
                                                    {count?.availableTenancyCount}
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}></Col>
                            </Row>

                            <div
                                className='card-header align-items-center py-5 gy-3 row'
                                style={{ justifyContent: 'flex-start' }}
                            >
                                <div className='mb-2 min-w-200px col-md-2 px-10'>
                                    {/* <label className='form-label fw-semibold'>Contract No.</label> */}
                                    <div>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Contract No'
                                            name='unitNo'
                                            onChange={(e) => { setContract(e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div className='mb-2 min-w-200px col-md-2 px-10'>
                                    <div>

                                        <select
                                            className='form-select'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-dropdown-parent='#kt_menu_631f08e971923'
                                            data-allow-clear='true'
                                            onChange={(e) => setOccupancy(e.target.value)}
                                        >
                                            <option disabled selected>
                                                Occupancy
                                            </option>
                                            <option value={1}>Occupied</option>
                                            <option value={0}>Vaccant</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='mb-2 min-w-200px col-md-2 px-10'>
                                    {/* <label className='form-label fw-semibold'> Tenancy Status</label> */}
                                    <div>
                                        <select
                                            className='form-select'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-dropdown-parent='#kt_menu_631f08e971923'
                                            data-allow-clear='true'
                                            onChange={(e) => { setTenancyStatus(e.target.value) }}
                                        >
                                            <option disabled selected>
                                                Tenancy Status
                                            </option>
                                            <option value='0'>Booked</option>
                                            <option value='1'>Active</option>
                                            <option value='2'>Expiring</option>
                                            <option value='3'>Ended</option>
                                            <option value='4'>Renewed</option>
                                            <option value='5'>Terminated</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='mb-2 min-w-200px col-md-2 px-10'>
                                    {/* <label className='form-label fw-semibold'>Unit Type </label> */}
                                    <div>
                                        <select
                                            className='form-select'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-dropdown-parent='#kt_menu_631f08e971923'
                                            data-allow-clear='true'
                                            onChange={(e) => { setUnit(e.target.value) }}
                                        >
                                            <option disabled selected>
                                                Unit Type
                                            </option>
                                            <option value='apartment'>Apartment</option>
                                            <option value='pent_house'>Penthouse</option>
                                            <option value='town_house'>Townhouse</option>
                                            <option value='vill'>Villa</option>
                                            <option value='common_area'>Common Area</option>
                                            <option value='Other'>Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='mb-2 min-w-200px col-md-2 px-10'>
                                    <div>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Unit No'
                                            name='unitNo'
                                            onChange={(e) => { setUnitNo(e.target.value) }}
                                        // value={formData?.unitNo}
                                        // onChange={handleChnage}
                                        />
                                    </div>
                                </div>
                                <div className='mb-2 min-w-200px col-md-2 px-10'>
                                    <div>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Bedrooms'
                                            name='Bedrooms'
                                            onChange={(e) => { setBedRooms(e.target.value) }}
                                        // value={formData?.unitNo}
                                        // onChange={handleChnage}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='card card-flush'>

                                <div className='card-body pt-0 table-responsive mt-5'>
                                    <table
                                        className='table align-middle table-row-dashed fs-6 gy-5'
                                        id='kt_ecommerce_sales_table'
                                    >
                                        <thead>
                                            <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                                <th className='min-w-100px'>Development </th>
                                                <th className='min-w-100px'> Unit No </th>
                                                <th className='min-w-70px'>Unit Type </th>
                                                <th className='min-w-70px'>Bedrooms </th>
                                                <th className='min-w-70px'>  Contract No </th>
                                                <th className='min-w-70px'> Occupancy </th>
                                                <th className='min-w-70px'>Tenancy</th>
                                                <th className='min-w-70px'>Tenant Status</th>
                                                <th className='min-w-70px'>Tenant Source</th>
                                                <th className='min-w-70px'>Duration</th>
                                                <th className='min-w-70px'>Remaining</th>
                                                <th className='min-w-70px'> Start - End </th>
                                                <th className='min-w-70px'>Form Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className='fw-semibold text-gray-600'>
                                            {tableData?.length ? (
                                                tableData?.map((v: any, i: any) => {
                                                    return (
                                                        <tr>
                                                            <td data-kt-ecommerce-order-filter='order_id' className='text-center'>
                                                                {v?.community[0]?.name ? v?.community[0]?.name : v?.building[0]?.name}
                                                            </td>
                                                            <td className='text-center'>{v?.unit?.unitNo}</td>
                                                            <td className='text-center' data-order='Denied'>
                                                                {v?.unit[0]?.unitType === 'town_house'
                                                                    ? 'TownHouse'
                                                                    : v?.unit?.unitType === 'other'
                                                                        ? 'Other'
                                                                        : v?.unit?.unitType === 'common_area'
                                                                            ? 'Common Area'
                                                                            : v?.unit?.unitType === 'villa'
                                                                                ? 'Villa'
                                                                                : v?.unit?.unitType === 'apartment'
                                                                                    ? 'Apartment'
                                                                                    : v?.unit?.unitType === 'penthouse'
                                                                                        ? 'Penthouse'
                                                                                        : ''}
                                                            </td>
                                                            <td className='text-center'>{v?.unit?.bedrooms}</td>
                                                            <td className='text-center' data-order='2022-09-09'>
                                                                {v?.contractNo}
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                {v?.unit?.occupy === 0 ? 'Vacant ' : 'Occupied'}
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                {v?.tenancyStatus === 0
                                                                    ? 'Booked '
                                                                    : v?.tenancyStatus === 1
                                                                        ? 'Active'
                                                                        : v?.tenancyStatus === 2 ?
                                                                            'Expiring'
                                                                            : v?.tenancyStatus === 3 ?
                                                                                'Ended'
                                                                                : v?.tenancyStatus === 4 ?
                                                                                    'Renewed'
                                                                                    : v?.tenancyStatus === 5 ?
                                                                                        'Terminated' : ''
                                                                }
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                {v?.tenant[0]?.tenantSource === 0 ? 'Agent' : 'Direct'}
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                Main Tenant
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                {v?.duration?.days <= 0 ? 0 : v?.duration?.days} Days
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                {v?.remainingDays <= 0 ? 0 : v?.remainingDays} Days
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                {moment(v?.duration?.start_date).format('DD/MM/YYYY')} -{' '}
                                                                {moment(v?.duration?.end_date).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td className='text-center' data-order='2022-09-11'>
                                                                {v?.isDraft ? 'Draft' : 'Created'}
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            ) : (
                                                 <tr><td colSpan={12} className="100 text-center"> No Data Found</td></tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row mb-5'>
                                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                                    </div>
                                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                        <div
                                            className='dataTables_paginate paging_simple_numbers'
                                            id='kt_ecommerce_sales_table_paginate'
                                        >
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                pageClassName="paginate_button page-item +"
                                                pageLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                previousClassName="paginate_button page-item previous disabled"
                                                nextClassName="paginate_button page-item next"
                                                previousLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={pageLimit}
                                                previousLabel="<"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            </div>
            )
}

            export default Tenancy
