import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiPost, ApiUpload, Bucket } from '../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast'
import Swal from 'sweetalert2'

const IssueInvoice = () => {
    const navigate = useNavigate()
    const location:any = useLocation();
    const [addVendor, setAddVendor] = React.useState<any>()
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm<any>();
    const [uploadedFile, setUploadedFile] = React.useState<any>("")
    const [documentData, setDocumentData] = React.useState<any>()
    const [invoiceData, setInvoiceData] = React.useState<any>()
    const [invoice, setInvoice] = React.useState<any>()

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setAddVendor({ ...addVendor, [name]: value })
    }
console.log(location?.state)
    const onSubmit = async () => {
        // const data: any = document.getElementById("receiptHtml")
        // const pdfData = data.innerHTML
        if(invoiceData?.outstandingAmount<invoice?.amountPaid) return ErrorToast("Paid Amount bigger than Due Amount")
        const body = {
            corporateInvoiceId: invoiceData?._id,
            corporateId: invoiceData?.corporateId,
            authorizedName: invoice?.authorizedName,
            authorizedDesignation: invoice?.authorizedDesignation,
            currency: invoiceData?.currency,
            saveStatus: 0,
            signatureURL: uploadedFile,
            comment: invoice?.comment,
            // pdfURL: "63c3b5c71570883e14e6b317/corporate_invoice_pdf/1674206871221.pdf",
            totalAmount: invoiceData?.outstandingAmount,
            paidAmount: Number(invoice?.amountPaid),
            outstandingAmount: Number(invoiceData?.outstandingAmount - invoice?.amountPaid),
            amountDue: invoiceData?.outstandingAmount,
            issueDate: new Date(),
            paymentDate: invoice?.date,
            paymentMethod: Number(invoice?.paymentMethod),
            document: [documentData],
            
        }
        await ApiPost(`admin/corporate_receipt`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }


    React.useEffect(() => {
        getDataByID()
    }, [])
    const getDataByID = async () => {
        await ApiGet(`admin/corporate_invoice/${window.location.pathname?.split('/')[3]}`)
            .then((res) => {
                setInvoiceData(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const uploadFilelHandler = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setUploadedFile(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }
    const uploadDocument = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setDocumentData(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }
    const saveReceipt = async () => {
        // const data: any = document.getElementById("receiptHtml")
        // const pdfData = data.innerHTML
        if(invoiceData?.outstandingAmount<invoice?.amountPaid) return ErrorToast("Paid Amount bigger than Due Amount")
        const body = {
            corporateInvoiceId: invoiceData?._id,
            corporateId: invoiceData?.corporateId,
            authorizedName: invoice?.authorizedName,
            authorizedDesignation: invoice?.authorizedDesignation,
            currency: invoiceData?.currency,
            saveStatus: 0,
            signatureURL: uploadedFile,
            comment: invoice?.comment,
            // pdfURL: "63c3b5c71570883e14e6b317/corporate_invoice_pdf/1674206871221.pdf",
            totalAmount: invoiceData?.outstandingAmount,
            paidAmount: Number(invoice?.amountPaid),
            outstandingAmount: Number(invoiceData?.outstandingAmount - invoice?.amountPaid),
            amountDue: invoiceData?.outstandingAmount,
            issueDate: new Date(),
            paymentDate: invoice?.date,
            paymentMethod: Number(invoice?.paymentMethod),
            document: [documentData],
            
        }
        console.log(body)
        await ApiPost(`admin/corporate_receipt`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)

            })
            .catch((e) => {
                console.log('e', e)
                ErrorToast(e?.message)
            })
    }


    return (
        <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='mb-6'>
                    <a
                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_app'
                        onClick={() => navigate(`/corporate/payment-detail/${invoiceData?.corporateId}`)}
                    >
                        Back
                    </a>
                </div>
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div id='' className='app-container container-xxl pt-0 mt-0'>

                            <div className='mb-5 min-w-375px d-flex justify-content-between w-auto'>

                            </div>
                        </div>
                        <div className='d-flex align-items-center mt-5 mx-10 mb-5' >
                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <div
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                    onClick={saveReceipt}
                                >
                                    Save
                                </div>
                                {/* <div
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                    onClick={() => {
                                        Swal
                                            .fire({
                                                text: '<p class="text-start m-0"> Are you sure you want to delete this receipt?</p>',
                                                html: '<p class="text-start m-0"> Are you sure you want to delete this receipt?</p>',
                                                icon: 'warning',
                                                showConfirmButton: true,
                                                confirmButtonColor: '#D72852',
                                                confirmButtonText: 'Yes',
                                                showCancelButton: true,
                                                // cancelButtonColor: "transparent",
                                                cancelButtonText: 'Cancel',
                                            })
                                            .then((res) => {
                                             
                                            })
                                    }}
                                >
                                    Delete
                                </div> */}
                                <button
                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                    type='submit'
                                >
                                    Submit Receipt
                                </button>
                            </div>
                        </div>
                        <div className='card card-flush' id='pdfData'>
                            <Row className='py-2'>
                                <Col lg={8}>
                                    <div className='mx-10 mb-10 mt-10' >
                                        <img src={Bucket+"pdf_logo.jpeg"} style={{ height: '150px' }} />                                        </div>
                                </Col>
                                <Col lg={4}>
                                    <h3 className='mx-10 mb-10 mt-10'>    PAYMENT RECEIPT     </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item mb-5'>
                                            <div className='d-flex'>
                                                <div className='me-10'>
                                                    <p className='m-0'>Propertise: <b>FZCO</b></p>
                                                    <p className='m-0'>Address: <b> Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</b></p>
                                                    <p className='m-0'>Tel:<b>+971 50 351 59 74 </b></p>
                                                    <p className='m-0'>  Email:  <b> Admin@propertise.com </b></p>
                                                    <p className='m-0'>Website: <b>www.propertise.com </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='py-2'>
                                <Col lg={8}>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item mb-5'>
                                            <div className='d-flex'>
                                                <div className='me-10'>
                                                    <p className='m-0'>Receipt #: <b>ACCOUNT ID_INVOICE #_000    </b></p>
                                                    <p className='m-0 mb-6'>Issue Date: <b>  Draft or DD.MM.YYYY  </b></p>
                                                    <div className='d-flex mb-5 align-items-center'>    <p className='m-0 min-w-100px'>Payment Date:  </p><input
                                                        type='date'
                                                        {...register("date", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='date'
                                                        onChange={(e) => setInvoice({ ...invoice, 'date': e.target.value })}

                                                    /></div>
                                                    {errors.date && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    <div className='d-flex mb-5 align-items-center'>    <p className='m-0 min-w-100px'>Amount Paid :  </p> <input
                                                        type='text'
                                                        {...register("amountPaid", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='amountPaid'
                                                        max={invoiceData?.outstandingAmount}
                                                        onChange={(e) => setInvoice({ ...invoice, 'amountPaid': e.target.value })}

                                                    /></div>
                                                    {errors.amountPaid && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    <div className='d-flex mb-5 align-items-center'>   <p className='m-0 min-w-150px'>  Payment Method:</p>    <select
                                                        {...register("paymentMethod", { required: true })}
                                                        className='form-select form-select-solid'
                                                        name='paymentMethod'
                                                        onChange={(e) => setInvoice({ ...invoice, 'paymentMethod': e.target.value })}

                                                    >
                                                        <option selected value=''>
                                                            Select
                                                        </option>
                                                        <option value={0}>
                                                            Bank Transfer
                                                        </option>
                                                        <option value={1}>
                                                            Cheque
                                                        </option>
                                                        <option value={2}>
                                                            Cash
                                                        </option>
                                                    </select></div>

                                                    {errors.paymentMethod && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    
                                                    <p className='m-0'>Outstanding Amount : <b>  {invoice?.amountPaid && Number(invoiceData?.outstandingAmount - invoice?.amountPaid).toFixed(2)}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4}>

                                    <div className='min-w-200px mx-10 mb-10 mt-10'>
                                        <div className='payment-receipt-item mb-5'>
                                            <div className='d-flex'>
                                                <div className='me-10'>
                                                    <p className='m-0'>Invoice: <b>{invoiceData?.id}</b></p>
                                                    <p className='m-0'>Amount Due:  <b>{invoiceData?.outstandingAmount} </b></p>
                                                    <p className='m-0'>Currency:  <b>{invoiceData?.currency} </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>



                            <Row className='py-2 mx-10'>
                                <Col lg={8}>
                                    <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                        Comments
                                    </h6>
                                    <textarea
                                        aria-rowspan={3}
                                        {...register("comment", { required: true })}
                                        className='form-control form-control-solid'
                                        name='comment'
                                        onChange={(e) => setInvoice({ ...invoice, 'comment': e.target.value })}
                                    />
                                    <div className='mt-10'>
                                        <button
                                            className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-200px'>
                                            Upload Document
                                            <input
                                                type='file'
                                                {...register("document", { required: true })}
                                                className='form-control form-control-solid position-absolute '
                                                placeholder=''
                                                name='document'
                                                onChange={uploadDocument}
                                                // onChange={uploadFilelHandler}
                                                style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                            />
                                        </button>
                                        {errors.document && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className='min-w-200px mx-10 mb-10 '>
                                        <div className='payment-receipt-item mb-5'>

                                            <div className=''>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px mb-3'>
                                                    Authorised Signatory
                                                </h6>
                                                <div className='mb-2'>
                                                    <input
                                                        type='text'
                                                        {...register("authorizedName", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='authorizedName'
                                                        placeholder='Name'
                                                        onChange={(e) => setInvoice({ ...invoice, 'authorizedName': e.target.value })}
                                                    />
                                                </div>
                                                {errors.authorizedName && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                <div className='mb-2'>
                                                    <input
                                                        type='text'
                                                        {...register("authorizedDesignation", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='authorizedDesignation'
                                                        placeholder='Authorized Designation'
                                                        onChange={(e) => setInvoice({ ...invoice, 'authorizedDesignation': e.target.value })}
                                                    />
                                                </div>
                                                {errors.authorizedDesignation && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                <button
                                                    className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-200px'>
                                                    Upload Signature
                                                    <input
                                                        type='file'
                                                        {...register("contract", { required: true })}
                                                        className='form-control form-control-solid position-absolute '
                                                        placeholder=''
                                                        name='contract'
                                                        onChange={uploadFilelHandler}
                                                        // onChange={uploadFilelHandler}
                                                        style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                                    />
                                                </button>
                                                {errors.contract && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div >
                {/* <div style={{ display: 'none' }}>
                    <div id='receiptHtml'>
                        <div style={{ background: '#fff', padding: '30px' }}>
                            <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div>
                                    <img src='/media/logos/WhatsAppImage.jpeg' style={{ height: '150px' }} />
                                </div>
                                <div>
                                    <h3 style={{ fontSize: '15px' }}> PAYMENT RECEIPT</h3>
                                    <div>
                                        <div>
                                            <div className='d-flex'>
                                                <div >
                                                    <p style={{ fontSize: '10px' }}>Propertise: <b>FZCO</b></p>
                                                    <p style={{ fontSize: '10px' }}>Address: <b> Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</b></p>
                                                    <p style={{ fontSize: '10px' }}>Tel:<b>+971 50 351 59 74 </b></p>
                                                    <p style={{ fontSize: '10px' }}>  Email:  <b> Admin@propertise.com </b></p>
                                                    <p style={{ fontSize: '10px' }}>Website: <b>www.propertise.com </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'space-between' }}>
                                <div >
                                    <div >
                                        <p style={{ fontSize: '10px' }}>Receipt: <b>ACCOUNT ID_INVOICE #_000</b></p>
                                        <p style={{ fontSize: '10px' }}>Issue Date: <b>  Draft or DD.MM.YYYY  </b></p>
                                        <p style={{ fontSize: '10px' }}>Payment Date: <b>DD/MM/YYYY </b></p>
                                        <p style={{ fontSize: '10px' }}>Amount Paid : <b>500</b></p>
                                        <p style={{ fontSize: '10px' }}>Payment Method:<b> DD/MM/YYYY </b></p>
                                        <p style={{ fontSize: '10px' }}>Outstanding Amount:  <b>  AED  </b></p>
                                    </div>
                                </div>
                                <div >
                                    <p style={{ fontSize: '10px' }}>Invoice: <b>ACCOUNT ID_</b></p>
                                    <p style={{ fontSize: '10px' }}>Amount Due: <b>   </b></p>

                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', marginTop: '5px', justifyContent: 'space-between' }}>
                                <div >
                                    <h6 style={{ fontSize: '10px' }}>
                                        Terms & Conditions
                                    </h6>
                                    <p style={{ fontSize: '10px' }}>condition and terms</p>

                                </div>
                                <div>
                                    <h6 style={{ fontSize: '10px' }}>
                                        Authorised Signatory
                                    </h6>
                                    <div style={{ marginBottom: '5px' }}>
                                        <p style={{ fontSize: '10px' }}> Kishan Dholakiya</p>
                                    </div>
                                    <div style={{ marginBottom: '5px' }}>
                                        <p style={{ fontSize: '10px' }}>xyz</p>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                    </div> */}
                </div>

            </div>
            )
}

            export default IssueInvoice
