import React, { useEffect, useState } from "react";
import "./style.scss";
import { ApiPost, ApiGet } from "../../../apiCommon/helpers/API/ApiData";
import moment from "moment";
import trashImg from "../../../img/trash-white.png";
import registerImg from "../../../img/email.png";
import swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import backArrow from "../../../img/back-arrow.png";
import { ErrorToast, SuccessToast } from "../../../apiCommon/helpers/Toast";

const InterestDetails = () => {
  const { state }: any = useLocation();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState<any>(state.interest);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //
  const deleteInterest = async () => {
    setIsLoading(true);
    let id: any = [];
    id[0] = requestData?._id;

    const body = {
      contactUsIds: id,
    };

    await ApiPost("admin/contact_us/delete", body)
      .then((res) => {
        SuccessToast("Request has been deleted successfully");
        navigate("/interests", {
          state: {
            activeTab: state?.activeTab,
            sortType: state?.sortType
          },
        });
        setIsLoading(false);
      })
      .catch((err: any) => {
        ErrorToast(err?.message);
        setIsLoading(false);
      });
  };

  //
  const resendConfirmation = async () => {
    setIsLoading(true);
    await ApiGet(`admin/contact_us/resent_email/${requestData?._id}`)
      .then((res) => {
        SuccessToast("Confirmation Email has been sent successfully..");
        navigate("/interests", {
          state: {
            activeTab: state?.activeTab,
            sortType: state?.sortType
          },
        });
        setIsLoading(false);
      })
      .catch((err: any) => {
        ErrorToast(err?.message);
        setIsLoading(false);
      });
  };

  return (
    <div
      className="mx-auto d-flex gap-5 flex-column p-0 m-0 px-2"
      id="kt_app_main"
    >
      {" "}
      <div className="page-title d-flex flex-column justify-content-center flex-wrap">
        <div className=" d-flex flex-stack pt-1 mt-0 px-0">
          <div className="d-flex align-items-center mb-1">
            <span
              className="rounded-circle cursor-pointer"
              onClick={() =>
                navigate("/interests", {
                  state: {
                    activeTab: state?.activeTab,
                    sortType: state?.sortType
                  },
                })
              }
            >
              <img src={backArrow} height="14" width="14" />
            </span>
            <h2 className="m-0 head-text ms-2">Interest Details</h2>
          </div>
          <button
            type="button"
            className="btn btn-sm fw-bold term-btn  me-0 ps-2  pe-0 mt-0  status-w-120  ms-auto"
            onClick={() => {
              swal
                .fire({
                  text: "Are you sure you want to delete the this request?",
                  icon: "warning",
                  showConfirmButton: true,
                  confirmButtonColor: "#D72852",
                  confirmButtonText: "Yes",
                  showCancelButton: true,
                  // cancelButtonColor: "transparent",
                  cancelButtonText: "Cancel",
                })
                .then((res) => {
                  if (res.isConfirmed) {
                    deleteInterest();
                  }
                });
            }}
            disabled={isLoading}
          >
            <img
              src={trashImg}
              height={18}
              width={18}
              className="cursor-pointer me-4 ms-2"
            />{" "}
            Delete
          </button>

          {!requestData?.isEmailSent && (
            <button
              type="button"
              className="btn btn-sm fw-bold renew-btn me-0 ps-2  pe-0 mt-0 ms-4 status-w-190"
              style={{ width: "" }}
              onClick={() => resendConfirmation()}
              disabled={isLoading}
            >
              <img
                src={registerImg}
                height={18}
                width={18}
                className="cursor-pointer me-4 ms-2"
              />
              Resend Confirmation
            </button>
          )}
        </div>
      </div>
      {/* Request Summary */}
      <div className="grid-out p-5 pt-0 px-0">
        <div className="card grid px-7 py-5 side">
          <h3 className="top ps-0">
            <b>Request Summary</b>
          </h3>

          <div className="mt-5 mb-0">
            <h6 className="my-1" style={{ color: "black" }}>
              <label style={{ minWidth: "120px" }} className="labl-gry">
                {" "}
                Requestor
              </label>
              <span className="ms-6">
                <b>
                  <>
                    {requestData?.firstName || requestData?.lastName
                      ? requestData?.firstName + " " + requestData?.lastName
                      : "-"}
                  </>
                </b>
              </span>
            </h6>
          </div>
          <div className="mb-0">
            <label style={{ minWidth: "120px" }} className="labl-gry">
              {" "}
            </label>
            <span className="ms-5 ps-1">
              <i>
                {requestData?.createdAt
                  ? `${moment(requestData?.createdAt).format(
                      "DD.MM.YYYY - HH:mm A"
                    )}`
                  : "-"}
              </i>
            </span>
          </div>

          {requestData?.userType?.[0] == 0 && (
            <div className="mt-5 mb-0">
              <h6 className="" style={{ color: "black" }}>
                <label style={{ minWidth: "120px" }} className="labl-gry">
                  {" "}
                  Company Name
                </label>
                <span className="ms-6">
                  <b>
                    <>
                      {requestData?.companyName
                        ? requestData?.companyName
                        : "-"}
                    </>
                  </b>
                </span>
              </h6>
            </div>
          )}

          <div className="mt-5 mb-0">
            <h6 className="" style={{ color: "black" }}>
              <label style={{ minWidth: "120px" }} className="labl-gry">
                {" "}
                {(requestData?.userType?.[0] == 0 && state?.activeTab == 'corporate') ? "Work Email" : "Email"}
              </label>
              <span className="ms-6">
                <b>
                  {(requestData?.userType?.[0] == 0 && state?.activeTab == 'corporate')  ? (
                    <>
                      {requestData?.companyEmail
                        ? requestData?.companyEmail
                        : "-"}
                    </>
                  ) : (
                    <>{requestData?.email ? requestData?.email : "-"}</>
                  )}
                </b>
              </span>
            </h6>
          </div>

          <div className="mt-5 mb-0">
            <h6 className="" style={{ color: "black" }}>
              <label style={{ minWidth: "120px" }} className="labl-gry">
                {" "}
                Mobile No.
              </label>
              <span className="ms-6">
                <b>
                  <>
                    {requestData?.phoneNumber
                      ? `${requestData?.phoneNumber}`
                      : "-"}
                  </>
                </b>
              </span>
            </h6>
          </div>

          <div className="mt-5 mb-0">
            <h6 className="" style={{ color: "black" }}>
              <label style={{ minWidth: "120px" }} className="labl-gry">
                {" "}
                Message
              </label>
              <span className="ms-6">
                <b>
                  <>{requestData?.message ? `${requestData?.message}` : "-"}</>
                </b>
              </span>
            </h6>
          </div>

          {(requestData?.userType?.[0] == 0 && state?.activeTab == 'corporate')  && (
            <div className="mt-4 mb-0 d-flex">
              <h6 className="" style={{ color: "black" }}>
                <label
                  style={{ minWidth: "120px" }}
                  className="labl-gry "
                  htmlFor="exampleFormControlInput1"
                >
                  Requested Features
                </label>
              </h6>
              <div className="test ms-5 ps-1 ">
                {requestData?.featureList?.length > 0 ? (
                  <>
                    {requestData?.featureList?.map((opt: any, ind: any) => (
                      <div className="d-flex align-items-center">
                        <p className="bullet-ic mb-0"></p>
                        <b>
                          {" "}
                          <h6
                            className="ms-3 mb-0"
                            style={{ fontWeight: "700" }}
                          >
                            {" "}
                            {opt}
                          </h6>
                        </b>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="d-flex align-items-center">
                      <b>{"-"}</b>
                    </div>{" "}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="card grid px-7 py-5 side">
          <h3 className="top ps-0">
            <b>Source Details</b>
          </h3>

          <div className="mt-5 mb-0">
            <h6 className="" style={{ color: "black" }}>
              <label style={{ minWidth: "100px" }} className="labl-gry">
                {" "}
                Region
              </label>
              <span className="ms-5 ps-1">
                <b>
                  {" "}
                  {requestData?.sourceData?.region
                    ? requestData?.sourceData?.region
                    : "-"}
                </b>
              </span>
            </h6>
          </div>

          <div className="mt-5 mb-0">
            <h6 className="" style={{ color: "black" }}>
              <label style={{ minWidth: "100px" }} className="labl-gry">
                {" "}
                City
              </label>
              <span className="ms-5 ps-1">
                <b>
                  {" "}
                  {requestData?.sourceData?.city
                    ? requestData?.sourceData?.city
                    : "-"}
                </b>
              </span>
            </h6>
          </div>

          <div className="mt-5 mb-0">
            <h6 className="" style={{ color: "black" }}>
              <label style={{ minWidth: "100px" }} className="labl-gry">
                {" "}
                Country
              </label>
              <span className="ms-5 ps-1">
                <b>
                  {" "}
                  {requestData?.sourceData?.country
                    ? requestData?.sourceData?.country
                    : "-"}
                </b>
              </span>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestDetails;
