import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import close from "../../../img/close.png";
import submitImg from "../../../img/submit-white.png";
import Select from "react-select";
import "./style.scss";
import PhoneInput from "react-phone-input-2";
import registerImg from "../../../img/email.png";
import { MultiSelect } from "react-multi-select-component";
import { Controller, useForm } from "react-hook-form";
import { ApiPost } from "../../../apiCommon/helpers/API/ApiData";
import { SuccessToast, ErrorToast } from "../../../apiCommon/helpers/Toast";

interface ButtonProps {
  show: any;
  handleClose: any;
  activeTab: any;
  handleSuccess: any;
}

const RegisterModal = ({
  handleClose,
  show,
  activeTab,
  handleSuccess,
}: ButtonProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });

  const [userData, setUserData] = React.useState<any>([]);

  const options = [
    { label: "Property Management", value: "Property Management" },
    { label: "Leasing", value: "Leasing" },
    { label: "Tenants", value: "Tenants" },
    { label: "Maintenance", value: "Maintenance" },
    { label: "Accounting", value: "Accounting" },
  ];

  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    message: "",
    companyEmail: "",
    featureList: [],
    userType: [activeTab == "corporate" ? 0 : activeTab == "landlord" ? 1 : 2],
    sourceType: 1, //admin portal
    campaignName: "Pre-Launched-Event",
  });

  const [selected, setSelected] = useState([]);

  const registerUser = async (data: any, e: any) => {
    e.preventDefault();

    let features: any = [];
    selected?.map((ft: any, ind: any) => {
      features[ind] = ft?.value;
    });

    const body = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      companyName: data?.companyName,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      companyEmail: data?.companyEmail,
      message: data?.message,
      featureList: features,
      userType: [
        activeTab == "corporate" ? 0 : activeTab == "landlord" ? 1 : 2,
      ],
      sourceType: 1, //admin portal
      campaignName: data?.campaignName,
    };

    await ApiPost(`admin/contact_us`, body)
      .then((response: any) => {
        SuccessToast(`Request has been submitted successfully..`);
        handleSuccess();
      })
      .catch((error: any) => {
        console.log(error);
        ErrorToast(error?.message);
      });
  };

  return (
    <div>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-750px mx-auto"
        show={show}
        onHide={() => {
          handleClose();
        }}
        backdrop={true}
        size="sm"
      >
        <div className="modal-header text-white ps-5 pe-1 user-header-style">
          <Row className="w-100">
            <Col md={8}>
              <h3 className="mx-0 my-1 mt-2 text-white text-capitalize">
                {activeTab} Registration
              </h3>
            </Col>
            <Col md={4} className="text-end pe-0">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross"
                onClick={handleClose}
              >
                <img
                  className="svg-icon-1 text-black"
                  src={close}
                  width={14}
                  height={14}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="user-header-bg">
          <Row className="mx-2">
            <Col md={9}></Col>
            <Col md={2} className="pt-1" style={{ paddingLeft: "2.8rem" }}>
              <img
                src={registerImg}
                height={80}
                width={80}
                className="me-3 ms-5"
              />
            </Col>
          </Row>
        </div>
        <div
          className="modal-body px-lg-10 pt-lg-4 pb-lg-3"
          style={{
            transform: "translate(0px, -80px)",
            maxHeight: "75vh",
            overflowY: "scroll",
            marginBottom: "-80px",
          }}
        >
          <form
            onSubmit={handleSubmit((data: any, e: any) =>
              registerUser(formData, e)
            )}
          >
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="d-flex align-items-center">
                  <h4
                    className="head-text ms-0 mb-0 text-capitalize"
                    style={{ fontWeight: "700" }}
                  >
                    {activeTab} Details
                  </h4>
                </div>
              </Col>
              <Col lg={6} md={6} className="align-items-center text-end">
                <button
                  className="btn btn-sm fw-bold ms-3 ps-2 text-white renew-btn status-w-140 ms-auto"
                  style={{
                    boxShadow: "0px 0px 20px 0px #146c6a",
                  }}
                  type="submit"
                >
                  <img
                    src={submitImg}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                    className="ms-2 me-4"
                  />{" "}
                  {"  "}
                  Submit
                </button>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col lg={6} md={6} className="align-items-center pe-5 mb-0">
                <Row>
                  <Col lg={12} md={12} className="align-items-center pe-5 mb-5">
                    <label className="required mb-2 label-color">
                      First Name
                    </label>
                    <input
                      {...register("firstName", { required: true })}
                      type="text"
                      className="form-control form-control-solid mytest"
                      placeholder="Enter your first name"
                      name="firstName"
                      value={formData?.firstName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />

                    {errors.firstName && (
                      <p
                        style={{ color: "var(--kt-danger)", gridColumn: "2/2" }}
                        className="m-0 mt-1"
                      >
                        This field is required
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="align-items-center pe-5 mb-5">
                    <label className="required mb-2 label-color">
                      Last Name
                    </label>
                    <input
                      {...register("lastName", { required: true })}
                      type="text"
                      className="form-control form-control-solid mytest"
                      placeholder="Enter your last name"
                      name="lastName"
                      value={formData?.lastName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />

                    {errors.lastName && (
                      <p
                        style={{ color: "var(--kt-danger)", gridColumn: "2/2" }}
                        className="m-0 mt-1"
                      >
                        This field is required
                      </p>
                    )}
                  </Col>

                  <Col lg={12} md={12} className="align-items-center pe-5 mb-5">
                    <label className="required mb-2 label-color">
                      {activeTab == "corporate" ? "Work Email" : "Email"}
                    </label>
                    {activeTab == "corporate" ? (
                      <>
                        <Controller
                          name="companyEmail"
                          control={control}
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              //   {...register('email', {required: true})}
                              type="email"
                              className="form-control form-control-solid mytest"
                              placeholder="Enter your work email"
                              name="companyEmail"
                              value={formData?.companyEmail}
                              onChange={(e) => {
                                setValue("companyEmail", e.target.value); // Update the form value
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          )}
                        />

                        {errors.companyEmail && (
                          <p
                            style={{
                              color: "var(--kt-danger)",
                              gridColumn: "2/2",
                            }}
                            className="m-0 mt-1"
                          >
                            Please enter a valid Email Address
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <Controller
                          name="email"
                          control={control}
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          }}
                          render={({ field }) => (
                            <input
                              //   {...register('email', {required: true})}
                              type="email"
                              className="form-control form-control-solid mytest"
                              placeholder="Enter your email"
                              name="email"
                              value={formData?.email}
                              onChange={(e) => {
                                setValue("email", e.target.value); // Update the form value
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          )}
                        />

                        {errors.email && (
                          <p
                            style={{
                              color: "var(--kt-danger)",
                              gridColumn: "2/2",
                            }}
                            className="m-0 mt-1"
                          >
                            Please enter a valid Email Address
                            {/* {errors.email.message} */}
                          </p>
                        )}
                      </>
                    )}
                  </Col>
                  <Col
                    lg={12}
                    md={12}
                    className="align-items-center mb-5 pe-5 test"
                  >
                    <label className="mb-2 label-color required">
                      Mobile Number
                    </label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          //   {...register('phoneNumber', {required: true})}
                          placeholder="Enter your phone number"
                          value={`${formData?.phoneNumber}`}
                          onChange={(phone: any, e: any) => {
                            let CountryCode: any = e?.dialCode;
                            let PhoneNumber: any = phone.split(CountryCode)[1];

                            // Check if the phone number is empty
                            if (phone.trim() === "") {
                              setFormData({
                                ...formData,
                                ["phoneNumber"]: "",
                              });

                              setValue("phoneNumber", ""); 
                            } else {
                              setFormData({
                                ...formData,
                                ["phoneNumber"]: `${parseInt(
                                  CountryCode
                                )} ${PhoneNumber}`,
                              });

                              setValue(
                                "phoneNumber",
                                `${parseInt(CountryCode)} ${PhoneNumber}`
                              );
                            }
                          }}
                        />
                      )}
                    />

                    {errors.phoneNumber && (
                      <p
                        style={{ color: "var(--kt-danger)", gridColumn: "2/2" }}
                        className="m-0 mt-1"
                      >
                        This field is required
                      </p>
                    )}
                  </Col>
                  {activeTab == "corporate" && (
                    <Col
                      lg={12}
                      md={12}
                      className="align-items-center pe-5 mb-5"
                    >
                      <label className="required mb-2 label-color">
                        Company Name
                      </label>
                      <input
                        {...register("companyName", { required: true })}
                        type="text"
                        className="form-control form-control-solid mytest"
                        placeholder="Enter your company name"
                        name="companyName"
                        value={formData?.companyName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />

                      {errors.companyName && (
                        <p
                          style={{
                            color: "var(--kt-danger)",
                            gridColumn: "2/2",
                          }}
                          className="m-0 mt-1"
                        >
                          This field is required
                        </p>
                      )}
                    </Col>
                  )}
                </Row>
              </Col>
              {/* Right Column */}
              <Col lg={6} md={6} className="align-items-center pe-5 mb-0">
                <Row>
                  {activeTab == "corporate" && (
                    <Col
                      lg={12}
                      md={12}
                      className="align-items-center pe-5 mb-5 test"
                    >
                      <label className="mb-2 label-color">
                        Which features are you interested in?
                      </label>
                      <div className={`multi-select-container ${selected.length === 0 ? 'no-value' : 'has-value'}`}>
                      <MultiSelect
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                      />
                      </div>
                    </Col>
                  )}
                  <Col lg={12} md={12} className="align-items-center pe-5 mb-5">
                    <label className="mb-2 label-color">
                      Have a feature in mind that you would like to experience
                      with Propertise?
                    </label>
                    <label className="mb-2 label-color">
                      Share with us your wishlist and we’ll make sure to exceed
                      your expectations!
                    </label>

                    <textarea
                      className="form-control form-control-solid-bg mytest"
                      placeholder="Share with us your wishlist and we’ll make sure to exceed
                    your expectations!"
                      name="item_id"
                      style={{ height: "125px" }}
                      value={formData?.message}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          ["message"]: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterModal;
