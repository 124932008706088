import React, { useEffect, useState } from "react";
import noData from "../../../img/NoData1.svg";
import "./style.scss";
import { ApiPost, ApiGet } from "../../../apiCommon/helpers/API/ApiData";
import moment from "moment";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Select from "react-select";
import swapImg from "../../../img/up-down-arrow.png";
import trashImg from "../../../img/trash-white.png";
import registerImg from "../../../img/email.png";
import swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../apiCommon/helpers/Toast";
import ReactPaginate from "react-paginate";
import { components } from "react-select";
import RegisterModal from "./RegisterModal";
import { CircularProgress } from "@mui/material";

const Interest = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [tableData, setTableData] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(state?.activeTab ? state?.activeTab : "corporate");
  const [interestCount, setInterestCount] = useState<any>({});
  const [isLoading, setIsLoading] = useState<any>(true)

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(25);
  const [pageLimit, setPageLimit] = useState<any>(1);

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1);
  };

  const [checkedValues, setCheckedValues] = useState<any>([]);
  const [searchCompanyName, setSearchCompanyName] = useState<any>("");
  const [searchName, setSearchName] = useState<any>("");

  const [sourceType, setSourceType] = useState<any>("all");
  const [sourceOptions, setSourceOptions] = useState<any>([
    { value: "all", label: "All" },
    { value: "0", label: "Website" },
    { value: "1", label: "Propertise Admin" },
    { value: "2", label: "Social - Linkedin" },
    { value: "3", label: "⁠Social - Instagram" },
    { value: "4", label: "Social - Facebook" },
    { value: "5", label: "Social - X" },
    { value: "6", label: "⁠Google Ads" },
    { value: "7", label: "Youtube Ads" },
  ]);

  const [userType, setUserType] = useState<any>(
    state?.activeTab == "corporate"
      ? [0]
      : state?.activeTab == "landlord"
      ? [1]
      : state?.activeTab == "tenant"
      ? [2]
      : [0]
  );
  const [campaignName, setCampaignName] = useState<any>("");
  const [campaignOptions, setCampaignOptions] = useState<any>([
    // { value: "pre-launched", label: "Pre-launced" },
  ]);

  // const sorting : any = localStorage?.getItem('interestSortType')
  // let  sortValue : any = JSON?.parse(sorting)
  // console.log(state?.sortType)

  const [sortType, setSortType] = useState<any>(state?.sortType ? state?.sortType : "A-Z");
  const [sortOptions, setSortOptions] = useState<any>([
    { value: "A-Z", label: "Alphabetic (A-Z)" },
    { value: "Z-A", label: "Alphabetic (Z-A)" },
    { value: "latest", label: "(Latest - Oldest)" },
    { value: "oldest", label: "(Oldest - Latest)" },
  ]);

  const [confirmationOptions, setConfirmationOptions] = useState<any>([
    { value: "all", label: "All" },
    { value: true, label: "Delivered" },
    { value: false, label: "Undelivered" },
  ]);

  const [isEmailSent, setIsEmailSent] = useState<any>();
  const [registerUserModal, setRegisterUserModal] = useState<any>(false);

  let totalSelected = 0;

  const searchContainerStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: "25px",
    width: "170px",
  };

  const searchIconStyle = {
    padding: "10px",
    color: "gray",
  };

  const inputBaseStyle = {
    flex: 1,
    padding: "5px",
  };

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: "170px",
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected ? "#c2d8d9" : "",
      ":hover": {
        backgroundColor: "#e0f5f0",
        color: "#000",
      },

      fontWeight: "500",
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: "0.1rem solid #007a59 ",
      marginTop: "0px",
      borderRadius: "0px",
      zIndex: "2",
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: "200px",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: "Italic",
      zIndex: "1",
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "#f5f8fa",
      color: " #b5b5c3",
      fontWeight: "500",
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "#f5f8fa",
      boxShadow: "none",
      color: "white",
      border: "0.1rem solid #bec3cb",
      borderRadius: "2px",
      padding: "0rem",
      height: "35px",
      ":hover": {
        borderColor: "#007a59",
        backgroundColor: "#fff",
      },
      ":focus": {
        borderColor: "#007a59 !important",
        backgroundColor: "#fff",
      },
      "::placeholder": {
        fontStyle: "Italic",
      },
      minHeight: "35px",
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#5e6278",
      fontWeight: "600",
      zIndex: "1",
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
  };

  // React Select for Email Confirmation
  const customStylesEmail = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: "200px",
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected ? "#c2d8d9" : "",
      ":hover": {
        backgroundColor: "#e0f5f0",
        color: "#000",
      },

      fontWeight: "500",
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: "0.1rem solid #007a59 ",
      marginTop: "0px",
      borderRadius: "0px",
      zIndex: "2",
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: "200px",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: "Italic",
      zIndex: "1",
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "#f5f8fa",
      color: " #b5b5c3",
      fontWeight: "500",
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "#f5f8fa",
      boxShadow: "none",
      color: "white",
      border: "0.1rem solid #bec3cb",
      borderRadius: "2px",
      padding: "0rem",
      height: "35px",
      ":hover": {
        borderColor: "#007a59",
        backgroundColor: "#fff",
      },
      ":focus": {
        borderColor: "#007a59 !important",
        backgroundColor: "#fff",
      },
      "::placeholder": {
        fontStyle: "Italic",
      },
      minHeight: "35px",
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#5e6278",
      fontWeight: "600",
      zIndex: "1",
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
  };

  //
  const goToRequest = (req: any) => {
    navigate(`/interest/${req?._id}`, {
      state: {
        interest: req,
        activeTab: activeTab,
        sortType: sortType
      },
    });
  };

  //
  const getInterestData = async () => {
    setIsLoading(true)
    setTableData([])
    const body = {
      page: page,
      limit: limit,
      searchName: searchName,
      searchCompanyName: activeTab == "corporate" ? searchCompanyName : "",
      sourceType: sourceType == "all" ? undefined : sourceType,
      userType: userType,
      campaignName: campaignName,
      sortType: sortType,
      isEmailSent: isEmailSent == "all" ? undefined : isEmailSent,
    };
    await ApiPost("admin/contact_us/get", body)
      .then((res) => {
        setIsLoading(false)
        setTableData(res?.data?.data?.contact_us_data);
        setPageLimit(res?.data?.data?.state?.page_limit);
      })
      .catch(() => {});
  };

  //
  const interestCountSummary = () => {
    ApiGet("admin/contact_us/count")
      .then((res) => {
        setInterestCount(res?.data?.data);
      })
      .catch((err) => ErrorToast(err.message));
  };

  useEffect(() => {
    getInterestData();
  }, [
    page,
    searchName,
    searchCompanyName,
    sourceType,
    campaignName,
    sortType,
    userType,
    isEmailSent,
  ]);

  useEffect(() => {
    interestCountSummary();
  }, [userType]);

  //
  const deleteInterests = async () => {
    let ids: any = [];
    let i: any = 0;
    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = tableData[ind]?._id;
        i++;
      }
    });

    const body = {
      contactUsIds: ids,
    };

    await ApiPost("admin/contact_us/delete", body)
      .then((res) => {
        let values = [...checkedValues];

        for (let i = 0; i < tableData?.length; i++) {
          values[i] = false;
        }

        setCheckedValues(values);
        SuccessToast("Selected requests have been deleted successfully");
        getInterestData();
      })
      .catch(() => {});
  };

  // useEffect(() => {
  //   if (state?.activeTab != undefined) {
  //     if (state?.activeTab == "corporate") setUserType([0]);
  //     else if (state?.activeTab == "landlord") setUserType([1]);
  //     else if (state?.activeTab == "tenant") setUserType([2]);
  //     setActiveTab(state?.activeTab);
  //   }
  // }, []);

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="ok-div d-flex align-items-center justify-content-center cursor-pointer">
          <img src={swapImg} height={18} width={18} alt="Dropdown Indicator" />
        </div>
      </components.DropdownIndicator>
    );
  };

  const customStylesImage = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      // width: "200px",
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected ? "#c2d8d9" : "",
      ":hover": {
        backgroundColor: "#e0f5f0",
        color: "#000",
      },

      fontWeight: "500",
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: "0.1rem solid #007a59 ",
      marginTop: "0px",
      borderRadius: "0px",
      zIndex: "2",
      width: "150px",
      transform: "translate(-110px, 0px)",
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: "200px",
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "0rem solid transparent",
      borderRadius: "2px",
    }),

    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
      display: "none",
    }),

    singleValue: (provided: any) => ({
      ...provided,
      backgroundImage: `url(${swapImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "contain", // or 'cover' based on your preference
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "transparent",
    }),
    indicatorContainer: () => ({ display: "none" }), // Hide the default dropdown indicator
    indicatorSeparator: () => ({ display: "none" }),
  };

  return (
    <>
      <div
        className="mx-auto d-flex gap-5 flex-column p-0 m-0 px-2"
        id="kt_app_main"
      >
        {" "}
        <div className="row">
          <div className="col-12">
            <h2 className="page-heading  m-0">
              <b>Interests</b>
            </h2>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {/* Corporate tab */}
          <div>
            <div
              className={
                activeTab == "corporate"
                  ? "tenancy-active-card d-flex align-items-center me-100px"
                  : "tenancy-green-card d-flex align-items-center me-100px"
              }
              onClick={() => {
                setActiveTab("corporate");
                setPage(1);
                setUserType([0]);
                let values = [...checkedValues];

                for (let i = 0; i < tableData?.length; i++) {
                  values[i] = false;
                }

                setCheckedValues(values);
              }}
            >
              <div
                className=""
                style={{
                  fontWeight: "700",
                  width: "fit-content",
                }}
              >
                {/* <img
                src={corporateImg}
                height={22}
                width={22}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              /> */}
              </div>
              <div>
                <h2 className="mb-0 text-white ps-5">
                  {interestCount?.corporateCount
                    ? interestCount?.corporateCount?.toLocaleString("en-US", {})
                    : 0}
                </h2>
                <h5 className="mb-0 text-white ps-5 pt-1">Corporate</h5>
              </div>
            </div>
            {activeTab == "corporate" && <div className="active-filter"></div>}
          </div>
          {/* Landlord Tab */}
          <div>
            <div
              className={
                activeTab == "landlord"
                  ? "tenancy-active-card d-flex align-items-center me-100px"
                  : "tenancy-green-card d-flex align-items-center me-100px"
              }
              onClick={() => {
                setActiveTab("landlord");
                setPage(1);
                setUserType([1]);
                let values = [...checkedValues];

                for (let i = 0; i < tableData?.length; i++) {
                  values[i] = false;
                }

                setCheckedValues(values);
              }}
            >
              <div
                className=""
                style={{
                  fontWeight: "700",
                  width: "fit-content",
                }}
              >
                {/* <img
                src={landlordImg}
                height={21}
                width={21}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              /> */}
              </div>
              <div>
                <h2 className="mb-0 text-white ps-5">
                  {interestCount?.landlordCount
                    ? interestCount?.landlordCount?.toLocaleString("en-US", {})
                    : 0}
                </h2>

                <h5 className="mb-0 text-white ps-5 pt-1">Landlord</h5>
              </div>
            </div>
            {activeTab == "landlord" && <div className="active-filter"></div>}
          </div>

          {/* Tenant Tab */}
          <div>
            <div
              className={
                activeTab == "tenant"
                  ? "tenancy-active-card d-flex align-items-center"
                  : "tenancy-green-card d-flex align-items-center"
              }
              onClick={() => {
                setActiveTab("tenant");

                setPage(1);
                setUserType([2]);

                let values = [...checkedValues];

                for (let i = 0; i < tableData?.length; i++) {
                  values[i] = false;
                }

                setCheckedValues(values);
              }}
            >
              <div
                className=""
                style={{
                  fontWeight: "700",
                  width: "fit-content",
                }}
              >
                {/* <img
                src={tenantImg}
                height={22}
                width={22}
                style={{
                  marginRight: '7px',
                }}
                className='mx-3'
              /> */}
              </div>
              <div>
                <h2 className="mb-0 text-white ps-5">
                  {interestCount?.tenantAppCount
                    ? interestCount?.tenantAppCount?.toLocaleString("en-US", {})
                    : 0}
                </h2>

                <h5 className="mb-0 text-white ps-5 pt-1">Tenant</h5>
              </div>
            </div>
            {activeTab == "tenant" && <div className="active-filter"></div>}
          </div>
        </div>
        {/* Filters */}
        <div
          className="d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst"
          // style={{ justifyContent: "space-between" }}
        >
          <label className="head-text me-5 pe-3" style={{ fontWeight: "600" }}>
            Search{" "}
          </label>

          <div className="me-4">
            <Paper component="form" style={searchContainerStyle}>
              <InputBase
                placeholder="Name..."
                style={inputBaseStyle}
                inputProps={{ "aria-label": "search" }}
                value={searchName}
                onChange={(e: any) => setSearchName(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          {activeTab == "corporate" && (
            <div className="me-4">
              <Paper component="form" style={searchContainerStyle}>
                <InputBase
                  placeholder="Company..."
                  style={inputBaseStyle}
                  inputProps={{ "aria-label": "search" }}
                  value={searchCompanyName}
                  onChange={(e: any) => setSearchCompanyName(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          )}
          <div className="me-4">
            <Select
              styles={customStyles}
              isSearchable={false}
              options={sourceOptions}
              name="source"
              onChange={(e: any) => {
                if (e.value == "all") setSourceType(e.value);
                else setSourceType(Number(e.value));
              }}
              placeholder={"Source"}
            />
          </div>

          <div className="me-4">
            <Select
              styles={customStyles}
              isSearchable={false}
              options={campaignOptions}
              name="campaign"
              onChange={(e: any) => {
                //    setCampaignName(e)
              }}
              placeholder={"Campaign"}
            />
          </div>

          <div className="me-4">
            <Select
              styles={customStylesEmail}
              isSearchable={false}
              options={confirmationOptions}
              name="confirmation"
              onChange={(e: any) => {
                setIsEmailSent(e.value);
              }}
              placeholder={"Email Confirmation"}
            />
          </div>

          <div className="">
            <Select
              styles={customStylesImage}
              isSearchable={false}
              options={sortOptions}
              name="source"
              onChange={(e: any) => {
                // localStorage.setItem('interestSortType', JSON.stringify(e.value))
                setSortType(e.value);
              }}
              components={{ DropdownIndicator }}
            />
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1;
                return <></>;
              })}
            </>
          )}

          {totalSelected > 0 && (
            <>
              <button
                type="button"
                className="btn btn-sm fw-bold term-btn  me-0 ps-2  pe-0 mt-0 ms-4 status-w-120 ms-auto"
                onClick={() => {
                  swal
                    .fire({
                      text: "Are you sure you want to delete the selected requests ?",
                      icon: "warning",
                      showConfirmButton: true,
                      confirmButtonColor: "#D72852",
                      confirmButtonText: "Yes",
                      showCancelButton: true,
                      // cancelButtonColor: "transparent",
                      cancelButtonText: "Cancel",
                    })
                    .then((res) => {
                      if (res.isConfirmed) {
                        deleteInterests();
                      }
                    });
                }}
              >
                <img
                  src={trashImg}
                  height={18}
                  width={18}
                  className="cursor-pointer me-4 ms-2"
                />{" "}
                Delete
              </button>
            </>
          )}

          <button
            type="button"
            className={
              totalSelected > 0
                ? "btn btn-sm fw-bold select-btn me-0 ps-2  pe-0 mt-0  status-w-120 ms-5"
                : "btn btn-sm fw-bold select-btn me-0 ps-2  pe-0 mt-0  status-w-120 ms-auto"
            }
            style={{ width: "" }}
            onClick={() => setRegisterUserModal(true)}
          >
            <img
              src={registerImg}
              height={18}
              width={18}
              className="cursor-pointer me-4 ms-2"
            />
            Register
          </button>

          {/* <div
          className="ok-div d-flex align-items-center justify-content-center cursor-pointer ms-auto"
          // onClick={() => setShowFilter(true)}
        >
          <img src={swapImg} height={18} width={18} />
        </div> */}
        </div>
        <div className="card card-flush py-5 px-5">
          <div
            className="app-main flex-column flex-row-fluid"
            id="------------kt_app_main"
          >
            <div className="table-responsive ">
              <table
                className="table align-start table-row-dashed fs-8 gy-5 "
                id="kt_ecommerce_sales_table"
              >
                <thead className="fs-7">
                  <th className="text-start min-w-25px test">
                    <div className="form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="vacant0"
                        name="none"
                        checked={
                          totalSelected == tableData?.length &&
                          tableData?.length != 0
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          let values = [...checkedValues];
                          if (e.target.checked) {
                            for (let i = 0; i < tableData?.length; i++) {
                              values[i] = true;
                            }
                          } else {
                            for (let i = 0; i < tableData?.length; i++) {
                              values[i] = false;
                            }
                          }
                          setCheckedValues(values);
                        }}
                      />
                    </div>
                  </th>
                  <th className="text-start min-w-100px">First Name</th>
                  <th className="text-start min-w-100px ps-2">Last Name</th>
                  {activeTab == "corporate" && (
                    <th className="text-start min-w-125px ps-2">
                      Company Name
                    </th>
                  )}
                  <th className="text-start min-w-150px ps-2">
                    {activeTab == "corporate" ? "Work Email" : "Email"}{" "}
                  </th>
                  <th className="text-start min-w-125px ps-2">Mobile </th>
                  <th className="text-start min-w-150px ps-2">Submission</th>
                  <th className="text-start min-w-100px ps-2">Source </th>
                  <th className="text-start min-w-150px ps-2">Campaign Name</th>
                  <th className="text-start min-w-150px ps-2">
                    Email Confirmation
                  </th>
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {tableData?.length ? (
                    tableData?.map((req: any, i: any) => {
                      return (
                        <tr>
                          <td className="text-start ps-0 test">
                            <div className="form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="vacant0"
                                name="none"
                                checked={
                                  checkedValues[i] == true ? true : false
                                }
                                onChange={(e: any) => {
                                  const values = [...checkedValues];
                                  values[i] = !values[i];
                                  setCheckedValues(values);
                                }}
                              />
                            </div>
                          </td>

                          <td
                            data-kt-ecommerce-order-filter="order_id"
                            className="text-start ps-0 cursor-pointer pe-2"
                            onClick={() => goToRequest(req)}
                          >
                            {(page - 1) * 10 + (i + 1)}
                            {". "}
                            {req?.firstName ? req?.firstName : "-"}
                          </td>

                          <td
                            data-kt-ecommerce-order-filter="order_id"
                            className="text-start ps-2 cursor-pointer"
                            onClick={() => goToRequest(req)}
                          >
                            {req?.lastName ? req?.lastName : "-"}
                          </td>
                          {activeTab == "corporate" && (
                            <td
                              className="text-start ps-2 cursor-pointer"
                              onClick={() => goToRequest(req)}
                            >
                              {req?.companyName ? req?.companyName : "-"}
                            </td>
                          )}

                          <td
                            className="text-start ps-2 cursor-pointer"
                            onClick={() => goToRequest(req)}
                          >
                            {activeTab == "corporate" ? (
                              <>{req?.companyEmail ? req?.companyEmail : "-"}</>
                            ) : (
                              <>{req?.email ? req?.email : "-"}</>
                            )}
                          </td>
                          <td
                            className="text-start ps-2 cursor-pointer"
                            onClick={() => goToRequest(req)}
                          >
                            {req?.phoneNumber ? `${req?.phoneNumber}` : "-"}
                          </td>

                          <td
                            className="text-start cursor-pointer ps-2"
                            onClick={() => goToRequest(req)}
                          >
                            {req?.createdAt
                              ? `${moment(req?.createdAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )}`
                              : "-"}
                          </td>

                          <td
                            className="text-start cursor-pointer ps-2"
                            onClick={() => goToRequest(req)}
                          >
                            {req?.sourceType === 0
                              ? "Website"
                              : req?.sourceType === 1
                              ? "Propertise Admin"
                              : req?.sourceType === 2
                              ? "Social - Linkedin"
                              : req?.sourceType === 3
                              ? "⁠Social - Instagram"
                              : req?.sourceType === 4
                              ? "Social - Facebook"
                              : req?.sourceType === 5
                              ? "Social - X"
                              : req?.sourceType === 6
                              ? "⁠Google Ads"
                              : req?.sourceType === 7
                              ? "Youtube Ads"
                              : ""}
                          </td>
                          <td
                            className="text-start ps-2 cursor-pointer"
                            onClick={() => goToRequest(req)}
                          >
                            {req?.campaignName ? req?.campaignName : "-"}
                          </td>

                          <td
                            className="text-start ps-2 cursor-pointer"
                            onClick={() => goToRequest(req)}
                          >
                            <div
                              className={
                                req?.isEmailSent
                                  ? "tenancy-stats approve-st"
                                  : "tenancy-stats cancelled-st"
                              }
                            >
                              {req?.isEmailSent ? "Delivered" : "Not Delivered"}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <>
                    { isLoading ? 
                    <tr className="">
                       <td colSpan={10} className="text-center">
                      <CircularProgress color="success" />
                      </td>
                    </tr>
                    :
                    <tr>
                      <td colSpan={10} className="text-center">
                        <img src={noData} alt="" width={350} />
                      </td>
                    </tr>
                     }
                    </>
                  )}
                </tbody>
              </table>
            </div>

            <div className="row mt-5 mb-3">
              <div className="col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5 pe-1">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="kt_ecommerce_sales_table_paginate"
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    pageClassName="paginate_button page-item +"
                    pageLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    previousClassName="paginate_button page-item previous"
                    nextClassName="paginate_button page-item next"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    forcePage={page - 1}
                    previousLabel="<"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  Register User Modal */}
      {registerUserModal && (
        <RegisterModal
          show={registerUserModal}
          handleClose={() => setRegisterUserModal(false)}
          activeTab={activeTab}
          handleSuccess={() => {
            getInterestData();
            interestCountSummary();
            setRegisterUserModal(false);
          }}
        />
      )}
    </>
  );
};

export default Interest;
