import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';


const Tenant = () => {
    const navigate = useNavigate()
    const [tableData, setTableData] = React.useState<any>([5])
    const [formData, setFormData] = React.useState<any>()
    const [pageLimit, setPageLimit] = React.useState<any>()
    const [page, setPage] = React.useState<any>(1)
    const [count, setCount] = React.useState<any>()

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const getCount = () => {
        ApiGet(`admin/tenant/count?corporateId=${window.location.pathname?.split('/')[3]}`)
            .then((res) => {
                // console.log('res', res?.data?.data)
                setCount(res?.data?.data)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    const tenantData = () => {
        let body = {
            page: page,
            limit: 10,
            searchTenant:formData?.searchTenant,
            searchContractNo: formData?.searchContractNo,
            tenancyStatus:Number(formData?.tenancyStatus),
            remainingDays:Number(formData?.remainingDays),
            corporateId: window.location.pathname?.split('/')[3]
        }
        ApiPost('admin/tenant/overview', body)
            .then((res) => {
                setTableData(res?.data?.data?.tenant_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((err) => console.log('err', err))
    }
    useEffect(() => {
        if(window.location.pathname?.split('/')[3]){
            getCount()
            tenantData()
        }
      
    }, [page, formData?.searchContractNo,formData?.searchTenant,formData?.tenancyStatus,formData?.remainingDays])

    const handlePageClick = () => {
    }
    return (
        <div>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/account-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Account Details
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/payment-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4  ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Payments
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/user-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Users
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/property-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Propertise
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenancy-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenancies
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenant-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenants
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {

                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Troubleshooting
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div id='kt_app_content' className='app-content flex-column-fluid'>
                        <div id='kt_app_content_container' className='app-container container-xxl'>
                            <Row>
                                <Col lg={6}>
                                    <div className='card-header align-items-center py-5  gap-md-2 d-flex'>
                                        <div className='mb-2 min-w-200px' style={{ display: 'contents' }}>
                                            <h5 className='gray-500 mx-10'>
                                                Tenants :{' '}
                                                <span className='' style={{ color: 'black' }}>
                                                    {count?.totalTenant}
                                                </span>
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}></Col>
                            </Row>

                            <div className='card-header align-items-center py-5 gy-3 row'>
                                <div className='mb-2 min-w-200px col-md-3 px-10'>
                                    {/* <label className='form-label fw-semibold'>Tenants</label> */}
                                    <div>
                                        <input
                                            type='text'
                                            className='form-control w-auto'
                                            placeholder='Tenants'
                                            name='searchTenant'
                                            // onChange={imageChange}
                                            value={formData?.searchTenant}
                                            onChange={handleChnage}
                                        />
                                    </div>
                                </div>
                                <div className='mb-2 min-w-200px col-md-3 px-10'>
                                    {/* <label className='form-label fw-semibold'>Contract No.</label> */}
                                    <div>
                                        <input
                                            type='text'
                                            className='form-control w-auto'
                                            placeholder='Contract No'
                                            name='searchContractNo'
                                            // onChange={imageChange}
                                            value={formData?.searchContractNo}
                                            onChange={handleChnage}
                                        />
                                    </div>
                                </div>

                                <div className='mb-2 min-w-200px col-md-3 px-10'>
                                    {/* <label className='form-label fw-semibold'> Tenancy Status</label> */}
                                    <div>
                                        <select
                                            className='form-select'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-dropdown-parent='#kt_menu_631f08e971923'
                                            data-allow-clear='true'
                                            name='tenancyStatus'
                                            // onChange={imageChange}
                                            value={formData?.tenancyStatus}
                                            onChange={handleChnage}
                                        >
                                            <option disabled selected>
                                                Tenancy Status
                                            </option>
                                            <option value={0}>Booked</option>
                                            <option value={1}>Active</option>
                                            <option value={2}>Expiring</option>
                                            <option value={3}>Ended</option>
                                            <option value={4}>Renewed</option>
                                            <option value={5}>Terminated</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='mb-2 min-w-200px col-md-3 px-10'>
                                    {/* <label className='form-label fw-semibold'>Remaining Days</label> */}
                                    <div>
                                        <input
                                            type='number'
                                            className='form-control'
                                            placeholder='Remaining Days'
                                            name='remainingDays'
                                            // onChange={imageChange}
                                            value={formData?.remainingDays}
                                            onChange={handleChnage}
                                        />

                                    </div>
                                </div>


                            </div>
                            <div className='card card-flush'>

                                <div className='card-body pt-0 table-responsive mt-5'>
                                    <table
                                        className='table align-middle table-row-dashed fs-6 gy-5'
                                        id='kt_ecommerce_sales_table'
                                    >
                                        <thead>
                                            <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                                <th className='min-w-100px'>Tenant ID </th>
                                                <th className='min-w-100px'> Name </th>
                                                <th className='min-w-70px'>Type</th>
                                                <th className='min-w-70px'>Registration Status</th>
                                                <th className='min-w-70px'>Tenancy Status </th>
                                                <th className='min-w-70px'> Development</th>
                                                <th className='min-w-70px'>Unit</th>
                                                <th className='min-w-70px'>Contract No</th>
                                                <th className='min-w-70px'>  Start - End </th>
                                                <th className='min-w-70px'>Duration</th>
                                                <th className='min-w-70px'>Remaining</th>
                                                <th className='min-w-70px'> Property </th>
                                                <th className='min-w-70px'></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className='fw-semibold text-gray-600'>
                                            {tableData?.length ?tableData?.map((v: any, i: any) => {
                                                return (
                                                    <tr>
                                                        <td data-kt-ecommerce-order-filter='order_id' className='text-center'>
                                                            {v?._id}
                                                        </td>
                                                        <td className='text-center '>
                                                            {v?.firstName} {v?.lastName}
                                                        </td>
                                                        <td className='text-center' data-order='Denied'>
                                                            {v?.tenantType === 0 ? 'Main ' : 'Sub'}
                                                        </td>
                                                        <td className='text-center'>Registered</td>
                                                        <td className='text-center' data-order='2022-09-09'>
                                                            {v?.tenancy?.tenancyStatus === 0
                                                                ? 'Booked'
                                                                : v?.tenancy?.tenancyStatus === 1
                                                                    ? 'Active'
                                                                    : v?.tenancy?.tenancyStatus === 2
                                                                        ? 'Expiring'
                                                                        : v?.tenancy?.tenancyStatus === 3
                                                                            ? 'Ended'
                                                                            : v?.tenancy?.tenancyStatus === 4
                                                                                ? 'Renewed'
                                                                                : 'terminated'}
                                                        </td>
                                                        <td className='text-center' data-order='2022-09-11'>
                                                            {v?.tenancy?.community[0]?.name}
                                                        </td>
                                                        <td className='text-center' data-order='2022-09-11'>
                                                            {v?.tenancy?.unit[0]?.unitNo}
                                                        </td>
                                                        <td className='text-center' data-order='2022-09-11'>
                                                            {v?.tenancy?.contractNo}
                                                        </td>
                                                        <td className='text-center' data-order='2022-09-11'>
                                                            {moment(v?.tenancy?.duration?.start_date).format('DD/MM/YYYY')} -{' '}
                                                            {moment(v?.tenancy?.duration?.end_date).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td className='text-center' data-order='2022-09-11'>
                                                            {v?.tenancy?.duration?.days} Days
                                                        </td>
                                                        <td className='text-center' data-order='2022-09-11'>
                                                            {v?.tenancy?.remainingDays} Days
                                                        </td>
                                                        <td className='text-center' data-order='2022-09-11'>
                                                            <span className='svg-icon svg-icon-2' style={{ color: '#ffad0f' }}>
                                                                <svg
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                >
                                                                    <path
                                                                        d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                                                        fill='currentColor'
                                                                    ></path>
                                                                </svg>
                                                            </span>
                                                            4.5
                                                        </td>
                                                    </tr>
                                                    
                                                                                                )
                                            })
                                            : <tr><td colSpan={12} className="100 text-center"> No Data Found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                    </div>
                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                        <div
                            className='dataTables_paginate paging_simple_numbers'
                            id='kt_ecommerce_sales_table_paginate'
                        >
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                pageClassName="paginate_button page-item +"
                                pageLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                previousClassName="paginate_button page-item previous disabled"
                                nextClassName="paginate_button page-item next"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageLimit}
                                previousLabel="<"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tenant
