/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItemWithSub
        to='/dashboard'
        title='Dashboard'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <SidebarMenuItem to='/dashboard' title='Dashboard' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/corporate'
        title='Corporate'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <SidebarMenuItem to='/corporate' title='Corporate' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/trouble-shooting'
        title='Troubleshooting'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <SidebarMenuItem to='/trouble-shooting' title='Troubleshooting' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Web INquiry */}
      {/* <SidebarMenuItemWithSub
        to='/web-inquery'
        title='WebInquery'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <SidebarMenuItem to='/web-inquery' title='WebInquery' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

       {/* Payments */}
      <SidebarMenuItemWithSub
        to='/payments'
        title='Payments'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <SidebarMenuItem to='/payments' title='Payments' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Interest */}
      <SidebarMenuItemWithSub
        to='/interests'
        title='Interests'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/art/art002.svg'
      >
        <SidebarMenuItem to='/interests' title='Interests' hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  )
}

export { SidebarMenuMain }
