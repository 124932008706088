import React from 'react'

const WebInquery = () => {
  return (
    <div>
      WebInquery
    </div>
  )
}

export default WebInquery
