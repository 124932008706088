import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import { DatePicker } from 'antd'
import 'antd/dist/antd.css'
import { ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';
import type { RangePickerProps } from 'antd/es/date-picker'

let sendReminder: any = []

const Corporate = () => {
    const navigate = useNavigate()
    const [formData, setFormData] = React.useState<any>()
    const [pageLimit, setPageLimit] = React.useState<any>()
    const [corporateData, setCorporateData] = React.useState<any>()
    const [page, setPage] = React.useState<any>(1)
    const [reminderArray, setReminderArray] = React.useState<any>([])
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    const { RangePicker } = DatePicker
    const dateFormat = 'YYYY-MM-DD'
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day')
    }

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const getData = async () => {
        const body = {
            page: page,
            limit: 10,
            companyName: formData?.companyName ? formData?.companyName : '',
            tradeLicense: formData?.tradeLicense ? formData?.tradeLicense : '',
            TRN: formData?.trn ? formData?.trn : '',
            subscriptionStartDate: start ? start : "",
            subscriptionEndDate: end ? end : ""
        }
        await ApiPost(`admin/corporate/overview`, body)
            .then((res) => {
                setCorporateData(res?.data?.data?.corporate_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    React.useEffect(() => {
        getData()
    }, [page, formData?.companyName, formData?.tradeLicense, formData?.trn, start, end])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }

    const handleChnageTenancyDate = (e: any) => {
        setStart(moment(e[0]?._d).format('YYYY-MM-DD'))
        setEnd(moment(e[1]?._d).format('YYYY-MM-DD'))
    }

    const handeleSelectVendor = (id: any) => {
        if (reminderArray.includes(id)) {
            sendReminder = sendReminder.filter((item: any) => item !== id)
                setReminderArray(sendReminder)
        } else {
            sendReminder.push(id)
            setReminderArray((reminderArray: any) => [...reminderArray, id]);
        }
    }

    const sendRenewalReminder = async () => {
        if (reminderArray.length > 0) {
            const body = {
                corporateIds: reminderArray
            }

            await ApiPost(`admin/corporate/renewal/remainder`, body)
                .then((res) => {
                    SuccessToast(res.data.message)
                    sendReminder = [];
                    setReminderArray(sendReminder)
                })
                .catch((e) => {
                    console.log('e', e)
                })
        } else {
            ErrorToast('please select user from listing')

        }

    }

    return (
        <>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <Row className='mb-5'>
                        <div className='col-3'>
                            <div className='d-flex align-items-center'>
                                <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                    Company Name
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='companyName'
                                    // onChange={imageChange}
                                    value={formData?.companyName}
                                    onChange={handleChnage}
                                />

                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='d-flex align-items-center'>
                                <label htmlFor='exampleFormControlInput1' className="w-200px  form-label m-0">
                                    Trade License
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='tradeLicense'
                                    // onChange={imageChange}
                                    value={formData?.tradeLicense}
                                    onChange={handleChnage}
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='d-flex align-items-center'>
                                <label htmlFor='exampleFormControlInput1' className="w-80px form-label m-0">
                                    TRN
                                </label>
                                <input
                                    type='text'
                                    className='form-control '
                                    name='trn'
                                    // onChange={imageChange}
                                    value={formData?.trn}
                                    onChange={handleChnage}
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='d-flex align-items-center'>
                                <label htmlFor='exampleFormControlInput1' className='form-label'>
                                    Start - End
                                </label>
                                <RangePicker
                                    defaultValue={[
                                        moment(start !== '' ? moment(start) : new Date(), dateFormat),
                                        moment(end !== '' ? moment(end) : new Date(), dateFormat),
                                    ]}
                                    // {...register("start", { required: true })}
                                    format={dateFormat}
                                    onChange={handleChnageTenancyDate}
                                    name='start'
                                    className='form-control'
                                />
                            </div>
                        </div>
                    </Row>

                    <div className='mb-8 min-w-375px d-flex justify-content-between w-auto'>
                        <div className='d-flex align-items-center'>
                            <button className='btn btn-sm fw-bold btn-primary btn-green hover-none' onClick={() => navigate('/corporate/account-detail')}>Create Account</button>
                            <button className='btn btn-sm fw-bold btn-primary btn-green hover-none' style={{ marginLeft: '5px' }} onClick={sendRenewalReminder}>Send Renewal Reminder</button>
                        </div>
                        <div className='d-flex'>

                        </div>
                    </div>
                    <div className='card card-flush'>
                        <div className='card-body pt-0 table-responsive mt-5'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0'>
                                        <th className='text-center min-w-10px'></th>
                                        <th className='min-w-100px'>Account ID </th>
                                        <th className='min-w-100px'>Company Name</th>
                                        <th className='min-w-70px'>Country</th>
                                        <th className='min-w-70px'>City</th>
                                        <th className='min-w-70px'>Trade License</th>
                                        <th className='min-w-70px'>TRN</th>
                                        <th className='min-w-70px'>Creation Date</th>
                                        <th className='min-w-70px'>Account Status</th>
                                        <th className='min-w-70px'>Renewal Status</th>
                                        <th className='min-w-70px'>Subscription Status</th>
                                        <th className='min-w-100px'>Subscription Type</th>
                                        <th className='min-w-100px'>Subscription Start</th>
                                        <th className='min-w-100px'>Subscription End</th>
                                        <th className='min-w-100px'>Amount</th>
                                        <th className='min-w-100px'>Payment Status</th>
                                        <th className='min-w-100px'>Total Users</th>
                                        <th className='min-w-100px'>Total Propertise</th>
                                        <th className='min-w-100px'>Total Tenants</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {corporateData?.map((v: any, i: any) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={reminderArray.includes(v?._id) ? true : false} onChange={(e) => handeleSelectVendor(v?._id)} />
                                                    </div>
                                                </td>
                                                <td>{v?.id}</td>
                                                <td>{v?.companyName
                                                }</td>
                                                <td>{v?.country}</td>
                                                <td>{v?.city}</td>
                                                <td>{v?.tradeLicense}</td>
                                                <td>{v?.TRN
                                                }</td>
                                                <td>{moment(v?.createdAt).format('DD/MM/YYYY')}</td>
                                                <td>{v?.accountStatus === 0 ? 'Draft' :
                                                    v?.accountStatus === 1 ? 'Active'
                                                        : v?.accountStatus === 2 ? 'In Active'
                                                            : v?.accountStatus === 3 ? 'Expiring'
                                                                : v?.accountStatus === 3 ? 'Cancelled'
                                                                    : 'Suspended'}</td>
                                                                    <td>{v?.isRenewed  ? 'Yes' : 'No'}</td>
                                                                    <td>{v?.corporate_subscription?.status === 0 ? 'In Active' : v?.corporate_subscription?.status === 1 ? 'Active' :'Renewal'}</td>
                                                <td>{v?.corporate_subscription?.subscriptionType === 0 ? 'Free Demo' : 'Paid Subscription'}</td>
                                                
                                                <td>{moment(v?.corporate_subscription?.startDate).format('DD/MM/YYYY')}</td>
                                                <td>{moment(v?.corporate_subscription?.endDate).format('DD/MM/YYYY')}</td>
                                                <td>{(v?.corporate_subscription?.totalAmount).toFixed(2)}</td>
                                                <td>{v?.corporate_subscription?.paymentStatus === 0 ? 'Not Paid'
                                                    : v?.corporate_subscription?.paymentStatus === 1 ? 'Partial Paid' : 'Paid'}</td>
                                                <td>{v?.corporate_subscription?.totalUsers}</td>
                                                <td>{v?.corporate_subscription?.totalPropertise}</td>
                                                <td>{v?.totalTenants}</td>
                                                <td className='text-center'>
                                                    <div className='menu-item px-3'>
                                                        <div
                                                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                            onClick={() => navigate(`/corporate/account-detail/${v?._id}`)}
                                                        >
                                                            View
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                            </div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='kt_ecommerce_sales_table_paginate'
                                >
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        pageClassName="paginate_button page-item +"
                                        pageLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        previousClassName="paginate_button page-item previous disabled"
                                        nextClassName="paginate_button page-item next"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageLimit}
                                        previousLabel="<"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >

        </>
    )
}

export default Corporate
