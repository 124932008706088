import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiGet, ApiGetNoAuth, ApiPost, ApiPut, ApiUpload, Bucket } from '../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast'
import ReactPhoneInput from 'react-phone-input-2'
import AccountFeaturePopup from './AccountFeaturePopup'
import SVG from 'react-inlinesvg'


import SuspendAccount from './SuspendAccount'
import moment from 'moment'
import { parse } from 'path'

const propertyManagement = [
    { title: "Property Registration", name: 'property', checked: false },
    { title: "Tenancy Management ", name: 'tenancy', checked: false },
    { title: "Tenant Management ", name: 'tenant', checked: false },
    { title: "Rent Management", name: 'rent', checked: false },
    { title: "Announcement Management", name: 'announcement', checked: false },
]

const serviceManagement = [
    { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
    { title: 'External Service Requests', name: 'externalServiceRequest', checked: false }
]

let vendorManagement = [
    { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
]
let facilityManagement = [
    { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
]

let itemarray:any = []
let totalselect:any = 0

const AccountDetail = () => {
    const navigate = useNavigate()
    const [accountDetail, setAccountDetail] = React.useState<any>()
    const [item, setItem] = React.useState<any>()

    const [show, setShow] = React.useState<any>(false)

    const [uploadedFile, setUploadedFile] = React.useState<any>("")
    const [countryName, setCountryName] = React.useState<any>("")
    const [checkAll, setCheckAll] = React.useState<any>()
    const [venderAll, setvendorAll] = React.useState<any>()
    const [facilityAll, setfacilityAll] = React.useState<any>()

    const [city, setCity] = React.useState([])
    const [country, setCountry] = React.useState([])
    const [start, setStart] = React.useState('')
    const [end, setEnd] = React.useState('')
    const [totalDays, setTotalDays] = React.useState('0 Days')
    const [tableData, setTableData] = React?.useState([5])
    const [accountfeature, setAccountFeature] = React?.useState<any>(propertyManagement)
    const [suspendAccount, setSuspendAccount] = React.useState<any>(false)
    const [vendor, setVendor] = React.useState<any>({ 'vendorList': false })
    const [facility, setFacility] = React?.useState<any>({ 'service': false })
    const [service, setService] = React.useState<any>(serviceManagement)
    const [allService, setAllService] = React.useState<any>()
    const [phoneNumber, setPhoneNumber] = React.useState<any>()
    const [accountStatus, setAccountStatus] = React.useState<any>()
    const [generateAccount, setGenerateAccount] = React.useState<any>('generateaccount')
    const [accountData, setAccountData] = React.useState<any>()
    const [subscriptionData, setSubscriptionData] = React.useState<any>()
    const [suspendData, setSuspendData] = React.useState<any>({})
    const [activateAccount, setActivateAccount] = React.useState<any>(false)
    const [account, setAccount] = React.useState<any>(0)
    const [count, setcount] = React.useState<any>(0)
    const [permissionflag, Setpermissionflag] = React.useState<any>(false)

    const [submitRenewal, setSubmitRenewal] = React.useState<any>(false)
    const [subscription, setSubscription] = React.useState<any>(false)

    const { register, handleSubmit, watch, control, reset, formState: { errors } } = useForm<any>();

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        console.log(name, value)
        if(name === 'discount'){
            console.log((parseInt(value) - Math.floor(parseInt(value))) !== 0)
            if(parseInt(value)>100){
                console.log('sdds')
                ErrorToast('Discount Not More than 100')
                return false
            }
            // if((parseInt(value) - Math.floor(parseInt(value))) !== 0){
            //     console.log('sdds')
            //     ErrorToast('Discount Not Valid Decimal Number')
            //     return false
            // }
            setAccountDetail({ ...accountDetail, [name]: value })
        }else if(name === 'startDate' || name === 'endDate'){
            // console.log("ssdsdsdsds",accountDetail?.endDate)
            // setAccountDetail({ ...accountDetail, 'totalDays': 1 })
            let diffDays:any
            if((name === 'startDate'&&accountDetail?.endDate&&value) ){
                
                const oneDay = 24 * 60 * 60 * 1000
                const firstDate:any = new Date(value);
                const secondDate:any = new Date(accountDetail?.endDate);

 diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
// setAccountDetail({ ...accountDetail, 'totalDays': 1 })
console.log(value,"dfsdfsfsdfsdf",firstDate,secondDate,diffDays)
            }else if((name === 'endDate'&&accountDetail?.startDate&&value) ){
                setAccountDetail({ ...accountDetail, 'totalDays': 1 })
                const oneDay = 24 * 60 * 60 * 1000
                const firstDate:any = new Date(accountDetail?.startDate);
                const secondDate:any = new Date(value);

 diffDays= Math.round(Math.abs((firstDate - secondDate) / oneDay));
console.log(value,"dfsdfsfsdfsdf",firstDate,secondDate,diffDays)

            }
            setAccountDetail({...accountDetail, [name]: value, 'totalDays': diffDays+1 })
        }else{
            setAccountDetail({...accountDetail, [name]: value })
        }
        
    }
    console.log(accountDetail)
    const implementationcount = () =>{
        console.log("dfsdfsdf")
        itemarray.push({
            description:"",
            qty:0,
            unitPrice:0,
            discountInPercentage:0,
            monthDuration:0,
            discountAmount:0,
            taxableAmount:0,
            isEditable:true
        })
        setcount(count+1)
    }
    const implementationdelete = (i:any) =>{
        console.log("dfsdfsdf")
        itemarray.splice(i, 1);
        setcount(count+1)
    }
    const handleChnage1 = (e: any,i:any) => {
        console.log(e)
        const { name, value } = e.target
        if(name=="qty" || name=="unitPrice" ){
            itemarray[i][name] = parseInt(value)

        }else{
            itemarray[i][name] = value

        }
        console.log(itemarray)
        if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && value)){
            console.log("jayraj")
            itemarray[i]["discountAmount"] = (itemarray[i].qty*itemarray[i].unitPrice)*itemarray[i].discountInPercentage/100
            itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
        }else if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && !value)){
            console.log("jayraj1")
            itemarray[i]["discountAmount"] = 0
            itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
            // itemarray[i]["discountAmount"] = (itemarray[i].qty*itemarray[i].unitPrice)*itemarray[i].discountInPercentage/100
            // itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
        }
        else{
             console.log("jayraj2")
            if(itemarray[i].discountInPercentage){
                itemarray[i]["discountAmount"] = (itemarray[i].qty*itemarray[i].unitPrice)*itemarray[i].discountInPercentage/100
                itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
            }else{
                itemarray[i]["discountAmount"] = 0
                itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
            }
            // console.log("jayraj2")
            // itemarray[i]["discountAmount"] = 0
            // itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
        }

        setcount(count+1)
    
        // setItem({ ...item, [name]: value })
    }

    const getCountry = async () => {
        await ApiGetNoAuth('country')
            .then((res) => {
                setCountry(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }


    const uploadFilelHandler = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setUploadedFile(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }
    const handleCountryChnage = async (e: any) => {
        const { name, value } = e.target
        const data = value.split('/')
        setCountryName(data[1]);
        await ApiGet(`country/cities?countryId=${data[0]}`)
            .then((res) => {
                setCity(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const handleNumber = (Phone: any, e: any) => {
        let CountryCode = e.dialCode
        let PhoneNumber = Phone.split(CountryCode)[1]

        setAccountDetail({
            ...accountDetail,
            ['countryCode']: parseInt(CountryCode),
            ['phoneNumber']: PhoneNumber,
        })
    }

    const handleNumber2 = (Phone: any, e: any) => {
        let CountryCode = e.dialCode
        let PhoneNumber = Phone.split(CountryCode)[1]

        setPhoneNumber({
            ...phoneNumber,
            ['countryCode']: parseInt(CountryCode),
            ['phoneNumber']: PhoneNumber,
        })
    }

    const accountFeature = (index: any, e: any) => {
        console.log('Check123',index,e,checkAll)
        if (checkAll) {
            if (!e.target.checked) {
                console.log(e.target.checked)
                setCheckAll(e.target.checked)
            }
        }
        var total: any = 0;
        accountfeature?.map((topping: any, currentIndex: any) =>
            topping.checked ? total = total + 1 : total,
        )
        if (total === accountfeature?.length - 1 && e.target.checked) {
            total = 0
            setCheckAll(!checkAll)
        }
        setAccountFeature(
            accountfeature?.map((topping: any, currentIndex: any) =>
                currentIndex === index ? { ...topping, checked: !topping.checked } : topping

            )
        )
    }
    const inFuture = (date: any,toleranceMillis:any = 0) => {
        console.log(date)
        let addd = new Date(date)
        console.log(addd.getTime() > Date.now() + toleranceMillis,"fdgfdgfdgfdgfg")
        return addd.getTime() > Date.now() + toleranceMillis
    };
    const CheckAll = (e: any) => {
        console.log('Check',e.target.checked)
        setCheckAll(e.target.checked)
        setAccountFeature(
            (accountfeature.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }
    const vendorext = (e: any) => {
        console.log('Check',e.target.checked)
        setvendorAll(e.target.checked)
        // if(e.target.checked){
            vendorManagement[0].checked = e.target.checked
        // }
        // setCheckAll(e.target.checked)
        // setAccountFeature(
        //     (accountfeature.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }
    const facilityext = (e: any) => {
        console.log('Check',e.target.checked)
        setfacilityAll(e.target.checked)
        // if(e.target.checked){
            facilityManagement[0].checked = e.target.checked
        // }
        // setCheckAll(e.target.checked)
        // setAccountFeature(
        //     (accountfeature.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }
    
    const selectAllService = (e: any) => {
        setAllService(e.target.checked)
        setService(
            (service.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }

    const selectServices = (index: any, e: any) => {
        if (allService) {
            if (!e.target.checked) {
                setAllService(e.target.checked)
            }
        }
        var total: any = 0;
        service?.map((topping: any, currentIndex: any) =>
            topping.checked ? total = total + 1 : total,
        )
        if (total === service?.length - 1 && e.target.checked) {
            total = 0
            setAllService(!allService)
        }
        setService(
            service?.map((topping: any, currentIndex: any) =>
                currentIndex === index ? { ...topping, checked: !topping.checked } : topping

            )
        )
    }
    const saveAccountData = async (type:any) => {
        console.log(type)
        const body = {
            firstName: accountDetail?.firstname ? accountDetail?.firstname : '',
            lastName: accountDetail?.lastName ? accountDetail?.lastName : '',
            country: countryName ? countryName : '',
            address: accountDetail?.address,
            phoneNumber: accountDetail?.phoneNumber ? accountDetail?.phoneNumber.toString() : '',
            email: accountDetail?.contractEmail ? accountDetail?.contractEmail : '',
            companyName: accountDetail?.companyName ? accountDetail?.companyName : '',
            accountOwnerCountryCode: phoneNumber?.countryCode,
            accountOwnerMobile: phoneNumber?.phoneNumber ? phoneNumber?.phoneNumber.toString() : '',
            tradeLicense: accountDetail?.tradeLicenseNo ? accountDetail?.tradeLicenseNo : '',
            tradeLicenseURL: uploadedFile,
            TRN: accountDetail?.trn,
            accountStatus: type,
            city: accountDetail?.city,
            countryCode: accountDetail?.countryCode ? accountDetail?.countryCode : '',
            subscription: {
                remainingDays: Number(accountDetail?.totalDays) ? Number(accountDetail?.totalDays) : 0,
                totalDays: Number(accountDetail?.totalDays) ? Number(accountDetail?.totalDays) : 0,
                paymentCycle:Number(accountDetail?.paymentCycle) ? Number(accountDetail?.paymentCycle):0,
                subTotal: Number(accountDetail?.totalPropertise * accountDetail?.ratePerProperty) ? Number(accountDetail?.totalPropertise * accountDetail?.ratePerProperty) : 0,
                discountInPercentage: Number(accountDetail?.discount)?Number(accountDetail?.discount):0,
                discountAmount: Number(accountDetail?.totalPropertise) && Number(accountDetail?.discount) && Number(accountDetail?.discount)!=0  ? ((accountDetail?.totalPropertise * accountDetail?.ratePerProperty)*(accountDetail?.discount))/100 :  0,
                totalAmount: Number(accountDetail?.totalPropertise) && Number(accountDetail?.discount) && Number(accountDetail?.discount)!=0  ? (accountDetail?.totalPropertise * accountDetail?.ratePerProperty)-((accountDetail?.totalPropertise * accountDetail?.ratePerProperty)*(accountDetail?.discount))/100 : accountDetail?.totalPropertise && (!accountDetail?.discount || accountDetail?.discount==0) ? accountDetail?.totalPropertise * accountDetail?.ratePerProperty: 0,
                paymentStatus: Number(accountDetail?.paymentStatus) ? Number(accountDetail?.paymentStatus) : 0,
                totalPropertise: Number(accountDetail?.totalPropertise) ? Number(accountDetail?.totalPropertise) : 0,
                totalCommonAreas: Number(accountDetail?.totalCommonArea) ? Number(accountDetail?.totalCommonArea) : 0,
                totalUsers: Number(accountDetail?.totalUser) ? Number(accountDetail?.totalUser) : 0,
                ratePerProperty: Number(accountDetail?.ratePerProperty) ? Number(accountDetail?.ratePerProperty) : 0,
                subscriptionType: Number(accountDetail?.subscriptionType) ? Number(accountDetail?.subscriptionType) : 0,
                startDate: accountDetail?.startDate ? accountDetail?.startDate : null,
                endDate: accountDetail?.endDate ? accountDetail?.endDate : null,
                items:itemarray,
                status:inFuture(accountDetail?.startDate)?2:1,
                accountFeature: {
                    numberOfSelected: (accountfeature.filter((e:any) => e.checked).length)+(service.filter((e:any) => e.checked).length)+(vendorManagement.filter((e:any) => e.checked).length)+(facilityManagement.filter((e:any) => e.checked).length),
                    numberOfTotal: 9,
                    property: accountfeature[0]?.checked,
                    tenancy: accountfeature[1]?.checked,
                    tenant: accountfeature[2]?.checked,
                    rent: accountfeature[3]?.checked,
                    announcement: accountfeature[4]?.checked,
                    vendorList: vendorManagement[0]?.checked,
                    service: facilityManagement[0]?.checked,
                    internalServiceRequest: service[0]?.checked,
                    externalServiceRequest: service[1]?.checked
                }
            },
            
        }


        await ApiPost(`admin/corporate`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                getAccountById(res?.data?.data?.corporateData?._id)
                setAccount(true)
                 navigate(`/corporate/account-detail/${res?.data?.data?.corporateData?._id}`)

            })
            .catch((e) => {
                console.log('e', e)
                ErrorToast(e.message)
            })
    }

    const onSubmit = async () => {
        setGenerateAccount('generateaccount')
        // if (accountData?.accountStatus === 0) {
        //     const body = {
        //         id: accountData?._id,
        //         accountStatus: 1,
        //         firstName: accountDetail?.firstname,
        //         lastName: accountDetail?.lastName,
        //         country: countryName,
        //         address: accountDetail?.address,
        //         phoneNumber: phoneNumber?.phoneNumber.toString(),
        //         email: accountDetail?.contractEmail,
        //         companyName: accountDetail?.companyName,
        //         accountOwnerCountryCode: accountDetail?.countryCode ? accountDetail?.countryCode : '',
        //         accountOwnerMobile: accountDetail?.phoneNumber ? accountDetail?.phoneNumber.toString() : '',
        //         tradeLicense: accountDetail?.tradeLicenseNo,
        //         tradeLicenseURL: uploadedFile,
        //         TRN: accountDetail?.trn,
        //         city: accountDetail?.city,
        //         countryCode: phoneNumber?.countryCode,
        //         subscription: {
        //             remainingDays: accountDetail?.days,
        //             totalAmount: Number(accountDetail?.totalPropertise * accountDetail?.ratePerProperty),
        //             paymentStatus: Number(accountDetail?.paymentStatus),
        //             totalPropertise: Number(accountDetail?.totalPropertise),
        //             totalCommonAreas: Number(accountDetail?.totalCommonArea),
        //             totalUsers: Number(accountDetail?.totalUser),
        //             ratePerProperty: Number(accountDetail?.ratePerProperty),
        //             subscriptionType: Number(accountDetail?.subscriptionType),
        //             startDate: accountDetail?.startDate ? accountDetail?.startDate : null,
        //             endDate: accountDetail?.endDate ? accountDetail?.endDate : null
        //         },
        //         accountPermission: {
        //             property: accountfeature[0]?.checked,
        //             tenancy: accountfeature[1]?.checked,
        //             tenant: accountfeature[2]?.checked,
        //             rent: accountfeature[3]?.checked,
        //             announcement: accountfeature[4]?.checked,
        //             vendorList: vendor?.vendorList,
        //             service: facility?.service,
        //             internalServiceRequest: service[0]?.checked,
        //             externalServiceRequest: service[1]?.checked
        //         }
        //     }
        //     await ApiPut(`admin/corporate`, body)
        //         .then((res) => {
        //             SuccessToast(res?.data?.message)
        //             setGenerateAccount('editsubscription')
        //             getAccountById(accountData?._id)

        //         })
        //         .catch((e) => {
        //             console.log('e', e)
        //             ErrorToast(e.message)
        //         })
        // } else {
        //     const body = {
        //         firstName: accountDetail?.firstname,
        //         lastName: accountDetail?.lastName,
        //         country: countryName,
        //         address: accountDetail?.address,
        //         phoneNumber: phoneNumber?.phoneNumber.toString(),
        //         email: accountDetail?.contractEmail,
        //         companyName: accountDetail?.companyName,
        //         accountOwnerCountryCode: accountDetail?.countryCode ? accountDetail?.countryCode : '',
        //         accountOwnerMobile: accountDetail?.phoneNumber ? accountDetail?.phoneNumber.toString() : '',
        //         tradeLicense: accountDetail?.tradeLicenseNo,
        //         tradeLicenseURL: uploadedFile,
        //         TRN: accountDetail?.trn,
        //         accountStatus: accountStatus,
        //         city: accountDetail?.city,
        //         countryCode: phoneNumber?.countryCode,
        //         subscription: {
        //             remainingDays: accountDetail?.days,
        //             totalAmount: Number(accountDetail?.totalPropertise * accountDetail?.ratePerProperty),
        //             paymentStatus: Number(accountDetail?.paymentStatus),
        //             totalPropertise: Number(accountDetail?.totalPropertise),
        //             totalCommonAreas: Number(accountDetail?.totalCommonArea),
        //             totalUsers: Number(accountDetail?.totalUser),
        //             ratePerProperty: Number(accountDetail?.ratePerProperty),
        //             subscriptionType: Number(accountDetail?.subscriptionType),
        //             startDate: accountDetail?.startDate ? accountDetail?.startDate : null,
        //             endDate: accountDetail?.endDate ? accountDetail?.endDate : null
        //         },
        //         accountPermission: {
        //             property: accountfeature[0]?.checked,
        //             tenancy: accountfeature[1]?.checked,
        //             tenant: accountfeature[2]?.checked,
        //             rent: accountfeature[3]?.checked,
        //             announcement: accountfeature[4]?.checked,
        //             vendorList: vendor?.vendorList,
        //             service: facility?.service,
        //             internalServiceRequest: service[0]?.checked,
        //             externalServiceRequest: service[1]?.checked
        //         }
        //     }
        //     await ApiPost(`admin/corporate`, body)
        //         .then((res) => {
        //             SuccessToast(res?.data?.message)
        //             setGenerateAccount('editsubscription')
        //             getAccountById(res?.data?.data?.corporateData?._id)

        //         })
        //         .catch((e) => {
        //             console.log('e', e)
        //             ErrorToast(e.message)
        //         })
        // }

    }
    const getAccountById = async (id: any) => {
        await ApiGet(`admin/corporate/${id}`)
            .then((res) => {
                if (res?.data?.data[0]) {
                    setAccountData(res?.data?.data[0])
                    setAccount(res?.data?.data[0]?.accountStatus)
                    getSubscriptionData(res?.data?.data[0]?.corporate_subscription[0]?._id)
                }

            })
            .catch((e) => {
                console.log('e', e)
            })

    }
    console.log(accountDetail)
    const editSubscription = async () => {
        setGenerateAccount('generateaccount')
        if (generateAccount === 'generateaccount') {
            const body = {
                id: accountData?.corporate_subscription[0]?._id,
                remainingDays: accountDetail?.days,
                totalAmount: Number(accountDetail?.totalPropertise * accountDetail?.ratePerProperty),
                paymentStatus: Number(accountDetail?.paymentStatus),
                totalPropertise: Number(accountDetail?.totalPropertise),
                totalCommonAreas: Number(accountDetail?.totalCommonArea),
                totalUsers: Number(accountDetail?.totalUser),
                ratePerProperty: Number(accountDetail?.ratePerProperty),
                subscriptionType: Number(accountDetail?.subscriptionType),
                startDate: accountDetail?.startDate,
                endDate: accountDetail?.endDate
            }
            await ApiPut(`admin/corporate_subscription`, body)
                .then((res) => {
                    SuccessToast(res?.data?.message)
                    getSubscriptionData(res?.data?.data?._id)
                    setGenerateAccount('editsubscription')
                    setSubscription(false)

                })
                .catch((e) => {
                    console.log('e', e)
                })
        }
    }

    const renewSubscription = async () => {
        setGenerateAccount('generateaccount')

        if (generateAccount === 'generateaccount') {
            const body = {
                corporateId: accountData?.corporate_subscription[0]?._id,
                remainingDays: Number(accountDetail?.days),
                totalAmount: Number(accountDetail?.totalPropertise * accountDetail?.ratePerProperty),
                totalPropertise: Number(accountDetail?.totalPropertise),
                totalCommonAreas: Number(accountDetail?.totalCommonArea),
                totalUsers: Number(accountDetail?.totalUser),
                ratePerProperty: Number(accountDetail?.ratePerProperty),
                subscriptionType: Number(accountDetail?.subscriptionType),
                startDate: accountDetail?.startDate,
                endDate: accountDetail?.endDate
            }
            await ApiPost(`admin/corporate_subscription`, body)
                .then((res) => {
                    SuccessToast(res?.data?.message)
                    getSubscriptionData(res?.data?.data?._id)
                    setGenerateAccount('editsubscription')
                    setSubmitRenewal(false)
                })
                .catch((e) => {
                    console.log('e', e)
                })
        }

    }

    const suspendAccountBtn = async () => {
        const body = {
            corporateId: accountData?._id,
            reason: Number(suspendData?.reason)
        }

        await ApiPost(`admin/corporate/suspend`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                setActivateAccount(true)
                setSuspendAccount(false)
                setSuspendData('')
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const reActivateAccount = async () => {
        const body = {
            id: accountData?._id,
            accountStatus: 1
        }
        await ApiPut(`admin/corporate`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                setActivateAccount(false)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const getSubscriptionData = async (id: any) => {
        await ApiGet(`admin/corporate_subscription/${id}`)
            .then((res) => {
                setSubscriptionData(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    // let rdt = [...Array(10).keys() as any].map( i => i+1)
    React.useEffect(() => {
        getCountry()
        vendorManagement[0].checked = false
        facilityManagement[0].checked = false
        itemarray=[]
    }, [])
    return (
        <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 justify-content-center'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/account-detail`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4  active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Account Details
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                accountData?._id ? navigate(`/corporate/payment-detail/${accountData?._id}`) : navigate(`/corporate/payment-detail`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Payments
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                accountData?._id &&
                                    navigate(`/corporate/user-detail/${accountData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Users
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                accountData?._id ? navigate(`/corporate/property-detail/${accountData?._id}`) :
                                    navigate('/corporate/property-detail')
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Propertise
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                accountData?._id ? navigate(`/corporate/tenancy-detail/${accountData?._id}`) :
                                    navigate('/corporate/tenancy-detail')
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Tenancies
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                accountData?._id ? navigate(`/corporate/tenant-detail/${accountData?._id}`) :
                                    navigate('/corporate/tenant-detail')
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Tenants
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                accountData?._id ? navigate(`/corporate/request/request/${accountData?._id}`) :
                                    navigate('/corporate/request/request')
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Requests
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {

                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }} >
                                Troubleshooting
                            </a>
                        </li>
                    </ul>


                </div>
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div id='' className='app-container container-xxl pt-0 mt-0'>
                            {/* <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3'>
                                <label>Account Id </label>
                                <span>{accountData?.id}</span>
                            </div> */}
                            <div className='mb-8 min-w-375px d-flex justify-content-between w-auto'>
                                <div className='d-flex align-items-center'>
                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                        <div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={() => navigate('/corporate')}>
                                            Back
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-8 min-w-375px d-flex justify-content-between w-auto'>
                                <div className='d-flex align-items-center'>
                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                        {account === 0 && <div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={() => saveAccountData(0)}>
                                            Save
                                        </div>}
                                        {account === 0 && <button
                                                className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                type='submit'
                                                onClick={() => saveAccountData(1)}
                                            >
                                                Generate Account
                                            </button>}
                                        {/* {account === 0 &&
                                            <button
                                                className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                type='submit'
                                                onClick={() => setAccountStatus(1)}
                                            >
                                                Generate Account
                                            </button>}
                                        {subscription !== true ? <div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={() => { setSubscription(true); setGenerateAccount('generateaccount') }}>
                                            Edit Subscription
                                        </div>
                                            :
                                            <div
                                                className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                onClick={() => renewSubscription()}>
                                                Submit Renewal
                                            </div>} */}
                                    </div>
                                </div>
                                {/* {activateAccount === false && <div className='d-flex'>
                                    <div className='mb-2 px-5 d-flex w-auto'>
                                        <button
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            type='button'
                                            onClick={() => setSuspendAccount(true)}
                                        >
                                            Suspend Account
                                        </button>

                                    </div>
                                </div>} */}
                                {/* {activateAccount === true && <div className='d-flex'>
                                    <div className='mb-2 px-5 d-flex w-auto'>
                                        <button
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            type='button'
                                            onClick={() => reActivateAccount()}
                                        >
                                            Reactivate Account
                                        </button>

                                    </div>
                                </div>} */}
                            </div>
                            {/* <div className='d-flex align-items-center justify-content-end gap-2 mb-5 gap-lg-3'>
                                <label> Account Suspension Date </label>
                                <span>{accountData && moment(accountData?.accountSuspensionDate).format('YYYY-MM-DD HH:mm:ss')} </span>
                            </div> */}
                            
                        </div>
                        {generateAccount === 'generateaccount' && <div className='card card-flush'>
                            <>

                                <Row className='py-2'>
                                    <Col lg={6}>
                                        <h3 className='mx-10 mb-10 mt-10'>Client Details </h3>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Company Name
                                                </h6>
                                                <input
                                                    type='text'
                                                    {...register("companyName", { required: true })}
                                                    className='form-control form-control-solid'
                                                    name='companyName'
                                                    onChange={handleChnage}

                                                />
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5 justify-content-between'>
                                                 <div className='d-flex w-100'>
                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <div>
                                                        <h6 className='text-muted m-0 me-2'>
                                                            Country
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <select
                                                            {...register("country", { required: true })}
                                                            name='country'
                                                            className='form-select form-select-solid'
                                                            onChange={handleCountryChnage}
                                                        >
                                                            <option value='' disabled selected>
                                                                Select country
                                                            </option>
                                                            {country?.map((v: any) => (
                                                                <option value={`${v._id}/${v.country}`}>{v.country}</option>
                                                            ))}
                                                        </select>
                                                        {errors.country && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                    </div>
                                                </div>
                                                <div className='w-50 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        City
                                                    </h6>
                                                        <select
                                                            {...register("city", { required: true })}
                                                            name='city'
                                                            className='form-select form-select-solid w-100'
                                                            value={accountDetail?.city}
                                                            onChange={handleChnage}
                                                        >
                                                            <option value='' disabled selected>
                                                                Select city
                                                            </option>
                                                            {city?.map((v: any) => (
                                                                <option value={v.name}>{v.city}</option>
                                                            ))}
                                                        </select>
                                                        {errors.city && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}

                                                </div>
                                                </div>
                                            </div>
                                            {errors.companyName && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Address
                                                </h6>
                                                <input
                                                    type='text'
                                                    {...register("address", { required: true })}
                                                    className='form-control form-control-solid'
                                                    name='address'
                                                    onChange={handleChnage}

                                                />
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Trade License No.
                                                </h6>
                                                <input
                                                    type='number'
                                                    {...register("tradeLicenseNo", { required: true })}
                                                    className='form-control form-control-solid'
                                                    placeholder=''
                                                    onChange={handleChnage}
                                                    name='tradeLicenseNo'
                                                />
                                                {errors.tradeLicenseNo && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                <button
                                                    className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-300px'>
                                                    Trade License Upload
                                                    <input
                                                        type='file'
                                                        {...register("contract", { required: true })}
                                                        className='form-control form-control-solid position-absolute '
                                                        placeholder=''
                                                        name='contract'
                                                        onChange={uploadFilelHandler}
                                                        // onChange={uploadFilelHandler}
                                                        style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                                    />
                                                </button>
                                                {errors.contract && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    TRN
                                                </h6>
                                                <input
                                                    type='number'
                                                    {...register("trn", { required: true })}
                                                    className='form-control form-control-solid'
                                                    name='trn'
                                                    onChange={handleChnage}
                                                />
                                            </div>
                                            {errors.trn && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            <div className='payment-receipt-item contract_number d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2 min-w-100px'>
                                                    Phone
                                                </h6>
                                                {/* <Controller
                                                control={control}
                                                
                                                {...register("phone", {
                                                    required: true, pattern: {
                                                        value:/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
                                                        message: "invalid email address"
                                                    }
                                                })}
                                                name="phone"
                                                render={({ field: { ref, ...field } }) => (
                                                    <ReactPhoneInput
                                                        // country={countryName}
                                                        placeholder='Enter phone number'
                                                        onChange={(phone, e) => handleNumber(phone, e)}
                                                    // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                    />
                                                )}
                                            /> */}
                                                <ReactPhoneInput
                                                    // country={countryName}
                                                    placeholder='Enter phone number'
                                                    onChange={(phone, e) => handleNumber(phone, e)}
                                                // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <h3 className='mx-10 mb-10 mt-10'> Main User Details  </h3>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item mb-5'>
                                                <div className='d-flex'>
                                                    <div className='w-50 pe-5 d-flex align-items-center '>
                                                        <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                            First Name
                                                        </h6>
                                                        <div>
                                                            <input
                                                                type='text'
                                                                {...register("firstname", { required: true })}
                                                                className='form-control form-control-solid'
                                                                name='firstname'
                                                                onChange={handleChnage}

                                                            />
                                                            {errors.firstname && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                        </div>
                                                    </div>
                                                    <div className='w-50 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                            Last Name
                                                        </h6>
                                                        <div>
                                                            <input
                                                                type='text'
                                                                {...register("lastName", { required: true })}
                                                                className='form-control form-control-solid'
                                                                name='lastName'
                                                                onChange={handleChnage}

                                                            />
                                                            {errors.lastName && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='payment-receipt-item mb-5'>
                                                <div className='d-flex'>
                                                    <div className='w-50 pe-5 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                            Email
                                                        </h6>
                                                        <input
                                                            type='text'
                                                            {...register("contractEmail", {
                                                                required: true, pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                    message: "invalid email address"
                                                                }
                                                            })}
                                                            className='form-control form-control-solid'
                                                            name='contractEmail'
                                                            onChange={handleChnage}
                                                        />
                                                        {errors.contractEmail && <p style={{ color: 'var(--kt-danger)' }}>invalid email address</p>}
                                                    </div>

                                                    <div className='w-50 pe-5 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 me-2 min-w-100px'>
                                                            Mobile No.
                                                        </h6>
                                                        {/* <Controller
                                                control={control}
                                                
                                                {...register("phone", {
                                                    required: true, pattern: {
                                                        value:/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
                                                        message: "invalid email address"
                                                    }
                                                })}
                                                name="phone"
                                                render={({ field: { ref, ...field } }) => (
                                                    <ReactPhoneInput
                                                        // country={countryName}
                                                        placeholder='Enter phone number'
                                                        onChange={(phone, e) => handleNumber(phone, e)}
                                                    // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                    />
                                                )}
                                            /> */}
                                                        <ReactPhoneInput
                                                            // country={countryName}
                                                            placeholder='Enter phone number'
                                                            onChange={(phone, e) => handleNumber2(phone, e)}
                                                            inputStyle={{ width: '180px' }}
                                                        // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col lg={10}>
                                        <h3 className='mx-10 mb-10 mt-10'>
                                            Subscription Details
                                        </h3>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <div className='pe-5 w-50 d-flex align-items-center justify-content-around'>
                                                    <h6 className='text-muted m-0 mr-2' style={{ width: '80%' }}>
                                                        Subscription Type
                                                    </h6>
                                                    <div style={{ marginLeft: '5px' }}>
                                                        <select
                                                            {...register("subscriptionType", { required: true })}
                                                            className='form-select form-select-solid'
                                                            name='subscriptionType'
                                                            onChange={handleChnage}

                                                        >
                                                            <option selected value=''>
                                                                Select
                                                            </option>
                                                            <option value={0}>
                                                                Free Demo
                                                            </option>
                                                            <option value={1}>
                                                                Paid Subscription
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* {accountDetail?.subscriptionType && <div className='pe-5 w-1 d-flex align-items-center justify-content-around'>
                                                    <div >
                                                        <input
                                                            type='number'
                                                            className='form-control form-control-solid'
                                                            name='days'
                                                            onChange={handleChnage}
                                                            min={0}
                                                        />
                                                    </div>
                                                    <div>
                                                        <h6 className='text-muted m-0'>
                                                            Months
                                                        </h6>
                                                    </div>
                                                </div>} */}
                                                
                                                <div className='w-50 d-flex align-items-center justify-content-around'>
                                                    <div>
                                                        <h6 className='text-muted m-0 mr-2'>
                                                            Start Date
                                                        </h6>
                                                    </div>
                                                    <div style={{ marginLeft: '5px' }}>
                                                        <input
                                                            type='date'
                                                            {...register("startDate", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='startDate'
                                                            onChange={handleChnage}

                                                        />
                                                    </div>
                                                </div>
                                                <div className=' w-50 d-flex align-items-center justify-content-around'>
                                                    <div>
                                                        <h6 className='text-muted m-0 mr-2'>
                                                            End Date
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type='date'
                                                            {...register("endDate", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='endDate'
                                                            onChange={handleChnage}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-30 d-flex align-items-center justify-content-around'>
                                                <div>
                                                        <h6 className='text-muted m-0 mr-2'>
                                                            Days
                                                        </h6>
                                                    </div>
                                                    <div >
                                                        <input
                                                            type='number'
                                                            className='form-control form-control-solid'
                                                            name='days'
                                                            onChange={handleChnage}
                                                            // min={0}
                                                            disabled
                                                            value={accountDetail?.totalDays}
                                                        />
                                                    </div>
                                                   
                                                </div>
                                                {/* <div className=' w-50 d-flex align-items-center justify-content-around'>
                                                    <div>
                                                        <h6 className='text-muted m-0 mr-2'>
                                                            End Date
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type='date'
                                                            className='form-control form-control-solid'
                                                            name='endDate'
                                                            value={accountDetail?.startDate && moment(accountDetail?.startDate).add(accountDetail?.days, 'months').subtract(1, 'days').format('YYYY-MM-DD')}
                                                            onChange={handleChnage}
                                                            min={moment(accountDetail?.startDate).format('YYYY-MM-DD')}
                                                        />
                                                    </div>
                                                </div> */}
                                            </div>

                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2' style={{ width: '20%' }}>
                                                    Account Features
                                                </h6>
                                                <div
                                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                    onClick={() => setShow(true)}>
                                                    Select
                                                </div>
                                                <h6 className='text-muted m-0 mr-2' style={{ width: '15%' }}>
                                                    Selected Features
                                                </h6>
                                                <h6 className='text-muted m-0 mr-2' >
                                                {(accountfeature.filter((e:any) => e.checked).length)+(service.filter((e:any) => e.checked).length)+(vendorManagement.filter((e:any) => e.checked).length)+(facilityManagement.filter((e:any) => e.checked).length)}/9
                                                </h6>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>



                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Total Properties
                                                </h6>
                                                <div>
                                                    <input
                                                        type='number'
                                                        {...register("totalPropertise", { required: true })}
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        onChange={handleChnage}
                                                        name='totalPropertise'
                                                    // onChange={uploadFilelHandler}
                                                    />
                                                    {errors.totalPropertise && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                </div>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px text-center'>
                                                    Total Common Areas
                                                </h6>
                                                <div>

                                                    <input
                                                        type='number'
                                                        {...register("totalCommonArea", { required: true })}
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        onChange={handleChnage}
                                                        name='totalCommonArea'
                                                    // onChange={uploadFilelHandler}
                                                    />
                                                    {errors.totalCommonArea && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                </div>
                                                <h6 className='text-muted m-0 mr-2 min-w-90px text-center'>
                                                    Total Users
                                                </h6>
                                                <div>

                                                    <input
                                                        type='number'
                                                        {...register("totalUser", { required: true })}
                                                        className='form-control form-control-solid'
                                                        placeholder=''
                                                        onChange={handleChnage}
                                                        name='totalUser'
                                                    // onChange={uploadFilelHandler}
                                                    />
                                                    {errors.totalUser && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}

                                                </div>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                    Rate per property
                                                </h6>
                                                <div style={{ width: '200px' }}>
                                                    <input
                                                        type='number'
                                                        {...register("ratePerProperty", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='ratePerProperty'
                                                        onChange={handleChnage}
                                                    />
                                                </div>
                                                <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                    AED
                                                </h6>

                                                <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                    Discount
                                                </h6>
                                                <div style={{ width: '200px' }}>
                                                    <input
                                                        type='number'
                                                        {...register("discount", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='discount'
                                                        onChange={handleChnage}
                                                        step="1"
                                                        onKeyDown={ (evt:any) => {console.log(evt) 
                                                            return evt.keyCode == 110}  }
                                                        max={100}
                                                    />
                                                </div>
                                                <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                    %
                                                </h6>
                                            </div>
                                            {errors.ratePerProperty && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2 min-w-150px' style={{ width: '40%' }}>
                                                    Monthly Subscription Amount:
                                                </h6>
                                                <input
                                                        type='number'
                                                        // {...register("discount", { required: true })}
                                                        className='form-control form-control-solid'
                                                        disabled
                                                        value={accountDetail?.totalPropertise && accountDetail?.discount && accountDetail?.discount!=0  ? (accountDetail?.totalPropertise * accountDetail?.ratePerProperty)-((accountDetail?.totalPropertise * accountDetail?.ratePerProperty)*(accountDetail?.discount))/100 : accountDetail?.totalPropertise && (!accountDetail?.discount || accountDetail?.discount==0) ? accountDetail?.totalPropertise * accountDetail?.ratePerProperty: 0}
                                                        // name='discount'
                                                        // onChange={handleChnage}
                                                        // max={100}
                                                    /><h6 className='text-muted m-0 mr-2 min-w-100px' >
                                                    AED
                                                </h6>

                                                {/* <span style={{ width: '22%', marginLeft: '20px' }}> AED {accountDetail?.totalPropertise && accountDetail?.discount && accountDetail?.discount!=0  ? (accountDetail?.totalPropertise * accountDetail?.ratePerProperty)-((accountDetail?.totalPropertise * accountDetail?.ratePerProperty)*(accountDetail?.discount))/100 : accountDetail?.totalPropertise && (!accountDetail?.discount || accountDetail?.discount==0) ? accountDetail?.totalPropertise * accountDetail?.ratePerProperty: 0} </span> */}
                                                <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                    Payment Status
                                                </h6>
                                                <select
                                                    {...register("paymentStatus", { required: true })}
                                                    className='form-select form-select-solid'
                                                    name='paymentStatus'
                                                    onChange={handleChnage}

                                                >
                                                    <option selected value=''>
                                                        Select
                                                    </option>
                                                    <option value={0}>
                                                        Not Paid
                                                    </option>
                                                    <option value={1}>
                                                        Partially Paid
                                                    </option>
                                                    <option value={2}>
                                                        Paid
                                                    </option>
                                                </select>
                                                {errors.paymentStatus && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>}
                                                <h6 className='text-muted m-0 mr-2 min-w-100px text-center' style={{ width: '80%' }} >
                                                Payment Cycle
                                                </h6>
                                                <select
                                                    // {...register("paymentStatus", { required: true })}
                                                    className='form-select form-select-solid'
                                                    name='paymentCycle'
                                                    onChange={handleChnage}

                                                >
                                                    <>
                                                    <option selected value=''>
                                                        Select 
                                                    </option>
                                                    <option value={1}>
                                                        Single
                                                    </option>
                                                    <option value={2}>
                                                        Monthly
                                                    </option>
                                                    {/* {accountDetail?.days && [...Array(parseInt(accountDetail?.days)).keys() as any].map( i => i+1)?.map((v: any, i: any) => <option value={v}>
                                                        {v}
                                                    </option>)} */}
                                                    </>
                                                    {/* <option value={1}>
                                                        Partially Paid
                                                    </option>
                                                    <option value={2}>
                                                        Paid
                                                    </option> */}
                                                </select>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                {/* <h6 className='text-muted m-0 mr-2' style={{ width: '20%' }}>
                                                    Account Features
                                                </h6> */}
                                                <div
                                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                    onClick={() => implementationcount()}
                                                    >
                                                    Add Other fess
                                                </div>
                                            </div>
                                            {itemarray.length>0 && <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <table className='w-100'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Item Number</th>
                                                            <th className='text-center'>Description</th>
                                                            <th className='text-center'>Quantity</th>
                                                            <th className='text-center'>Unit Price</th>
                                                            <th className='text-center'>Discount(%)</th>
                                                            <th className='text-center'>Amount(AED)</th>
                                                            <th className='text-center'>Action</th>

                                                        </tr>
                                                        {itemarray.length>0 && (itemarray as any)?.map((v: any, i: any) => 
                                            <><tr>
                                                <td className='text-center'><h6 className='text-muted m-0 mr-2'>
                                                            {i+1}
                                                        </h6></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                        <input
                                                            type='text'
                                                            // {...register("description", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='description'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.description}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("quantity", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='qty'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.qty}
                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='unitPrice'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.unitPrice}

                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='discountInPercentage'
                                                            onChange={(e:any)=>{
                                                                if(e.target.value>100){
ErrorToast("Discount is not more than 100")
                                                                }else{
                                                                    handleChnage1(e,i)
                                                                }
                                                                }}
                                                            value={v.discountInPercentage}
                                                            // step="1"
                                                            // onKeyDown={ (evt:any) => {console.log(evt) 
                                                            //     return evt.keyCode == 110}  }
                                                            // max={100}
                                                            // min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='taxableAmount'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.taxableAmount}
                                                            // value={v.qty && v.unitPrice && (v.discountInPercentage!==0 || v.discountInPercentage) ? (v.qty*v.unitPrice)-(((v.qty*v.unitPrice)*v.discountInPercentage)/100) :v.qty && (!v.unitPrice || v.discountInPercentage==0 || v.discountInPercentage==NaN as any) ? v.qty*v.unitPrice :0}
                                                            disabled
                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td className='text-center'>
                                                {/* <div
                                                    className='btn  fw-bold me-5 btn-primary btn-green hover-none'
                                                    
                                                    > */}
                                                    {/* <span className="svg-icon svg-icon-md svg-icon-danger">  */}
                                                    <SVG src='\media\icons\duotune\arrows\Trash.svg' color='#007a59' onClick={() => implementationdelete(i)}/>
                                                    {/* </span> */}
                                                {/* </div> */}
                                                </td>
                                                </tr></>)}
                                                        
                                                    </thead>
                                                   

                                                </table>
                                                </div>}
                                               
                                           
                                           
                                        </div>
                                    </Col>

                                </Row>

                                {/* <h3 className='mx-10 mb-10 mt-10'>
                                    Subscription History
                                </h3>
                                <div className='card-body pt-0 table-responsive mt-5'>
                                    <table
                                        className='table align-middle table-row-dashed fs-6 gy-5'
                                        id='kt_ecommerce_sales_table'
                                    >
                                        <thead>
                                            <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0'>
                                                <th className='text-center min-w-10px'></th>
                                                <th className='min-w-100px'> Subscription Type   </th>
                                                <th className='min-w-100px'>Start Date</th>
                                                <th className='min-w-70px'>End Date</th>
                                                <th className='min-w-70px'>Total Properties</th>
                                                <th className='min-w-70px'>Total Common Areas</th>
                                                <th className='min-w-70px'>Total Users </th>
                                                <th className='min-w-70px'>Rate per Property</th>
                                                <th className='min-w-70px'>Subscription Amount</th>
                                                <th className='min-w-100px'>Payment Status </th>
                                                <th className='min-w-100px'></th>

                                            </tr>
                                        </thead>
                                        <tbody className='fw-semibold text-gray-600'>
                                            {tableData?.map((v: any, i: any) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                            </div>
                                                        </td>
                                                        <td>{subscriptionData && subscriptionData?.subscriptionType === 0 ? 'Free Demo' : subscriptionData?.subscriptionType === 1 ? 'Paid Subscription' : ''}</td>
                                                        <td>{subscriptionData && moment(subscriptionData?.startDate).format('YYYY-MM-DD')}</td>
                                                        <td>{subscriptionData && moment(subscriptionData?.endDate).format('YYYY-MM-DD')}</td>
                                                        <td>{subscriptionData && subscriptionData?.totalPropertise}</td>
                                                        <td>{subscriptionData && subscriptionData?.totalCommonAreas}</td>
                                                        <td>{subscriptionData && subscriptionData?.totalUsers}</td>
                                                        <td>{subscriptionData && subscriptionData?.ratePerProperty}</td>
                                                        <td>{subscriptionData && subscriptionData?.ratePerProperty * subscriptionData?.totalPropertise}</td>
                                                        <td>{subscriptionData && subscriptionData?.paymentStatus === 0 ? 'Not Paid'
                                                            : subscriptionData?.paymentStatus === 1 ? 'Partial Paid' : subscriptionData?.paymentStatus === 2 ? 'Paid' : ''}</td>
                                                        <td className='text-center'>
                                                            <div className='menu-item px-3'>
                                                                <div
                                                                    className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                                    onClick={() => setGenerateAccount('generateaccount')}
                                                                >
                                                                    View
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div> */}
                            </>
                        </div>}
                        {generateAccount === 'editsubscription' && <div className='card card-flush'>
                            <>
                                <Row className='py-2'>
                                    <Col lg={6}>
                                        <h3 className='mx-10 mb-10 mt-10'>Client Details </h3>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    Company Name
                                                </h6>
                                                <b>{accountData && accountData?.companyName}</b>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <div className='pe-5 w-50 d-flex align-items-center'>
                                                    <div>
                                                        <h6 className='text-muted m-0 me-2'>
                                                            Country
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <b>{accountData && accountData?.country}</b>
                                                    </div>
                                                </div>
                                                <div className='w-50 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        City
                                                    </h6>
                                                    <div>
                                                        <b>{accountData && accountData?.city}</b>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    Address
                                                </h6>
                                                <b>{accountData && accountData.address}</b>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    Trade License No.
                                                </h6>
                                                <b>{accountData && accountData.tradeLicense}</b>

                                                <a
                                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                    href={`${Bucket}${accountData?.tradeLicenseURL}`} target="_blank">
                                                    View Trade License
                                                </a>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    TRN
                                                </h6>
                                                <b>{accountData && accountData.TRN}</b>
                                            </div>
                                            <div className='payment-receipt-item contract_number d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    Phone
                                                </h6>

                                                <b>+{accountData && accountData.countryCode} {accountData && accountData.phoneNumber
                                                }
                                                </b>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <h3 className='mx-10 mb-10 mt-10'> Main User Details  </h3>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item mb-5'>
                                                <div className='d-flex'>
                                                    <div className='w-50 pe-5 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 me-2'>
                                                            First Name
                                                        </h6>
                                                        <b>{accountData && accountData.firstName}</b>
                                                    </div>
                                                    <div className='w-50 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                            Last Name
                                                        </h6>
                                                        <b>{accountData && accountData.lastName
                                                        }</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='payment-receipt-item mb-5'>
                                                <div className='d-flex'>
                                                    <div className='w-50 pe-5 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 me-2'>
                                                            Email
                                                        </h6>
                                                        <b>{accountData && accountData.email
                                                        }</b>
                                                    </div>

                                                    <div className='w-50 pe-5 d-flex align-items-center'>
                                                        <h6 className='text-muted m-0 me-2'>
                                                            Mobile No.
                                                        </h6>
                                                        <b>+{accountData && accountData.accountOwnerCountryCode} {accountData && accountData.accountOwnerMobile
                                                        }</b>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Col lg={10}>
                                        <h3 className='mx-10 mb-10 mt-10'>
                                            Subscription Details
                                        </h3>
                                        <div className='min-w-200px mx-10'>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <div className='pe-5 w-50 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Subscription Type
                                                    </h6>
                                                    <div style={{ marginLeft: '5px' }}>
                                                        <b>{accountData && accountData?.corporate_subscription[0] === 0 ? 'Free Demo' : 'Paid Subscription'}</b>
                                                    </div>
                                                </div>
                                                <div className='w-50 d-flex align-items-center'>
                                                    <div>
                                                        <h6 className='text-muted m-0 me-2'>
                                                            Start Date
                                                        </h6>
                                                    </div>
                                                    <div style={{ marginLeft: '5px' }}>
                                                        <b>{moment(accountData && accountData?.corporate_subscription[0].startDate).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                </div>
                                                <div className=' w-50 d-flex align-items-center'>
                                                    <div>
                                                        <h6 className='text-muted m-0 mr-2'>
                                                            End Date
                                                        </h6>
                                                    </div>
                                                    <div>
                                                        <b>{moment(accountData && accountData?.corporate_subscription[0].endDate).format('DD/MM/YYYY')}</b>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 mr-2' style={{ width: '20%' }}>
                                                    Account Features
                                                </h6>
                                                <div
                                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                    onClick={() => setShow(true)}>
                                                    Select
                                                </div>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2' style={{ paddingLeft: '10' }}>
                                                    Total Properties:
                                                </h6>
                                                <b>{accountData && accountData?.corporate_subscription[0].totalPropertise}</b>

                                                <h6 className='text-muted m-0 me-2' style={{ paddingLeft: '10' }}>
                                                    Total Common Areas:
                                                </h6>
                                                <b>{accountData && accountData?.corporate_subscription[0].totalCommonAreas}</b>
                                                <h6 className='text-muted m-0 me-2' style={{ paddingLeft: '10' }}>
                                                    Total Users:
                                                </h6>
                                                <b>{accountData && accountData?.corporate_subscription[0].totalUsers
                                                }</b>
                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2' style={{ paddingLeft: '10' }}>
                                                    Rate per property :
                                                </h6>
                                                <b>{accountData && accountData?.corporate_subscription[0].ratePerProperty}AED</b>


                                            </div>
                                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <h6 className='text-muted m-0 me-2' style={{ paddingLeft: '10' }}>
                                                   Monthly Subscription Amount:
                                                </h6>
                                                <span style={{ width: '22%', marginLeft: '20px' }}> AED <b>{accountDetail?.totalAmount}</b> </span>
                                                <h6 className='text-muted m-0 me-2' style={{ paddingLeft: '10' }}>
                                                    Payment Status:
                                                </h6>
                                                <b>{accountData?.corporate_subscription[0].paymentStatus === 0 ? 'Not Paid'
                                                    : accountData?.corporate_subscription[0] === '1' ? 'Partial Paid' : 'Paid'}</b>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                                {/* <h3 className='mx-10 mb-10 mt-10'>
                                    Subscription History
                                </h3>
                                <div className='card-body pt-0 table-responsive mt-5'>
                                    <table
                                        className='table align-middle table-row-dashed fs-6 gy-5'
                                        id='kt_ecommerce_sales_table'
                                    >
                                        <thead>
                                            <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0'>
                                                <th className='text-center min-w-10px'></th>
                                                <th className='min-w-100px'> Subscription Type   </th>
                                                <th className='min-w-100px'>Start Date</th>
                                                <th className='min-w-70px'>End Date</th>
                                                <th className='min-w-70px'>Total Properties</th>
                                                <th className='min-w-70px'>Total Common Areas</th>
                                                <th className='min-w-70px'>Total Users </th>
                                                <th className='min-w-70px'>Rate per Property</th>
                                                <th className='min-w-70px'>Subscription Amount</th>
                                                <th className='min-w-100px'>Payment Status </th>
                                                <th className='min-w-100px'></th>
                                                <th className='min-w-100px'></th>


                                            </tr>
                                        </thead>
                                        <tbody className='fw-semibold text-gray-600'>
                                            {tableData?.map((v: any, i: any) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                            </div>
                                                        </td>
                                                        <td>{subscriptionData && subscriptionData?.subscriptionType === 0 ? 'Free Demo' : subscriptionData?.subscriptionType === 1 ? 'Paid Subscription' : ''}</td>
                                                        <td>{subscriptionData && moment(subscriptionData?.startDate).format('YYYY-MM-DD')}</td>
                                                        <td>{subscriptionData && moment(subscriptionData?.endDate).format('YYYY-MM-DD')}</td>
                                                        <td>{subscriptionData && subscriptionData?.totalPropertise}</td>
                                                        <td>{subscriptionData && subscriptionData?.totalCommonAreas}</td>
                                                        <td>{subscriptionData && subscriptionData?.totalUsers}</td>
                                                        <td>{subscriptionData && subscriptionData?.ratePerProperty}</td>
                                                        <td>{subscriptionData && subscriptionData?.ratePerProperty * subscriptionData?.totalPropertise}</td>
                                                        <td>{subscriptionData && subscriptionData?.paymentStatus === 0 ? 'Not Paid'
                                                            : subscriptionData?.paymentStatus === 1 ? 'Partial Paid' : 'Paid'}</td>
                                                        <td className='text-center'>
                                                            <div className='menu-item px-3'>
                                                                <div
                                                                    className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                                    onClick={() => setGenerateAccount('generateaccount')}
                                                                >
                                                                    View
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div> */}
                            </>
                        </div>}
                    </form>
                </div >
            </div>
            <AccountFeaturePopup
                show={show}
                handleClose={() => {
                    setShow(false)
                    Setpermissionflag(false)

                }}
                permissionflag={permissionflag}

                accountFeature={accountFeature}
                CheckAll={CheckAll}
                accountfeature={accountfeature}
                checkAll={checkAll}
                setVendor={setVendor}
                vendor={vendor}
                venderAll={venderAll}
                vendorext={vendorext}
                facilityAll={facilityAll}
                facilityext={facilityext}
                setFacility={setFacility}
                facility={facility}
                setService={setService}
                service={service}
                selectServices={selectServices}
                allService={allService}
                selectAllService={selectAllService}
            />
          
            <SuspendAccount show={suspendAccount}
                handleClose={() => setSuspendAccount(false)}
                suspendAccountBtn={suspendAccountBtn}
                accountId={accountData?._id}
                setSuspendData={setSuspendData}
                suspendData={suspendData}
            />
        </div>
    )
}

export default AccountDetail