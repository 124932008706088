import React, { useEffect, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ApiGet, ApiGetNoAuth, ApiPost, ApiPut, ApiUpload, Bucket } from '../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast'
import ReactPhoneInput from 'react-phone-input-2'
import AccountFeaturePopup from './AccountFeaturePopup'
import SuspendAccount from './SuspendAccount'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import { AiFillEdit, AiOutlineCheck } from 'react-icons/ai'


let propertyManagement:any = [
    // { title: "Property Registration", name: 'property', checked: false },
    // { title: "Tenancy Management ", name: 'tenancy', checked: false },
    // { title: "Tenant Management ", name: 'tenant', checked: false },
    // { title: "Rent Management", name: 'rent', checked: false },
    // { title: "Announcement Management", name: 'announcement', checked: false },
]

let serviceManagement:any = [
    // { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
    // { title: 'External Service Requests', name: 'externalServiceRequest', checked: false }
]

let vendorManagement:any = [
    // { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
]
// let facilityManagement:any = [
//     // { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
// ]

let itemarray:any = []
let facilityManagement:any = []
let renewitemarray:any = []


const ViewAccountDetail = () => {
    
    const navigate = useNavigate()
    const [accountDetail, setAccountDetail] = React.useState<any>()
    const [accountDetailData, setAccountDetailData] = React.useState<any>()
    const [show, setShow] = React.useState<any>(false)
    const [approvalStatus, setApprovalStatus] = React.useState<any>()
    const [uploadedFile, setUploadedFile] = React.useState<any>()
    const [countryName, setCountryName] = React.useState<any>("")
    const [checkAll, setCheckAll] = React.useState<any>()
    const [city, setCity] = React.useState([])
    const [country, setCountry] = React.useState([])
    const [tableData, setTableData] = React?.useState<any>()
    const [accountfeature, setAccountFeature] = React?.useState<any>(propertyManagement)
    const [facilityfeature, setfacilityFeature] = React?.useState<any>(facilityManagement)
    const [vendorfeature, setvendorFeature] = React?.useState<any>(vendorManagement)
    const [suspendAccount, setSuspendAccount] = React.useState<any>(false)
    const [vendor, setVendor] = React.useState<any>({ 'vendorList': false })
    const [facility, setFacility] = React?.useState<any>({ 'service': false })
    const [service, setService] = React.useState<any>(serviceManagement)
    const [allService, setAllService] = React.useState<any>()
    const [phoneNumber, setPhoneNumber] = React.useState<any>()
    const [phoneNumber1, setPhoneNumber1] = React.useState<any>()
    const [venderAll, setvendorAll] = React.useState<any>()
    const [facilityAll, setfacilityAll] = React.useState<any>()
    const [accountStatus, setAccountStatus] = React.useState<any>()
    const [generateAccount, setGenerateAccount] = React.useState<any>('editsubscription')
    const [accountData, setAccountData] = React.useState<any>()
    const [accountId, setAccountId] = React.useState<any>()
    const corporateId = window?.location?.pathname?.split('/')[3];
    const [suspendData, setSuspendData] = React.useState<any>()
    const [activateAccount, setActivateAccount] = React.useState<any>(false)
    const [subscriptionData, setSubscriptionData] = React.useState<any>()
    const [renewsubData, setrenewsubData] = React.useState<any>()
    const [permissionflag, Setpermissionflag] = React.useState<any>(false)
    const [count, setcount] = React.useState<any>(0)
    const [renewflag, setrenewflag] = React.useState<any>()
    const [IsEdit, setIsEdit] = React.useState<any>(false)

    

    

    
    const [submitRenewal, setSubmitRenewal] = React.useState<any>(false)
    const [subscription, setSubscription] = React.useState<any>(false)

  
    const { register, handleSubmit, watch, control, reset, formState: { errors } } = useForm<any>();

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setAccountDetail({ ...accountDetail, [name]: value })
    }
    const updatedate = async() =>{
        const body = {
            id: accountDetailData?._id,
            accountSuspensionDate: accountDetailData?.accountSuspensionDate,
            
        }
        await ApiPut(`admin/corporate`, body)
            .then((res) => {
                // SuccessToast(res?.data?.message)
                // setGenerateAccount('editsubscription')
                // setAccountId(res?.data?.data?.corporateData?._id)
                viewAccountDetail()
                setIsEdit(false) 
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const handleChnagedate = (e:any) =>{
        const { name, value } = e.target

        let diffDays:any
            if((name === 'startDate'&&subscriptionData?.endDate&&value) ){
                
                const oneDay = 24 * 60 * 60 * 1000
                const firstDate:any = new Date(value);
                const secondDate:any = new Date(subscriptionData?.endDate);

 diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
// setAccountDetail({ ...subscriptionData, 'totalDays': 1 })
console.log(value,"dfsdfsfsdfsdf",firstDate,secondDate,diffDays)
            }else if((name === 'endDate'&&subscriptionData?.startDate&&value) ){
                // setAccountDetail({ ...subscriptionData, 'totalDays': 1 })
                const oneDay = 24 * 60 * 60 * 1000
                const firstDate:any = new Date(subscriptionData?.startDate);
                const secondDate:any = new Date(value);

 diffDays= Math.round(Math.abs((firstDate - secondDate) / oneDay));
console.log(value,"dfsdfsfsdfsdf",firstDate,secondDate,diffDays)

            }
            setSubscriptionData({...subscriptionData, [name]: value, 'totalDays': diffDays+1 })
    }
    const handleChnagedaterenew = (e:any) =>{
        const { name, value } = e.target

        let diffDays:any
            if((name === 'startDate'&&renewsubData?.endDate&&value) ){
                
                const oneDay = 24 * 60 * 60 * 1000
                const firstDate:any = new Date(value);
                const secondDate:any = new Date(renewsubData?.endDate);

 diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
// setAccountDetail({ ...renewsubData, 'totalDays': 1 })
console.log(value,"dfsdfsfsdfsdf",firstDate,secondDate,diffDays)
            }else if((name === 'endDate'&&renewsubData?.startDate&&value) ){
                // setAccountDetail({ ...renewsubData, 'totalDays': 1 })
                const oneDay = 24 * 60 * 60 * 1000
                const firstDate:any = new Date(renewsubData?.startDate);
                const secondDate:any = new Date(value);

 diffDays= Math.round(Math.abs((firstDate - secondDate) / oneDay));
console.log(value,"dfsdfsfsdfsdf",firstDate,secondDate,diffDays)

            }
            setrenewsubData({...renewsubData, [name]: value, 'totalDays': diffDays+1 })
    }

    const implementationcount = () =>{
        console.log("dfsdfsdf")
        itemarray.push({
            description:"",
            qty:0,
            unitPrice:0,
            discountInPercentage:0,
            monthDuration:0,
            discountAmount:0,
            taxableAmount:0,
            isEditable:true

        })
        setcount(count+1)
    }
    const renewimplementationcount = () =>{
        console.log("dfsdfsdf")
        renewitemarray.push({
            description:"",
            qty:0,
            unitPrice:0,
            discountInPercentage:0,
            monthDuration:0,
            discountAmount:0,
            taxableAmount:0,
            isEditable:true

        })
        setcount(count+1)
    }
    const implementationdelete = (i:any) =>{
        console.log("dfsdfsdf")
        itemarray.splice(i, 1);
        setcount(count+1)
    }
    const renewimplementationdelete = (i:any) =>{
        console.log("dfsdfsdf")
        renewitemarray.splice(i, 1);
        setcount(count+1)
    }
    const handleChnage1 = (e: any,i:any) => {
        console.log(e)
        const { name, value } = e.target
        if(name=="qty" || name=="unitPrice" ){
            itemarray[i][name] = parseInt(value)

        }else{
            itemarray[i][name] = value

        }
        console.log(itemarray)
        if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && value)){
            console.log("jayraj")
            itemarray[i]["discountAmount"] = (itemarray[i].qty*itemarray[i].unitPrice)*itemarray[i].discountInPercentage/100
            itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
        }else if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && !value)){
            console.log("jayraj1")
            itemarray[i]["discountAmount"] = 0
            itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
            // itemarray[i]["discountAmount"] = (itemarray[i].qty*itemarray[i].unitPrice)*itemarray[i].discountInPercentage/100
            // itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
        }
        else{
            console.log("jayraj2")
            if(itemarray[i].discountInPercentage){
                itemarray[i]["discountAmount"] = (itemarray[i].qty*itemarray[i].unitPrice)*itemarray[i].discountInPercentage/100
                itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
            }else{
                itemarray[i]["discountAmount"] = 0
                itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
            }
            
        }
        // if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && value)){
        //     itemarray[i]["discountAmount"] = (itemarray[i].qty*itemarray[i].unitPrice)*itemarray[i].discountInPercentage/100
        //     itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
        // }else{
        //     itemarray[i]["discountAmount"] = 0
        //     itemarray[i]["taxableAmount"] = (itemarray[i].qty*itemarray[i].unitPrice) - itemarray[i].discountAmount
        // }

        setcount(count+1)
    
        // setItem({ ...item, [name]: value })
    }
    const renewhandleChnage1 = (e: any,i:any) => {
        console.log(e)
        const { name, value } = e.target
        if(name=="qty" || name=="unitPrice" ){
            renewitemarray[i][name] = parseInt(value)

        }else{
            renewitemarray[i][name] = value

        }
        console.log(renewitemarray)
        if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && value)){
            console.log("jayraj")
            renewitemarray[i]["discountAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice)*renewitemarray[i].discountInPercentage/100
            renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
        }else if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && !value)){
            console.log("jayraj1")
            renewitemarray[i]["discountAmount"] = 0
            renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
            // renewitemarray[i]["discountAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice)*renewitemarray[i].discountInPercentage/100
            // renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
        }
        else{
            if(renewitemarray[i].discountInPercentage){
                renewitemarray[i]["discountAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice)*renewitemarray[i].discountInPercentage/100
                renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
            }else{
                renewitemarray[i]["discountAmount"] = 0
                renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
            }
            // console.log("jayraj2")
            // renewitemarray[i]["discountAmount"] = 0
            // renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
        }
        // if((name=="qty" || name=="unitPrice" || name=="discountInPercentage") && (name=="discountInPercentage" && value)){
        //     renewitemarray[i]["discountAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice)*renewitemarray[i].discountInPercentage/100
        //     renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
        // }else{
        //     renewitemarray[i]["discountAmount"] = 0
        //     renewitemarray[i]["taxableAmount"] = (renewitemarray[i].qty*renewitemarray[i].unitPrice) - renewitemarray[i].discountAmount
        // }

        setcount(count+1)
    
        // setItem({ ...item, [name]: value })
    }
    const getCountry = async () => {
        await ApiGetNoAuth('country')
            .then((res) => {
                setCountry(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    React.useEffect(() => {
        getCountry()
    }, [])

    console.log('sasdas',phoneNumber)
    const uploadFilelHandler = async (e: any) => {
        let file = e.target.files[0]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
        await ApiUpload('upload/image/document', formData)
            .then((res: any) => setUploadedFile(res?.data?.data?.image))
            .catch((err: any) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
            })
    }
    const handleCountryChnage = async (e: any) => {
        const { name, value } = e.target
        const data = value.split('/')
        setCountryName(data[1]);
        await ApiGet(`country/cities?countryId=${data[0]}`)
            .then((res) => {
                setCity(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const handleNumber = (Phone: any, e: any) => {
        let CountryCode = e.dialCode
        let PhoneNumber = Phone.split(CountryCode)[1]

        setPhoneNumber({
            ...phoneNumber,
            ['countryCode']: parseInt(CountryCode),
            ['phoneNumber']: PhoneNumber,
        })
    }

    const handleNumber2 = (Phone: any, e: any) => {
        let CountryCode = e.dialCode
        let PhoneNumber = Phone.split(CountryCode)[1]

        setPhoneNumber1({
            ...phoneNumber1,
            ['countryCode']: parseInt(CountryCode),
            ['phoneNumber']: PhoneNumber,
        })
    }

    const accountFeature = (index: any, e: any) => {
        if (checkAll) {
            if (!e.target.checked) {
                setCheckAll(e.target.checked)
            }
        }
        var total: any = 0;
        accountfeature?.map((topping: any, currentIndex: any) =>
            topping.checked ? total = total + 1 : total,
        )
        if (total === accountfeature?.length - 1 && e.target.checked) {
            total = 0
            setCheckAll(!checkAll)
        }
        setAccountFeature(
            accountfeature?.map((topping: any, currentIndex: any) =>
                currentIndex === index ? { ...topping, checked: !topping.checked } : topping

            )
        )
    }
    const CheckAll = (e: any) => {
        setCheckAll(e.target.checked)
        setAccountFeature(
            (accountfeature.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }

    const selectAllService = (e: any) => {
        setAllService(e.target.checked)
        setService(
            (service.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }

    const selectServices = (index: any, e: any) => {
        if (allService) {
            if (!e.target.checked) {
                setAllService(e.target.checked)
            }
        }
        var total: any = 0;
        service?.map((topping: any, currentIndex: any) =>
            topping.checked ? total = total + 1 : total,
        )
        if (total === service?.length - 1 && e.target.checked) {
            total = 0
            setAllService(!allService)
        }
        setService(
            service?.map((topping: any, currentIndex: any) =>
                currentIndex === index ? { ...topping, checked: !topping.checked } : topping

            )
        )
    }

    const callcity = async(value:any) =>{
        // const data = value.split('/')
        await ApiGet(`country/cities?countryName=${value}`)
            .then((res) => {
                setCity(res?.data?.data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const GenerateAccount = async () => {
        // setGenerateAccount('generateaccount')
        // if (generateAccount === 'generateaccount') {
            const body = {
                id: accountDetailData?._id,
                accountStatus: 1,
                
            }
            await ApiPut(`admin/corporate`, body)
                .then((res) => {
                    SuccessToast(res?.data?.message)
                    setGenerateAccount('editsubscription')
                    setAccountId(res?.data?.data?.corporateData?._id)
                    viewAccountDetail()
                })
                .catch((e) => {
                    console.log('e', e)
                })
        // }
    }
    const inFuture = (date: any,toleranceMillis:any = 0) => {
        console.log(date)
        let addd = new Date(date)
        return addd.getTime() > Date.now() + toleranceMillis
    };
    const editSubscription = async () => {
        setGenerateAccount('generateaccount')
        if (generateAccount === 'generateaccount') {
            // const body = {
            //     id: accountDetailData?.corporate_subscription[0]?._id,
            //     remainingDays: subscriptionData?.remainingDays,
            //     totalAmount: Number(subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty),
            //     paymentStatus: Number(subscriptionData?.paymentStatus),
            //     totalPropertise: Number(subscriptionData?.totalPropertise),
            //     totalCommonAreas: Number(subscriptionData?.totalCommonAreas),
            //     totalUsers: Number(subscriptionData?.totalUsers),
            //     ratePerProperty: Number(subscriptionData?.ratePerProperty),
            //     subscriptionType: Number(subscriptionData?.subscriptionType),
            //     startDate: subscriptionData?.startDate,
            //     endDate: subscriptionData?.endDate
            // }
            const body = {
                id: accountDetailData?._id,
                firstName: accountDetailData?.firstName ? accountDetailData?.firstName : '',
                lastName: accountDetailData?.lastName ? accountDetailData?.lastName : '',
                country: accountDetailData?.country ? accountDetailData?.country : '',
                address: accountDetailData?.address,
                phoneNumber: phoneNumber ? phoneNumber?.phoneNumber.toString() : accountDetailData?.phoneNumber,
                email: accountDetailData?.email ? accountDetailData?.email : '',
                companyName: accountDetailData?.companyName ? accountDetailData?.companyName : '',
                accountOwnerCountryCode: phoneNumber1 ? phoneNumber1?.countryCode.toString() : accountDetailData?.accountOwnerCountryCode,
                accountOwnerMobile: phoneNumber1 ? phoneNumber1?.phoneNumber.toString() : accountDetailData?.accountOwnerMobile,
                tradeLicense: accountDetailData?.tradeLicense ? accountDetailData?.tradeLicense : '',
                tradeLicenseURL: uploadedFile,
                TRN: accountDetailData?.TRN,
                accountStatus: accountDetailData?.accountStatus ? accountDetailData?.accountStatus:0,
                city: accountDetailData?.city,
                countryCode: phoneNumber ? phoneNumber?.countryCode.toString() : accountDetailData?.countryCode,
                accountSuspensionDate: accountDetailData?.accountSuspensionDate ? accountDetailData?.accountSuspensionDate : '',
                // 
            }
            const body1 = {
                        id:subscriptionData?._id,
                        remainingDays: Number(subscriptionData?.totalDays) ? Number(subscriptionData?.totalDays) : 0,
                        totalDays: Number(subscriptionData?.totalDays) ? Number(subscriptionData?.totalDays) : 0,
                        paymentCycle:Number(subscriptionData?.paymentCycle) ? Number(subscriptionData?.paymentCycle):0,
                        subTotal: Number(subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty) ? Number(subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty) : 0,
                        discountInPercentage: Number(subscriptionData?.discountInPercentage)?Number(subscriptionData?.discountInPercentage):0,
                        discountAmount: Number(subscriptionData?.totalPropertise) && Number(subscriptionData?.discountInPercentage) && Number(subscriptionData?.discountInPercentage)!=0  ? ((subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty)*(subscriptionData?.discountInPercentage))/100 :  0,
                        totalAmount: subscriptionData?.totalPropertise && subscriptionData?.discountInPercentage && subscriptionData?.discountInPercentage!=0  ? (subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty)-((subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty)*(subscriptionData?.discountInPercentage))/100 : subscriptionData?.totalPropertise && (!subscriptionData?.discountInPercentage || subscriptionData?.discountInPercentage==0) ? subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty: 0,
                        paymentStatus: Number(subscriptionData?.paymentStatus) ? Number(subscriptionData?.paymentStatus) : 0,
                        totalPropertise: Number(subscriptionData?.totalPropertise) ? Number(subscriptionData?.totalPropertise) : 0,
                        totalCommonAreas: Number(subscriptionData?.totalCommonAreas) ? Number(subscriptionData?.totalCommonAreas) : 0,
                        totalUsers: Number(subscriptionData?.totalUsers) ? Number(subscriptionData?.totalUsers) : 0,
                        ratePerProperty: Number(subscriptionData?.ratePerProperty) ? Number(subscriptionData?.ratePerProperty) : 0,
                        subscriptionType: Number(subscriptionData?.subscriptionType) ? Number(subscriptionData?.subscriptionType) : 0,
                        startDate: subscriptionData?.startDate ? subscriptionData?.startDate : null,
                        endDate:  subscriptionData?.endDate ? subscriptionData?.endDate : null,
                        items:subscriptionData?.items,
                        status:inFuture(subscriptionData?.startDate)?2:1,
                        accountFeature: {
                        numberOfSelected: (accountfeature.filter((e:any) => e.checked).length)+(service.filter((e:any) => e.checked).length)+(vendorfeature.filter((e:any) => e.checked).length)+(facilityfeature.filter((e:any) => e.checked).length),
                        numberOfTotal: 9,
                        property: accountfeature[0]?.checked,
                        tenancy: accountfeature[1]?.checked,
                        tenant: accountfeature[2]?.checked,
                        rent: accountfeature[3]?.checked,
                        announcement: accountfeature[4]?.checked,
                        vendorList: vendorfeature[0]?.checked,
                        service: facilityfeature[0]?.checked,
                        internalServiceRequest: service[0]?.checked,
                        externalServiceRequest: service[1]?.checked,
                        
                },
                corporateId:accountDetailData?._id,
                    // accountPermission: {
                    //     property: permissionData?.property,
                    //     tenancy: permissionData?.tenancy,
                    //     tenant: permissionData?.tenant,
                    //     rent: permissionData?.rent,
                    //     announcement: permissionData?.announcement,
                    //     vendorList: permissionData?.vendorList,
                    //     service: permissionData?.service,
                    //     internalServiceRequest: permissionData?.internalServiceRequest,
                    //     externalServiceRequest: permissionData?.externalServiceRequest
                    // }
            }
            console.log(body,body1)
            await ApiPut(`admin/corporate`, body)
                .then((res) => {
                    ApiPut(`admin/corporate_subscription`, body1)
                .then((res) => {
                    SuccessToast(res?.data?.message)
                    setGenerateAccount('editsubscription')
                    viewAccountDetail()
                    setSubscription(false)
                    setrenewflag(false) 
                })
                .catch((e) => {
                    console.log('e', e)
                })
                    // SuccessToast(res?.data?.message)
                    // setGenerateAccount('editsubscription')
                    // viewAccountDetail()
                    // setSubscription(false)
                })
                .catch((e) => {
                    console.log('e', e)
                })
                
        }
    }
    const vendorext = (e: any) => {
        console.log('Check',e.target.checked)
        setvendorAll(e.target.checked)
        // if(e.target.checked){
            let data = vendorfeature
            console.log('data',data)
            data[0].checked = e.target.checked
            setvendorFeature(
                data
            )
            // vendorManagement[0].checked = e.target.checked
        // }
        // setCheckAll(e.target.checked)
        // setAccountFeature(
        //     (accountfeature.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }
    const facilityext = (e: any) => {
        console.log('Check',e.target.checked)
        setfacilityAll(e.target.checked)
        // if(e.target.checked){
            let data = facilityfeature
            console.log('data',data)

            data[0].checked = e.target.checked
            setfacilityFeature(data)
        // }
        // setCheckAll(e.target.checked)
        // setAccountFeature(
        //     (accountfeature.map((topping: any) => ({ ...topping, checked: e.target.checked }))))
    }
    const renewSubscription = async () => {
        // setGenerateAccount('generateaccount')
        // if (generateAccount === 'generateaccount') {
            const body1 = {
                remainingDays: Number(renewsubData?.totalDays) ? Number(renewsubData?.totalDays) : 0,
                totalDays: Number(renewsubData?.totalDays) ? Number(renewsubData?.totalDays) : 0,
                paymentCycle:Number(renewsubData?.paymentCycle) ? Number(renewsubData?.paymentCycle):0,
                        subTotal: Number(renewsubData?.totalPropertise * renewsubData?.ratePerProperty) ? Number(renewsubData?.totalPropertise * renewsubData?.ratePerProperty) : 0,
                        discountInPercentage: Number(renewsubData?.discountInPercentage)?Number(renewsubData?.discountInPercentage):0,
                        discountAmount: Number(renewsubData?.totalPropertise) && Number(renewsubData?.discountInPercentage) && Number(renewsubData?.discountInPercentage)!=0  ? ((renewsubData?.totalPropertise * renewsubData?.ratePerProperty)*(renewsubData?.discountInPercentage))/100 :  0,
                        totalAmount: renewsubData?.totalPropertise && renewsubData?.discountInPercentage && renewsubData?.discountInPercentage!=0  ? (renewsubData?.totalPropertise * renewsubData?.ratePerProperty)-((renewsubData?.totalPropertise * renewsubData?.ratePerProperty)*(renewsubData?.discountInPercentage))/100 : renewsubData?.totalPropertise && (!renewsubData?.discountInPercentage || renewsubData?.discountInPercentage==0) ? renewsubData?.totalPropertise * renewsubData?.ratePerProperty: 0,
                        paymentStatus: Number(renewsubData?.paymentStatus) ? Number(renewsubData?.paymentStatus) : 0,
                        totalPropertise: Number(renewsubData?.totalPropertise) ? Number(renewsubData?.totalPropertise) : 0,
                        totalCommonAreas: Number(renewsubData?.totalCommonAreas) ? Number(renewsubData?.totalCommonAreas) : 0,
                        totalUsers: Number(renewsubData?.totalUsers) ? Number(renewsubData?.totalUsers) : 0,
                        ratePerProperty: Number(renewsubData?.ratePerProperty) ? Number(renewsubData?.ratePerProperty) : 0,
                        subscriptionType: Number(renewsubData?.subscriptionType) ? Number(renewsubData?.subscriptionType) : 0,
                        startDate: renewsubData?.startDate ? renewsubData?.startDate : null,
                        endDate:  renewsubData?.endDate ? renewsubData?.endDate : null,
                        items:renewsubData?.items,
                        status:2,
                        accountFeature: {
                        numberOfSelected: (accountfeature.filter((e:any) => e.checked).length)+(service.filter((e:any) => e.checked).length)+(vendorfeature.filter((e:any) => e.checked).length)+(facilityfeature.filter((e:any) => e.checked).length),
                        numberOfTotal: 9,
                        property: accountfeature[0]?.checked,
                        tenancy: accountfeature[1]?.checked,
                        tenant: accountfeature[2]?.checked,
                        rent: accountfeature[3]?.checked,
                        announcement: accountfeature[4]?.checked,
                        vendorList: vendorfeature[0]?.checked,
                        service: facilityfeature[0]?.checked,
                        internalServiceRequest: service[0]?.checked,
                        externalServiceRequest: service[1]?.checked,
                        
                        },
                        corporateId: accountDetailData?._id
            }
            await ApiPost(`admin/corporate_subscription`, body1)
                .then((res) => {
                    SuccessToast(res?.data?.message)
                    setGenerateAccount('editsubscription')
                    setSubmitRenewal(false)
                    viewAccountDetail()
                })
                .catch((e) => {
                    console.log('e', e)
                })
        // }
    }

    const suspendAccountBtn = async () => {
        if(suspendData?.reason !== undefined){
            const body = {
                corporateId: accountDetailData?._id,
                reason: Number(suspendData?.reason)
            }
    
            await ApiPost(`admin/corporate/suspend`, body)
                .then((res) => {
                    SuccessToast(res?.data?.message)
                    setActivateAccount(true)
                    setSuspendAccount(false)
                    viewAccountDetail()
                    setSuspendData('')
    
                })
                .catch((e) => {
                    console.log('e', e)
                })
        }else{
            ErrorToast('please select proper reason')
        }
    }


    useEffect(() => {
        if (corporateId !== undefined) {
            viewAccountDetail()
        }
    }, [])

    const calltable = async () => {
        const body = {
            corporateId: corporateId,
            page: 1,
            limit: 10,
        }
        await ApiPost(`admin/corporate_subscription/get`, body)
            .then((res) => {
                // SuccessToast(res?.data?.message)
                // setActivateAccount(false)
                console.log(res)
                setTableData(res?.data?.data?.corporate_subscription_data)
            })
            .catch((e) => {
                console.log('e', e)
            })
    
    }
    const viewAccountDetail = async () => {
        await ApiGet(`admin/corporate/${corporateId}`)
            .then((res) => {
                calltable()
                setAccountDetailData(res?.data?.data[0])
                setSubscriptionData(res?.data?.data[0]?.corporate_subscription[0])
                // setPermissionData(res?.data?.data[0]?.account_permission[0])
                callcity(res?.data?.data[0]?.country)
                itemarray = res?.data?.data[0]?.corporate_subscription[0]?.items
                setAccountFeature([
                    { title: "Property Registration", name: 'property', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.property },
                    { title: "Tenancy Management ", name: 'tenancy', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.tenancy },
                    { title: "Tenant Management ", name: 'tenant', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.tenant },
                    { title: "Rent Management", name: 'rent', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.rent },
                    { title: "Announcement Management", name: 'announcement', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.announcement },
                ])
                if(res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.property &&
                    res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.tenancy &&
                    res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.tenant &&
                    res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.rent &&
                    res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.announcement  ){
                        console.log("jayraj")
                        setCheckAll(true)
                    }
                setvendorFeature([
                    { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.vendorList },
                ])
                setfacilityAll(res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.service)
                setfacilityFeature([
                    { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.service },
                ])
                setvendorAll(res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.vendorList)
                setService([
                    { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.internalServiceRequest },
                    { title: 'External Service Requests', name: 'externalServiceRequest', checked: res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.externalServiceRequest }
                ])
                if(res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.internalServiceRequest && res?.data?.data[0]?.corporate_subscription[0]?.accountFeature?.externalServiceRequest ){
                    setAllService(true)
                }
                setcount(count+1)

            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const reActivateAccount = async () => {
        const body = {
            id: accountDetailData?._id,
            accountStatus: 1
        }
        await ApiPut(`admin/corporate`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                setActivateAccount(false)
                viewAccountDetail()
            })
            .catch((e) => {
                console.log('e', e)
            })

    }
 
    return (
        <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 justify-content-center'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/account-detail/${accountDetailData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4  active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Account Details
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/payment-detail/${accountDetailData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Payments
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/user-detail/${accountDetailData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Users
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/property-detail/${accountDetailData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Propertise
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/tenancy-detail/${accountDetailData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Tenancies
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/tenant-detail/${accountDetailData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Tenants
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/request/request/${accountDetailData?._id}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Requests
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {

                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }} >
                                Troubleshooting
                            </a>
                        </li>
                    </ul>

                </div>
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>

                    <div id='' className='app-container container-xxl pt-0 mt-0'>
                        <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3'>
                            <label>Account Id </label>
                            <span>{accountDetailData?.id}</span>
                        </div>
                        <div className='mb-8 min-w-375px d-flex justify-content-between w-auto'>
                            <div className='d-flex align-items-center'>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <div
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        onClick={() => navigate('/corporate')}>
                                        Back
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-8 min-w-375px d-flex justify-content-between w-auto'>
                            <div className='d-flex align-items-center'>
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                   {accountDetailData?.accountStatus === 0 && subscription === false && <button
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        // type='submit'
                                        onClick={() => GenerateAccount()}
                                    >
                                        Generate Account
                                    </button>}
                                    {subscription === false  && <div
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        onClick={() => { setSubscription(true); setGenerateAccount('generateaccount') ;setrenewflag(true);setSubmitRenewal(false);viewAccountDetail()}}>
                                        Edit Details
                                    </div>
}
                                        { renewflag === true && subscription === true  &&<div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={() => {editSubscription()}}>
                                            Submit Updated Details
                                        </div>
                                    }
                                     {/* {submitRenewal !== true && accountDetailData?.accountStatus === 1 ? <div
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        onClick={() => { setSubmitRenewal(true);  }}>
                                        Edit Subscription
                                    </div>:null} */}
                                    {renewflag !== true && submitRenewal !== true && accountDetailData?.accountStatus === 1 && !accountDetailData?.isAlreadyRenewed? <div
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        onClick={() => {
                                            setrenewsubData({})
                                            setAccountFeature([
                                                { title: "Property Registration", name: 'property', checked: false },
                                                { title: "Tenancy Management ", name: 'tenancy', checked: false },
                                                { title: "Tenant Management ", name: 'tenant', checked: false },
                                                { title: "Rent Management", name: 'rent', checked: false },
                                                { title: "Announcement Management", name: 'announcement', checked: false },
                                            ]);
                                            
                                            setCheckAll(false);
                                                
                                            setvendorFeature([
                                                { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
                                            ])
                                            setfacilityAll(false)
                                            setfacilityFeature([
                                                { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
                                            ])
                                            setvendorAll(false)
                                            setAllService(false)
                                            setService([
                                                { title: 'Internal Service Requests', name: 'internalServiceRequest', checked: false },
                                                { title: 'External Service Requests', name: 'externalServiceRequest', checked: false }
                                            ]);
                                            setSubmitRenewal(true);  }}>
                                        Renew Subscription
                                    </div>:null}
                                   
                                      {submitRenewal == true && accountDetailData?.accountStatus === 1 ? <div
                                            className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                            onClick={() => renewSubscription()}>
                                            Submit Renewal
                                        </div> :null} 
                                </div>
                            </div>
                            {activateAccount === false && accountDetailData?.accountStatus === 1 && <div className='d-flex'>
                                <div className='mb-2 px-5 d-flex w-auto'>
                                    <div
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        onClick={() => setSuspendAccount(true)}
                                    >
                                        Suspend Account
                                    </div>

                                </div>
                            </div>}
                            {accountDetailData?.accountStatus === 5 && <div className='d-flex'>
                                <div className='mb-2 px-5 d-flex w-auto'>
                                    <div
                                        className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                        onClick={() => reActivateAccount()}
                                    >
                                        Reactivate Account
                                    </div>

                                </div>
                            </div>}
                            
                        </div>
                        <div className='d-flex align-items-center justify-content-end gap-2 mb-10 gap-lg-3'>
                            <label> Account Suspension Date :</label>
                            { IsEdit ? <><input
                                                 type='datetime-local'
                                                //  {...register("accountSuspensionDate", { required: true })}
                                                 style={{"width":"169px"}}
                                                 className='form-control form-control-solid'
                                                 name='accountSuspensionDate'
                                                 onChange={(e) => {
                                                    console.log(e)
                                                 setAccountDetailData({ ...accountDetailData, 'accountSuspensionDate': e.target.value })}}
                                                 value={accountDetailData?.accountSuspensionDate?.split('.')[0] ? accountDetailData?.accountSuspensionDate?.split('.')[0] : accountDetailData?.accountSuspensionDate }
                                                //  disabled={accountDetailData?.accountStatus === 0 ? false : true}
                                            />
                                            <AiOutlineCheck color='#007a59' style={{ cursor: 'pointer' }}
                                                                fontSize={20} onClick={() => {
                                                                    
                                                                    updatedate()}} />
                                            </> : 
                                            <>
                                            <span>{moment.parseZone(accountDetailData?.accountSuspensionDate).local(true).format('YYYY-MM-DD HH:mm')}</span>
                                            <AiFillEdit color='#007a59' style={{ cursor: 'pointer' }}
                                                                fontSize={20} onClick={() => setIsEdit(true)} />
                                                   </>             
                                            }
                             

                        </div>

                        
                    </div>
                    {generateAccount === 'generateaccount' && <div className='card card-flush'>
                        <>

                            <Row className='py-2'>
                                <Col lg={6}>
                                    <h3 className='mx-10 mb-10 mt-10'>Client Details </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Company Name
                                            </h6>
                                            <input
                                                type='text'
                                                {...register("companyName", { required: true })}
                                                className='form-control form-control-solid'
                                                name='companyName'
                                                onChange={(e) => setAccountDetailData({ ...accountDetailData, 'companyName': e.target.value })}
                                                value={accountDetailData?.companyName}
                                            />
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Country
                                                    </h6>
                                                </div>
                                                <div>
                                                    <select
                                                        {...register("country", { required: true })}
                                                        name='country'
                                                        className='form-select form-select-solid'
                                                        onChange={(e) => {
                                                            setAccountDetailData({ ...accountDetailData, 'country': e.target.value })
                                                            callcity(e.target.value)
                                                        }}
                                                        value={accountDetailData?.country}
                                                    >
                                                        <option value='' disabled selected>
                                                            Select country
                                                        </option>
                                                        {country?.map((v: any) => (
                                                            <option value={v.country}>{v.country}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    City
                                                </h6>
                                                <div>
                                                    <select
                                                        {...register("city", { required: true })}
                                                        name='city'
                                                        className='form-select form-select-solid'
                                                        onChange={(e) => setAccountDetailData({ ...accountDetailData, 'city': e.target.value })}
                                                        value={accountDetailData?.city}

                                                    >
                                                        <option value='' disabled selected>
                                                            Select city
                                                        </option>
                                                        {city?.map((v: any) => (
                                                            <option value={v.name}>{v.city}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Address
                                            </h6>
                                            <input
                                                type='text'
                                                {...register("address", { required: true })}
                                                className='form-control form-control-solid'
                                                name='address'
                                                onChange={(e) => setAccountDetailData({ ...accountDetailData, 'address': e.target.value })}
                                                value={accountDetailData?.address}

                                            />
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Trade License No.
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("tradeLicense", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={(e) => setAccountDetailData({ ...accountDetailData, 'tradeLicense': e.target.value })}
                                                name='tradeLicense'
                                                value={accountDetailData?.tradeLicense}
                                            />

                                            <button
                                                className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative w-300px'>
                                                Trade License Upload
                                                <input
                                                    type='file'
                                                    className='form-control form-control-solid position-absolute '
                                                    placeholder=''
                                                    name='contract'
                                                    onChange={uploadFilelHandler}
                                                    // onChange={uploadFilelHandler}
                                                    style={{ top: 0, left: 0, width: "100%", height: "100%", opacity: 0 }}
                                                />
                                            </button>
                                            {uploadedFile?<img src={Bucket+uploadedFile} width={50} height={50}></img>:<img src={`${Bucket}${accountDetailData?.tradeLicenseURL}`} width={50} height={50}></img>}
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                TRN
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("TRN", { required: true })}
                                                className='form-control form-control-solid'
                                                name='TRN'
                                                onChange={(e) => setAccountDetailData({ ...accountDetailData, 'TRN': e.target.value })}
                                                value={accountDetailData?.TRN}
                                            />
                                        </div>
                                        <div className='payment-receipt-item contract_number d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 me-2 min-w-100px'>
                                                Phone
                                            </h6>
                                            {/* <Controller
                                                control={control}
                                                
                                                {...register("phone", {
                                                    required: true, pattern: {
                                                        value:/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
                                                        message: "invalid email address"
                                                    }
                                                })}
                                                name="phone"
                                                render={({ field: { ref, ...field } }) => (
                                                    <ReactPhoneInput
                                                        // country={countryName}
                                                        placeholder='Enter phone number'
                                                        onChange={(phone, e) => handleNumber(phone, e)}
                                                    // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                    />
                                                )}
                                            /> */}
                                            <ReactPhoneInput
                                                // country={countryName}
                                                placeholder='Enter phone number'
                                                onChange={(phone, e) => handleNumber(phone, e)}
                                                value={`${accountDetailData?.countryCode}${accountDetailData?.phoneNumber}`}
                                            // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <h3 className='mx-10 mb-10 mt-10'> Main User Details  </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item mb-5'>
                                            <div className='d-flex'>
                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                        First Name
                                                    </h6>
                                                    <div>
                                                        <input
                                                            type='text'
                                                            {...register("firstName", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='firstName'
                                                            onChange={(e) => setAccountDetailData({ ...accountDetailData, 'firstName': e.target.value })}
                                                            value={accountDetailData?.firstName}

                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-50 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                        Last Name
                                                    </h6>
                                                    <div>
                                                        <input
                                                            type='text'
                                                            {...register("lastName", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='lastName'
                                                            onChange={(e) => setAccountDetailData({ ...accountDetailData, 'lastName': e.target.value })}
                                                            value={accountDetailData?.lastName}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='payment-receipt-item mb-5'>
                                            <div className='d-flex'>
                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                        Email
                                                    </h6>
                                                    <input
                                                        type='text'
                                                        {...register("email", {
                                                            required: true, pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "invalid email address"
                                                            }
                                                        })}
                                                        className='form-control form-control-solid'
                                                        name='email'
                                                        onChange={(e) => setAccountDetailData({ ...accountDetailData, 'email': e.target.value })}
                                                        value={accountDetailData?.email}
                                                    />
                                                </div>

                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2 min-w-100px'>
                                                        Mobile No.
                                                    </h6>
                                                    {/* <Controller
                                                control={control}
                                                
                                                {...register("phone", {
                                                    required: true, pattern: {
                                                        value:/([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
                                                        message: "invalid email address"
                                                    }
                                                })}
                                                name="phone"
                                                render={({ field: { ref, ...field } }) => (
                                                    <ReactPhoneInput
                                                        // country={countryName}
                                                        placeholder='Enter phone number'
                                                        onChange={(phone, e) => handleNumber(phone, e)}
                                                    // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                    />
                                                )}
                                            /> */}
                                                    <ReactPhoneInput
                                                        // country={countryName}
                                                        placeholder='Enter phone number'
                                                        onChange={(phone, e) => handleNumber2(phone, e)}
                                                        inputStyle={{ width: '180px' }}
                                                        value={`${accountDetailData?.accountOwnerCountryCode}${accountDetailData?.accountOwnerMobile}`}
                                                    // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                            <Row className='py-2'>
                                <Col lg={10}>
                                    <h3 className='mx-10 mb-10 mt-10'>
                                        Subscription Details
                                    </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 mr-2' style={{ width: '75%' }}>
                                                    Subscription Type
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <select
                                                        {...register("subscriptionType", { required: true })}
                                                        className='form-select form-select-solid'
                                                        name='subscriptionType'
                                                        onChange={(e) => setSubscriptionData({ ...subscriptionData, 'subscriptionType': e.target.value })}
                                                        value={subscriptionData?.subscriptionType}
                                                    >
                                                        <option selected value=''>
                                                            Select
                                                        </option>
                                                        <option value={0}>
                                                            Free Demo
                                                        </option>
                                                        <option value={1}>
                                                            Paid Subscription
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='pe-5 w-1 d-flex align-items-center'>
                                           
                                            <div className='w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                        Start Date
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <input
                                                        type='date'
                                                        {...register("startDate", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='startDate'
                                                        onChange={handleChnagedate}
                                                        value={subscriptionData?.startDate?.split('T')[0]}
                                                        disabled={accountDetailData?.accountStatus === 0 ? false : true}

                                                    />
                                                </div>
                                            </div>
                                            <div className=' w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 pe-2'>
                                                        End Date
                                                    </h6>
                                                </div>
                                                <div>
                                                    <input
                                                        type='date'
                                                        className='form-control form-control-solid'
                                                        name='endDate'
                                                        value={subscriptionData?.endDate?.split('T')[0]}
                                                        onChange={handleChnagedate}
                                                        min={moment(subscriptionData?.startDate).format('YYYY-MM-DD')}
                                                        disabled={accountDetailData?.accountStatus === 0 ? false : true}

                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                    <h6 className='text-muted m-0'>
                                                        Days
                                                    </h6>
                                                </div>
                                                <div >
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        name='totalMonths'
                                                        onChange={(e) => setSubscriptionData({ ...subscriptionData, 'totalDays': e.target.value })}
                                                        value={subscriptionData?.totalDays}
                                                        disabled
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2' style={{ width: '15%' }}>
                                                Account Features
                                            </h6>
                                            <div
                                                className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                onClick={() => setShow(true)}>
                                                Select
                                            </div>
                                            <h6 className='text-muted m-0 mr-2' style={{ width: '15%' }}>
                                                    Selected Features
                                                </h6>
                                                <h6 className='text-muted m-0 mr-2' >
                                                {(accountfeature.filter((e:any) => e.checked).length)+(service.filter((e:any) => e.checked).length)+(vendorfeature.filter((e:any) => e.checked).length)+(facilityfeature.filter((e:any) => e.checked).length)}/9
                                                </h6>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Total Properties
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("totalPropertise", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={(e) => setSubscriptionData({ ...subscriptionData, 'totalPropertise': e.target.value })}
                                                name='totalPropertise'
                                                value={subscriptionData?.totalPropertise}
                                            // onChange={uploadFilelHandler}
                                            />

                                            <h6 className='text-muted m-0 mr-2 min-w-150px text-center'>
                                                Total Common Areas
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("totalCommonAreas", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={(e) => setSubscriptionData({ ...subscriptionData, 'totalCommonAreas': e.target.value })}
                                                name='totalCommonAreas'
                                                value={subscriptionData?.totalCommonAreas}
                                            // onChange={uploadFilelHandler}
                                            />
                                            <h6 className='text-muted m-0 mr-2 min-w-90px text-center'>
                                                Total Users
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("totalUsers", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={(e) => setSubscriptionData({ ...subscriptionData, 'totalUsers': e.target.value })}
                                                name='totalUsers'
                                                value={subscriptionData?.totalUsers}
                                            // onChange={uploadFilelHandler}
                                            />
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Rate per property
                                            </h6>
                                            <div style={{ width: '200px' }}>
                                                <input
                                                    type='number'
                                                    {...register("ratePerProperty", { required: true })}
                                                    className='form-control form-control-solid'
                                                    name='ratePerProperty'
                                                    onChange={(e) => setSubscriptionData({ ...subscriptionData, 'ratePerProperty': e.target.value })}
                                                    value={subscriptionData?.ratePerProperty}
                                                />
                                            </div>

                                            <h6 className='text-muted m-0  min-w-80px'>
                                                AED
                                            </h6>

                                            <h6 className='text-muted m-0 me-4'>
                                                Discount
                                            </h6>
                                            <div style={{ width: '193px' }}>
                                                <input
                                                    type='text'
                                                    {...register("discountInPercentage", { required: true })}
                                                    className='form-control form-control-solid'
                                                    name='discountInPercentage'
                                                    onChange={(e) => setSubscriptionData({ ...subscriptionData, 'discountInPercentage': e.target.value })}
                                                    value={subscriptionData?.discountInPercentage}
                                                />
                                            </div>%
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Monthly Subscription:
                                            </h6>
                                                <input
                                                    type='text'
                                                    // {...register("discountInPercentage", { required: true })}
                                                    className='form-control form-control-solid'
                                                    style={{ width: '150px' }}
                                                    disabled
                                                    // name='discountInPercentage'
                                                    // onChange={(e) => setSubscriptionData({ ...subscriptionData, 'discountInPercentage': e.target.value })}
                                                    value={subscriptionData?.totalPropertise && subscriptionData?.discountInPercentage && subscriptionData?.discountInPercentage!=0  ? (subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty)-((subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty)*(subscriptionData?.discountInPercentage))/100 : subscriptionData?.totalPropertise && (!subscriptionData?.discountInPercentage || subscriptionData?.discountInPercentage==0) ? subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty: 0}
                                                />
                                            {/* <span style={{ width: '22%', marginLeft: '20px' }}> AED {subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty ? subscriptionData?.totalPropertise * subscriptionData?.ratePerProperty : 0} </span> */}
                                            <h6 className='text-muted m-0 mr-2 min-w-150px text-center'>
                                                Payment Status
                                            </h6>
                                            <select
                                                {...register("paymentStatus", { required: true })}
                                                className='form-select form-select-solid'
                                                name='paymentStatus'
                                                onChange={(e) => setSubscriptionData({ ...subscriptionData, 'paymentStatus': e.target.value })}
                                                value={subscriptionData?.paymentStatus}

                                            >
                                                <option selected value=''>
                                                    Select
                                                </option>
                                                <option value={0}>
                                                    Not Paid
                                                </option>
                                                <option value={1}>
                                                    Partially Paid
                                                </option>
                                                <option value={2}>
                                                    Paid
                                                </option>
                                            </select>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px text-center' style={{ width: '40%' }}>
                                            Payment Cycle1:
                                            </h6>
                                            <select
                                                    // {...register("paymentStatus", { required: true })}
                                                    className='form-select form-select-solid'
                                                    name='paymentCycle'
                                                    onChange={(e) => setSubscriptionData({ ...subscriptionData, 'paymentCycle': e.target.value })}
                                                    value={subscriptionData?.paymentCycle}
                                                    disabled={accountDetailData?.accountStatus === 0 ? false : true}

                                                >
                                                    <>
                                                    <option selected value=''>
                                                        Select 
                                                    </option>
                                                    <option value={1}>
                                                        Single
                                                    </option>
                                                    <option value={2}>
                                                        Monthly
                                                    </option>
                                                    {/* {subscriptionData?.totalMonths && [...Array(parseInt(subscriptionData?.totalMonths)).keys() as any].map( i => i+1)?.map((v: any, i: any) => <option value={v}>
                                                        {v}
                                                    </option>)} */}
                                                    </>
                                                    {/* <option value={1}>
                                                        Partially Paid
                                                    </option>
                                                    <option value={2}>
                                                        Paid
                                                    </option> */}
                                                </select>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                              
                                                <div
                                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                    onClick={() => implementationcount()}
                                                    >
                                                    Add Other fess
                                                </div>
                                            </div>
                            <Row>
                            {itemarray.length>0 && <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <table className='w-100'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Item Number</th>
                                                            <th className='text-center'>Description</th>
                                                            <th className='text-center'>Quantity</th>
                                                            <th className='text-center'>Unit Price</th>
                                                            <th className='text-center'>Discount(%)</th>
                                                            <th className='text-center'>Amount(AED)</th>
                                                            <th className='text-center'>Action</th>

                                                        </tr>
                                                        {itemarray.length>0 && (itemarray as any)?.map((v: any, i: any) => 
                                            <>{v.isEditable?<tr>
                                                <td className='text-center'><h6 className='text-muted m-0 mr-2'>
                                                            {i+1}
                                                        </h6></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                        <input
                                                            type='text'
                                                            // {...register("description", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='description'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.description}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("quantity", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='qty'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.qty}
                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='unitPrice'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.unitPrice}

                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='discountInPercentage'
                                                            onChange={(e:any)=>{
                                                                if(e.target.value>100){
ErrorToast("Discount is not more than 100")
                                                                }else{
                                                                    handleChnage1(e,i)
                                                                }
                                                                }}
                                                            value={v.discountInPercentage}

                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='taxableAmount'
                                                            onChange={(e)=>handleChnage1(e,i)}
                                                            value={v.taxableAmount}
                                                            // value={v.qty && v.unitPrice && (v.discountInPercentage!==0 || v.discountInPercentage) ? (v.qty*v.unitPrice)-(((v.qty*v.unitPrice)*v.discountInPercentage)/100) :v.qty && (!v.unitPrice || v.discountInPercentage==0 || v.discountInPercentage==NaN as any) ? v.qty*v.unitPrice :0}
                                                            disabled
                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td className='text-center'><SVG src='\media\icons\duotune\arrows\Trash.svg' color='#007a59' onClick={() => implementationdelete(i)}/></td>
                                                </tr>:<tr>
                                                <td className='text-center'>
                                                            {i+1}
                                                        </td>
                                                <td className='text-center'>{v.description}</td>
                                                <td className='text-center'>{v.qty}</td>
                                                <td className='text-center'>{v.unitPrice}</td>
                                                <td className='text-center'>{v.discountInPercentage}</td>
                                                <td className='text-center'>{v.taxableAmount}</td>
                                                <td className='text-center'>N/A</td>

                                                </tr>}</>)}
                                                        
                                                    </thead>
                                                   

                                                </table>
                                                </div>}
                                                </Row>
                            {accountDetailData?.accountStatus !== 0 ?<><h3 className='mx-10 mb-10 mt-10'>
                                Subscriptions
                            </h3>
                            <div className='card-body pt-0 table-responsive mt-5'>
                                <table
                                    className='table align-middle table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                >
                                    <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0'>
                                            <th className='text-center min-w-10px'></th>
                                            <th className='min-w-100px'> Subscription Type   </th>
                                            <th className='min-w-100px'>Start Date</th>
                                            <th className='min-w-70px'>End Date</th>
                                            <th className='min-w-70px'>Total Properties</th>
                                            <th className='min-w-70px'>Total Common Areas</th>
                                            <th className='min-w-70px'>Total Users </th>
                                            <th className='min-w-70px'>Rate per Property</th>
                                            <th className='min-w-70px'>Subscription Amount</th>
                                            <th className='min-w-100px'>Payment Status </th>
                                            <th className='min-w-100px'></th>

                                        </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                        {tableData?.map((v: any, i: any) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                        </div>
                                                    </td>
                                                    <td>{subscriptionData?.subscriptionType === 0 ? 'Free Demo' : 'Paid Subscription'}</td>
                                                    <td>{moment(subscriptionData?.startDate).format('YYYY-MM-DD')}</td>
                                                    <td>{moment(subscriptionData?.endDate).format('YYYY-MM-DD')}</td>
                                                    <td>{subscriptionData?.totalPropertise}</td>
                                                    <td>{subscriptionData?.totalCommonAreas}</td>
                                                    <td>{subscriptionData?.totalUsers}</td>
                                                    <td>{subscriptionData?.ratePerProperty}</td>
                                                    <td>{subscriptionData?.ratePerProperty * subscriptionData?.totalPropertise}</td>
                                                    <td>{subscriptionData?.paymentStatus === 0 ? 'Not Paid'
                                                        : subscriptionData?.paymentStatus === 1 ? 'Partial Paid' : 'Paid'}</td>
                                                    <td className='text-center'>
                                                        <div className='menu-item px-3'>
                                                            <div
                                                                className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                            >
                                                                View
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div></>:null}
                        </>
                    </div>}
                    {generateAccount === 'editsubscription' && <div className='card card-flush'>
                        <>

                            <Row className='py-2'>
                                <Col lg={6}>
                                    <h3 className='mx-10 mb-10 mt-10'>Client Details </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 me-2'>
                                                Company Name
                                            </h6>
                                            <b>{accountDetailData?.companyName}</b>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Country
                                                    </h6>
                                                </div>
                                                <div>
                                                    <b>{accountDetailData?.country}</b>
                                                </div>
                                            </div>
                                            <div className='w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    City
                                                </h6>
                                                <div>
                                                    <b>{accountDetailData?.city}</b>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 me-2'>
                                                Address
                                            </h6>
                                            <b>{accountDetailData?.address}</b>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 me-2'>
                                                Trade License No.
                                            </h6>
                                            <b>{accountDetailData?.tradeLicense}</b>

                                            <a
                                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                href={`${Bucket}${accountDetailData?.tradeLicenseURL}`} target="_blank">
                                                View Trade License
                                            </a>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 me-2'>
                                                TRN
                                            </h6>
                                            <b>{accountDetailData?.TRN}</b>
                                        </div>
                                        <div className='payment-receipt-item contract_number d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 me-2'>
                                                Phone
                                            </h6>

                                            <b>+{accountDetailData?.countryCode} {accountDetailData?.phoneNumber}</b>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <h3 className='mx-10 mb-10 mt-10'> Main User Details  </h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item mb-5'>
                                            <div className='d-flex'>
                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        First Name
                                                    </h6>
                                                    <b>{accountDetailData?.firstName}</b>
                                                </div>
                                                <div className='w-50 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 mr-2 min-w-100px'>
                                                        Last Name
                                                    </h6>
                                                    <b>{accountDetailData?.lastName
                                                    }</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='payment-receipt-item mb-5'>
                                            <div className='d-flex'>
                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Email
                                                    </h6>
                                                    <b>{accountDetailData?.email
                                                    }</b>
                                                </div>

                                                <div className='w-50 pe-5 d-flex align-items-center'>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Mobile No.
                                                    </h6>
                                                    <b>+{accountDetailData?.accountOwnerCountryCode} {accountDetailData?.accountOwnerMobile
                                                    }</b>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                            <Row className='py-2'>
                                <Col lg={10}>
                                    <h3 className='mx-10 mb-10 mt-10'>
                                        Subscription Details
                                    </h3>
                                    {!submitRenewal?<><div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    Subscription Type
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.subscriptionType === 0 ? 'Free Demo' : 'Paid Subscription'}</b>
                                                </div>
                                            </div>
                                           
                                            <div className='w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Start Date
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{moment(subscriptionData?.startDate).format('DD/MM/YYYY')}</b>
                                                </div>
                                            </div>
                                            <div className=' w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                        End Date
                                                    </h6>
                                                </div>
                                                <div>
                                                    <b>{moment(subscriptionData?.endDate).format('DD/MM/YYYY')}</b>
                                                </div>
                                            </div>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    Days
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.totalDays}</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2' style={{ width: '20%' }}>
                                                Account Features
                                            </h6>
                                            <div
                                                className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                onClick={() =>{ setShow(true); Setpermissionflag(true)}}>
                                                Select
                                            </div>
                                            <h6 className='text-muted m-0 mr-2' style={{ width: '15%' }}>
                                                    Selected Features
                                                </h6>
                                                <h6 className='text-muted m-0 mr-2' >
                                                {(accountfeature.filter((e:any) => e.checked).length)+(service.filter((e:any) => e.checked).length)+(vendorfeature.filter((e:any) => e.checked).length)+(facilityfeature.filter((e:any) => e.checked).length)}/9
                                                </h6>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                    Total Properties:
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.totalPropertise}</b>
                                                </div>
                                            </div>
                                            <div className='w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 me-2'>
                                                        Total Common Areas:
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.totalCommonAreas}</b>
                                                </div>
                                            </div>
                                            <div className=' w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                      Total Users:
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.totalUsers}</b>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                Rate per property :
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.ratePerProperty} AED</b>
                                                </div>
                                            </div>
                                            
<div className=' w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                      Discount:
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.discountInPercentage}%</b>
                                                </div>
                                            </div>
                                            <div className=' w-50 d-flex align-items-center'></div>

                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                Monthly Subscription:
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.totalAmount} AED</b>
                                                </div>
                                            </div>
                                            
<div className=' w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                    Payment Status:
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.paymentStatus === 0 ? 'Not Paid'
                                                : subscriptionData?.paymentStatus === 1 ? 'Partial Paid' : 'Paid'}</b>
                                                </div>
                                            </div>

                                            <div className=' w-50 d-flex align-items-center'>
                                            <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                    Payment Cycle:
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                <b>{subscriptionData?.paymentCycle === 1 ? 'Single' : 'Monthly'}</b>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                Total Subscription:
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.fullSubTotal} AED</b>
                                                </div>
                                            </div>

                                            <div className=' w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 me-2'>
                                                Other Fees:
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <b>{subscriptionData?.totalOtherFees} AED</b>
                                                </div>
                                            </div>
                                            
                                            <div className=' w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                    Total Amount:
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                <b>{subscriptionData?.finalSubTotal} AED</b>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    
                                    </div><h3 className='mx-10 mb-10 mt-10'>
                                        Other Fees
                                    </h3>
                            {itemarray.length>0 && <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <table className='w-100'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Item Number</th>
                                                            <th className='text-center'>Description</th>
                                                            <th className='text-center'>Quantity</th>
                                                            <th className='text-center'>Unit Price</th>
                                                            <th className='text-center'>Discount(%)</th>
                                                            <th className='text-center'>Amount(AED)</th>

                                                        </tr>
                                                        {itemarray.length>0 && (itemarray as any)?.map((v: any, i: any) => 
                                            <><tr>
                                                <td className='text-center'>
                                                            {i+1}
                                                        </td>
                                                <td className='text-center'>{v.description}</td>
                                                <td className='text-center'>{v.qty}</td>
                                                <td className='text-center'>{v.unitPrice}</td>
                                                <td className='text-center'>{v.discountInPercentage}</td>
                                                <td className='text-center'>{v.taxableAmount}</td>
                                                </tr></>)}
                                                        
                                                    </thead>
                                                   

                                                </table>
                                                </div>}</>:<><div className='min-w-200px mx-10'>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <div className='pe-5 w-50 d-flex align-items-center'>
                                                <h6 className='text-muted m-0 mr-2' style={{ width: '75%' }}>
                                                    Subscription Type
                                                </h6>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <select
                                                        {...register("subscriptionType", { required: true })}
                                                        className='form-select form-select-solid'
                                                        name='subscriptionType'
                                                        onChange={(e) => setrenewsubData({ ...renewsubData, 'subscriptionType': e.target.value })}
                                                        value={renewsubData?.subscriptionType}
                                                    >
                                                        <option selected value=''>
                                                            Select
                                                        </option>
                                                        <option value={0}>
                                                            Free Demo
                                                        </option>
                                                        <option value={1}>
                                                            Paid Subscription
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                           
                                            <div className='w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 mr-2'>
                                                        Start Date
                                                    </h6>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <input
                                                        type='date'
                                                        {...register("startDate", { required: true })}
                                                        className='form-control form-control-solid'
                                                        name='startDate'
                                                        onChange={handleChnagedaterenew}
                                                        value={renewsubData?.startDate?.split('T')[0]}

                                                    />
                                                </div>
                                            </div>
                                            <div className=' w-50 d-flex align-items-center'>
                                                <div>
                                                    <h6 className='text-muted m-0 pe-2'>
                                                        End Date
                                                    </h6>
                                                </div>
                                                <div>
                                                    <input
                                                        type='date'
                                                        className='form-control form-control-solid'
                                                        name='endDate'
                                                        value={renewsubData?.endDate?.split('T')[0]}
                                                        onChange={handleChnagedaterenew}
                                                        min={moment(renewsubData?.startDate).format('YYYY-MM-DD')}
                                                    />
                                                </div>
                                            </div>
                                            <div className='pe-5 w-1 d-flex align-items-center'>
                                            <div>
                                                    <h6 className='text-muted m-0'>
                                                        Days
                                                    </h6>
                                                </div>
                                                <div >
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-solid'
                                                        name='totalMonths'
                                                        onChange={(e) => setrenewsubData({ ...renewsubData, 'totalDays': e.target.value })}
                                                        value={renewsubData?.totalDays}
                                                        disabled
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2' style={{ width: '15%' }}>
                                                Account Features
                                            </h6>
                                            <div
                                                className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                onClick={() => setShow(true)}>
                                                Select
                                            </div>
                                            <h6 className='text-muted m-0 mr-2' style={{ width: '15%' }}>
                                                    Selected Features
                                                </h6>
                                                <h6 className='text-muted m-0 mr-2' >
                                                {(accountfeature.filter((e:any) => e.checked).length)+(service.filter((e:any) => e.checked).length)+(vendorfeature.filter((e:any) => e.checked).length)+(facilityfeature.filter((e:any) => e.checked).length)}/9
                                                </h6>
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Total Properties
                                            </h6>
                                            <input
                                                type='number'
                                                // {...register("totalPropertise", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={(e) => setrenewsubData({ ...renewsubData, 'totalPropertise': e.target.value })}
                                                name='totalPropertise'
                                                value={renewsubData?.totalPropertise}
                                            // onChange={uploadFilelHandler}
                                            />

                                            <h6 className='text-muted m-0 mr-2 min-w-150px text-center'>
                                                Total Common Areas
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("totalCommonAreas", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={(e) => setrenewsubData({ ...renewsubData, 'totalCommonAreas': e.target.value })}
                                                name='totalCommonAreas'
                                                value={renewsubData?.totalCommonAreas}
                                            // onChange={uploadFilelHandler}
                                            />
                                            <h6 className='text-muted m-0 mr-2 min-w-90px text-center'>
                                                Total Users
                                            </h6>
                                            <input
                                                type='number'
                                                {...register("totalUsers", { required: true })}
                                                className='form-control form-control-solid'
                                                placeholder=''
                                                onChange={(e) => setrenewsubData({ ...renewsubData, 'totalUsers': e.target.value })}
                                                name='totalUsers'
                                                value={renewsubData?.totalUsers}
                                            // onChange={uploadFilelHandler}
                                            />
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Rate per property
                                            </h6>
                                            <div style={{ width: '200px' }}>
                                                <input
                                                    type='number'
                                                    {...register("ratePerProperty", { required: true })}
                                                    className='form-control form-control-solid'
                                                    name='ratePerProperty'
                                                    onChange={(e) => setrenewsubData({ ...renewsubData, 'ratePerProperty': e.target.value })}
                                                    value={renewsubData?.ratePerProperty}
                                                />
                                            </div>

                                            <h6 className='text-muted m-0  min-w-80px'>
                                                AED
                                            </h6>

                                            <h6 className='text-muted m-0 me-4'>
                                                Discount
                                            </h6>
                                            <div style={{ width: '193px' }}>
                                                <input
                                                    type='number'
                                                    {...register("discountInPercentage", { required: true })}
                                                    className='form-control form-control-solid'
                                                    name='discountInPercentage'
                                                    onChange={(e) => setrenewsubData({ ...renewsubData, 'discountInPercentage': e.target.value })}
                                                    value={renewsubData?.discountInPercentage}
                                                />
                                            </div>%
                                        </div>
                                        <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px'>
                                                Monthly Subscription:
                                            </h6>
                                                <input
                                                    type='number'
                                                    // {...register("discountInPercentage", { required: true })}
                                                    className='form-control form-control-solid'
                                                    style={{ width: '150px' }}
                                                    disabled
                                                    // name='discountInPercentage'
                                                    // onChange={(e) => setrenewsubData({ ...renewsubData, 'discountInPercentage': e.target.value })}
                                                    value={renewsubData?.totalPropertise && renewsubData?.discountInPercentage && renewsubData?.discountInPercentage!=0  ? (renewsubData?.totalPropertise * renewsubData?.ratePerProperty)-((renewsubData?.totalPropertise * renewsubData?.ratePerProperty)*(renewsubData?.discountInPercentage))/100 : renewsubData?.totalPropertise && (!renewsubData?.discountInPercentage || renewsubData?.discountInPercentage==0) ? renewsubData?.totalPropertise * renewsubData?.ratePerProperty: 0}
                                                />
                                            {/* <span style={{ width: '22%', marginLeft: '20px' }}> AED {renewsubData?.totalPropertise * renewsubData?.ratePerProperty ? renewsubData?.totalPropertise * renewsubData?.ratePerProperty : 0} </span> */}
                                            <h6 className='text-muted m-0 mr-2 min-w-150px text-center'>
                                                Payment Status
                                            </h6>
                                            <select
                                                {...register("paymentStatus", { required: true })}
                                                className='form-select form-select-solid'
                                                name='paymentStatus'
                                                onChange={(e) => setrenewsubData({ ...renewsubData, 'paymentStatus': e.target.value })}
                                                value={renewsubData?.paymentStatus}

                                            >
                                                <option selected value=''>
                                                    Select
                                                </option>
                                                <option value={0}>
                                                    Not Paid
                                                </option>
                                                <option value={1}>
                                                    Partially Paid
                                                </option>
                                                <option value={2}>
                                                    Paid
                                                </option>
                                            </select>
                                            <h6 className='text-muted m-0 mr-2 min-w-150px text-center' style={{ width: '40%' }}>
                                            Payment Cycle:
                                            </h6>
                                            <select
                                                    // {...register("paymentStatus", { required: true })}
                                                    className='form-select form-select-solid'
                                                    name='paymentCycle'
                                                    onChange={(e) => setrenewsubData({ ...renewsubData, 'paymentCycle': e.target.value })}
                                                    value={renewsubData?.paymentCycle}

                                                >
                                                    <>
                                                    <option selected value=''>
                                                        Select 
                                                    </option>
                                                    <option value={1}>
                                                        Single
                                                    </option>
                                                    <option value={2}>
                                                        Monthly
                                                    </option>
                                                    {/* {renewsubData?.totalMonths && [...Array(parseInt(renewsubData?.totalMonths)).keys() as any].map( i => i+1)?.map((v: any, i: any) => <option value={v}>
                                                        {v}
                                                    </option>)} */}
                                                    </>
                                                    {/* <option value={1}>
                                                        Partially Paid
                                                    </option>
                                                    <option value={2}>
                                                        Paid
                                                    </option> */}
                                                </select>
                                        </div>
                                    </div>
                                    <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                              
                                                <div
                                                    className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                                    onClick={() => renewimplementationcount()}
                                                    >
                                                    Add Other fess
                                                </div>
                                            </div>
                            <Row>
                            {renewitemarray.length>0 && <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                <table className='w-100'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Item Number</th>
                                                            <th className='text-center'>Description</th>
                                                            <th className='text-center'>Quantity</th>
                                                            <th className='text-center'>Unit Price</th>
                                                            <th className='text-center'>Discount(%)</th>
                                                            <th className='text-center'>Amount(AED)</th>
                                                            <th className='text-center'>Action</th>

                                                        </tr>
                                                        {renewitemarray.length>0 && (renewitemarray as any)?.map((v: any, i: any) => 
                                            <>{v.isEditable?<tr>
                                                <td className='text-center'><h6 className='text-muted m-0 mr-2'>
                                                            {i+1}
                                                        </h6></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                        <input
                                                            type='text'
                                                            // {...register("description", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='description'
                                                            onChange={(e)=>renewhandleChnage1(e,i)}
                                                            value={v.description}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("quantity", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='qty'
                                                            onChange={(e)=>renewhandleChnage1(e,i)}
                                                            value={v.qty}
                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='unitPrice'
                                                            onChange={(e)=>renewhandleChnage1(e,i)}
                                                            value={v.unitPrice}

                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='discountInPercentage'
                                                            onChange={(e:any)=>{
                                                                if(e.target.value>100){
ErrorToast("Discount is not more than 100")
                                                                }else{
                                                                    renewhandleChnage1(e,i)
                                                                }
                                                                }}
                                                            value={v.discountInPercentage}

                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td><div style={{ marginLeft: '5px' }}>
                                                    <input
                                                            type='number'
                                                            // {...register("unitprice", { required: true })}
                                                            className='form-control form-control-solid'
                                                            name='taxableAmount'
                                                            onChange={(e)=>renewhandleChnage1(e,i)}
                                                            value={v.taxableAmount}
                                                            // value={v.qty && v.unitPrice && (v.discountInPercentage!==0 || v.discountInPercentage) ? (v.qty*v.unitPrice)-(((v.qty*v.unitPrice)*v.discountInPercentage)/100) :v.qty && (!v.unitPrice || v.discountInPercentage==0 || v.discountInPercentage==NaN as any) ? v.qty*v.unitPrice :0}
                                                            disabled
                                                            min={0}

                                                        />
                                                    </div></td>
                                                <td className='text-center'><SVG src='\media\icons\duotune\arrows\Trash.svg' color='#007a59' onClick={() => renewimplementationdelete(i)}/></td>
                                                </tr>:<tr>
                                                <td className='text-center'>
                                                            {i+1}
                                                        </td>
                                                <td className='text-center'>{v.description}</td>
                                                <td className='text-center'>{v.qty}</td>
                                                <td className='text-center'>{v.unitPrice}</td>
                                                <td className='text-center'>{v.discountInPercentage}</td>
                                                <td className='text-center'>{v.taxableAmount}</td>
                                                <td className='text-center'>N/A</td>

                                                </tr>}</>)}
                                                        
                                                    </thead>
                                                   

                                                </table>
                                                </div>}
                                                </Row></>}
                                </Col>

                            </Row>
                            
                            {accountDetailData?.accountStatus !== 0 ?<><h3 className='mx-10 mb-10 mt-10'>
                                Subscriptions
                            </h3>
                            <div className='card-body pt-0 table-responsive mt-5'>
                                <table
                                    className='table align-middle table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                >
                                    <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0'>
                                            <th className='text-center min-w-10px'></th>
                                            <th className='min-w-100px'> Subscription Type</th>
                                            <th className='min-w-100px'>Start Date</th>
                                            <th className='min-w-70px'>End Date</th>
                                            <th className='min-w-70px'>Subscription Status</th>
                                            <th className='min-w-70px'>Total Properties</th>
                                            <th className='min-w-70px'>Total Common Areas</th>
                                            <th className='min-w-70px'>Total Users </th>
                                            <th className='min-w-70px'>Rate per Property</th>
                                            <th className='min-w-70px'>Discount(%)</th>
                                            <th className='min-w-70px'>Subscription Amount</th>
                                            
                                            <th className='min-w-100px'>Payment Status </th>
                                            <th className='min-w-100px'></th>

                                        </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                        {tableData?.map((v: any, i: any) => 
                                           
                                                <tr>
                                                    <td>
                                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                                        </div>
                                                    </td>
                                                    <td>{v?.subscriptionType === 0 ? 'Free Demo' : 'Paid Subscription'}</td>
                                                    <td>{moment(v?.startDate).format('YYYY-MM-DD')}</td>
                                                    <td>{moment(v?.endDate).format('YYYY-MM-DD')}</td>
                                                    <td>{v?.status === 1 ? 'Active'
                                                        : v?.status === 2 ? 'Renewal' : 'InActive'}</td>
                                                    <td>{v?.totalPropertise}</td>
                                                    <td>{v?.totalCommonAreas}</td>
                                                    <td>{v?.totalUsers}</td>
                                                    <td>{v?.ratePerProperty}</td>
                                                    <td>{v?.discountInPercentage}</td>
                                                    <td>{v?.totalAmount}</td>
                                                   
                                                    <td>{v?.paymentStatus === 0 ? 'Not Paid'
                                                        : v?.paymentStatus === 1 ? 'Partial Paid' : 'Paid'}</td>
                                                    <td className='text-center'>
                                                        <div className='menu-item px-3'>
                                                            {v?.status !== 2 ? <div
                                                                className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                                onClick={() => navigate(`/corporate/view-subscription-detail/${accountDetailData?._id}`,{state:{subscriptionid:v?._id}}as any)}
                                                            >
                                                                View
                                                            </div>:null}
                                                        </div>
                                                    </td>
                                                </tr>
                                            
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div></>:null}
                        </>
                    </div>}
                </div >
            </div>
            <AccountFeaturePopup
                show={show}
                handleClose={() => {
                    setShow(false)
                    Setpermissionflag(false)
                }}
                permissionflag={permissionflag}
                accountFeature={accountFeature}
                CheckAll={CheckAll}
                accountfeature={accountfeature}
                checkAll={checkAll}
                setVendor={setVendor}
                vendor={vendor}
                venderAll={venderAll}
                vendorext={vendorext}
                facilityAll={facilityAll}
                facilityext={facilityext}
                setFacility={setFacility}
                facility={facility}
                setService={setService}
                service={service}
                selectServices={selectServices}
                allService={allService}
                selectAllService={selectAllService}
            />
            <SuspendAccount show={suspendAccount}
                handleClose={() => setSuspendAccount(false)}
                suspendAccountBtn={suspendAccountBtn}
                accountId={accountDetailData?._id}
                setSuspendData={setSuspendData}
                suspendData={suspendData}

            />
        </div>
    )
}

export default ViewAccountDetail
