import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Dashboard' to='/dashboard' />
      <MenuItem title='Corporate' to='/corporate' />
      <MenuItem title='Troubleshooting' to='/trouble-shooting' />
      {/* <MenuItem title='Web Enquiries' to='/web-inquery' /> */}
      <MenuItem title='Payments' to='/payments' />
      <MenuItem title='Interests' to='/interests' />
    </>
  )
}
