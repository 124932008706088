import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-bootstrap';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';

const Request = () => {
    const navigate = useNavigate()
    const [tableData, setTableData] = React.useState<any>([5])
    const [formData, setFormData] = React.useState<any>()
    const [pageLimit, setPageLimit] = React.useState<any>()
    const [ServiceListCount, setServiceListCount] = React.useState<any>()
    const [requestId, setRequestId] = React.useState<any>()
    const [requestSource, setRequestSource] = React.useState<any>()
    const [requestStatus, setRequestStatus] = React.useState<any>()
    const [serviceList, setServiceList] = React.useState<any>()
    const [page, setPage] = React.useState<any>(1)

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }


    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }

    const getServiceListCount = async () => {

        await ApiGet(`admin/service_request/overview/count?corporateId=${window.location.pathname?.split('/')[4]}`)
            .then((res) => {
                setServiceListCount(res?.data?.data)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getServiceList = async () => {
        const body = {
            page: page,
            limit: 10,
            corporateId: window.location.pathname?.split('/')[4],
            serviceRequestId: requestId ? requestId : "",
            requestType: requestSource ? Number(requestSource) : '',
            requestStatus: requestStatus ? Number(requestStatus) : ''
        }
        await ApiPost(`admin/service_request/overview`, body)
            .then((res) => {
                setServiceList(res?.data?.data?.services_request)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    React.useEffect(() => {
        getServiceListCount()
        getServiceList()
    }, [requestId, requestSource, requestStatus, page])
    return (
        <div>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/account-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Account Details
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/payment-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4  ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Payments
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/user-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Users
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/property-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Propertise
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenancy-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenancies
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenant-detail/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenants
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {

                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Troubleshooting
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/job/${window.location.pathname?.split('/')[4]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4  ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Job
                                </a>
                            </li>
                        </ul>
                    </div>
                    <Row>
                        <Col lg={8}>
                            <div className='card-header align-items-center py-5  gap-md-2 d-flex' style={{ justifyContent: 'space-between' }}>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        Overall Requests:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {ServiceListCount?.overAllCount}
                                        </span>
                                    </h5>
                                </div>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        Pending Requests:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {ServiceListCount?.pendingRequestCount}
                                        </span>
                                    </h5>
                                </div>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        Requests Cancelled:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {ServiceListCount?.cancelledRequest}
                                        </span>
                                    </h5>
                                </div>
                                <div className='mb-10  min-w-200px' style={{ display: 'contents' }}>
                                    <h5 className='gray-500'>
                                        Pending Vendor Submission:{' '}
                                        <span className='' style={{ color: 'black' }}>
                                            {ServiceListCount?.pendingVendorSubmissionCount}
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <div className='mb-10'>
                        <Row>
                            <Col lg={3} md={3} className="d-flex align-items-center">
                                <input
                                    type='text'
                                    className='form-control form-control-solid solid'
                                    placeholder='Request ID'
                                    name='request_id'
                                    style={{ backgroundColor: "#fff" }}
                                    onChange={(e) => setRequestId(e.target.value)}
                                />
                            </Col>
                            <Col lg={3} md={3} className="d-flex align-items-center">
                                <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                    Request Source
                                </label>
                                <select
                                    name='request_source'
                                    className='form-select'
                                    onChange={(e) => setRequestSource(e.target.value)}
                                >
                                    <option selected value=''>
                                        Select
                                    </option>
                                    <option value={2}>
                                        Internal
                                    </option>
                                    <option value={1}>
                                        External
                                    </option>
                                    <option value={3}>
                                        Marketplace
                                    </option>
                                    <option value={0}>
                                        Tenant
                                    </option>

                                </select>
                            </Col>
                            <Col lg={3} md={3} className="d-flex align-items-center">
                                <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                    Request Status
                                </label>
                                <select
                                    name='request_status '
                                    className='form-select'
                                    onChange={(e) => setRequestStatus(e.target.value)}
                                >
                                    <option selected value=''>
                                        Select
                                    </option>
                                    <option value={0}>
                                        Draft
                                    </option>
                                    <option value={1}>
                                        Under Approval
                                    </option>
                                    <option value={1}>
                                        Pending Approval
                                    </option>
                                    <option value={2}>
                                        Recalled
                                    </option>
                                    <option value={3}>
                                        Rejected
                                    </option>
                                    <option value={4}>
                                        Approved
                                    </option>
                                </select>
                            </Col>
                        </Row>
                    </div>
                    <div className='card card-flush'>

                        <div className='card-body pt-0 table-responsive mt-5'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                        <th className='min-w-100px'> Request ID </th>
                                        <th className='min-w-100px'>  Service Name </th>
                                        <th className='min-w-70px'>Request Source</th>
                                        <th className='min-w-70px'> Request Status</th>
                                        <th className='min-w-70px'>  Assigned To </th>
                                        <th className='min-w-70px'> Handled By</th>
                                        <th className='min-w-70px'>Total Development</th>
                                        <th className='min-w-70px'>Building Manager </th>
                                        <th className='min-w-70px'>Total Units </th>
                                        <th className='min-w-70px'> Total Jobs   </th>
                                        <th className='min-w-70px'>Request Amount </th>
                                        <th className='min-w-70px'>Request Urgency </th>
                                        <th className='min-w-70px'>Requestor Type </th>
                                        <th className='min-w-70px'>Requestor</th>
                                        <th className='min-w-70px'>Completed By</th>
                                        <th className='min-w-70px'>Pending With</th>
                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {serviceList?.length ? serviceList?.map((f: any, i: any) => {
                                        console.info('f++', f)
                                        return (
                                            <tr>
                                                <td className='text-center' style={{ cursor: 'pointer' }}>
                                                    <a style={{ textDecoration: 'underline', color: ' #1890ff' }}>{f?.id}</a>
                                                </td>
                                                <td className='text-center'>{f?.main_service?.map((i: any) => i?.name)} & {f?.sub_service
                                                    ?.map((i: any) => i?.name)}</td>
                                                <td className='text-center'>{f?.requestType === 0 ? 'Tenant' :
                                                    f?.requestType === 1 ? 'External' :
                                                        f?.requestType === 2 ? 'internal' : ''}</td>
                                                <td className='text-center'>{f?.requestStatus === 0 ? 'Draft' :
                                                    f?.requestStatus === 1 ? 'Approval' :
                                                        f?.requestStatus === 2 ? 'Recalled' :
                                                            f?.requestStatus === 3 ? 'Rejected' :
                                                                f?.requestStatus === 4 ? 'Approved' :
                                                                    f?.requestStatus === 5 ? 'Vendor Confirmation' :
                                                                        f?.requestStatus === 6 ? 'Accepted' :
                                                                            f?.requestStatus === 7 ? 'Booked' :
                                                                                f?.requestStatus === 8 ? 'Variation Request' :
                                                                                    f?.requestStatus === 9 ? 'Cancel' :
                                                                                        ''
                                                } </td>
                                                < td className='text-center'>{f?.staff[0]?.name} </td>
                                                <td className='text-center'>{f?.requestType === 2 ? 'Intrenal Team' : f?.vendor[0]?.companyName}</td>
                                                <td className='text-center'>{f?.vendorStatus === 0 ? 'Not Submitted' : 'Accepted'}</td>
                                                <td className='text-center'>{f?.totalDevelopment}</td>
                                                <td className='text-center'>{f?.totalProperties}</td>
                                                <td className='text-center'>{f?.totalProperties}</td>
                                                <td className='text-center'></td>
                                                <td className='text-center'>{f?.requestUrgency === 0 ? 'Standard' : 'Emergency'}</td>
                                                <td className='text-center'>{f?.requestorType === 0 ? 'Staff' : 'Tenant'}</td>
                                                <td className='text-center'>{f?.requestor[0]?.fullName}</td>
                                                <td className='text-center'>{f?.approval_request[0]?.complete_with[0]?.user[0]?.fullName}</td>
                                                <td className='text-center'>{f?.approval_request[0]?.pending_with[0]?.user[0]?.fullName}</td>
                                            </tr>
                                        )
                                    })

                                    : <tr><td colSpan={12} className="100 text-center"> No Data Found</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                            </div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                <div
                                    className='dataTables_paginate paging_simple_numbers'
                                    id='kt_ecommerce_sales_table_paginate'
                                >
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        pageClassName="paginate_button page-item +"
                                        pageLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        previousClassName="paginate_button page-item previous disabled"
                                        nextClassName="paginate_button page-item next"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageLimit}
                                        previousLabel="<"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Request
