import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Tempate from '../modules/Tempate'
import Corporate from '../modules/Corporate/Corporate'
import Dashboard from '../modules/Dashboard'
import AccountDetail from '../modules/Corporate/AccountDetail'
import PaymentDetail from '../modules/Corporate/PaymentDetail'
import WebInquery from '../modules/WebInquiry/WebInquery'
import Troubleshooting from '../modules/Troubleshooting'
import Payment from '../modules/Payment'
import GenerateReceipt from '../modules/Corporate/GenerateReceipt'
import IssueInvoice from '../modules/Corporate/IssueInvoice'
import User from '../modules/Corporate/User'
import Property from '../modules/Corporate/Property'
import Tenancy from '../modules/Corporate/Tenancy'
import Tenant from '../modules/Corporate/Tenant'
import ViewAccountDetail from '../modules/Corporate/ViewAccountDetail'
import SubscriptionDetail from '../modules/Corporate/SubscriptionDetails'
import ViewGenerateReceiptData from '../modules/Corporate/ViewGenerateReceiptData'
import ViewInvoiceData from '../modules/Corporate/ViewInvoiceData'
import Buildings from '../modules/Corporate/Buildings'
import Communities from '../modules/Corporate/Communities'
import Request from '../modules/Corporate/Request'
import Job from '../modules/Corporate/Job'
import InvoiceHtml from '../modules/Corporate/InvoiceHtml'
import Interest from '../modules/Interest/Interest'
import InterestDetails from '../modules/Interest/InterestDetails'

const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/corporate' />} />
        {/* Pages */}
        <Route path='corporate' element={<Corporate />} />
        {/* Lazy Modules */}
        <Route
          path='/dashboard'
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/trouble-shooting'
          element={
            <SuspensedView>
              <Troubleshooting />
            </SuspensedView>
          }
        />
        <Route
          path='/web-inquery'
          element={
            <SuspensedView>
              <WebInquery />
            </SuspensedView>
          }
        />
        <Route
          path='/payments'
          element={
            <SuspensedView>
              <Payment />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/account-detail'
          element={
            <SuspensedView>
              <AccountDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/account-detail/:id'
          element={
            <SuspensedView>
              <ViewAccountDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/view-subscription-detail/:id'
          element={
            <SuspensedView>
              <SubscriptionDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/payment-detail/:id'
          element={
            <SuspensedView>
              <PaymentDetail />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/payment-detail'
          element={
            <SuspensedView>
              <PaymentDetail />
            </SuspensedView>
          }
        />

        <Route
          path='/corporate/generate-receipt/:id'
          element={
            <SuspensedView>
              <GenerateReceipt />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/view-generate-receipt/:id'
          element={
            <SuspensedView>
              <ViewGenerateReceiptData />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/view-invoice-Data/:id'
          element={
            <SuspensedView>
              <ViewInvoiceData />
            </SuspensedView>
          }
        />

        <Route
          path='/corporate/issue-invovice/:id'
          element={
            <SuspensedView>
              <IssueInvoice />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/user-detail/:id'
          element={
            <SuspensedView>
              <User />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/property-detail/:id'
          element={
            <SuspensedView>
              <Property />
            </SuspensedView>
          }
        />
         <Route
          path='/corporate/property-detail'
          element={
            <SuspensedView>
              <Property />
            </SuspensedView>
          }
        />
         <Route
          path='/corporate/property-detail/buildings'
          element={
            <SuspensedView>
              <Buildings />
            </SuspensedView>
          }
        />
         <Route
          path='/corporate/property-detail/buildings/:id'
          element={
            <SuspensedView>
              <Buildings />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/property-detail/communities/:id'
          element={
            <SuspensedView>
              <Communities   />
            </SuspensedView>
          }
        />
          <Route
          path='/corporate/property-detail/communities'
          element={
            <SuspensedView>
              <Communities   />
            </SuspensedView>
          }
        />


        <Route
          path='/corporate/tenancy-detail'
          element={
            <SuspensedView>
              <Tenancy />
            </SuspensedView>
          }
        />
           <Route
          path='/corporate/tenancy-detail/:id'
          element={
            <SuspensedView>
              <Tenancy />
            </SuspensedView>
          }
        />
        <Route
          path='/corporate/tenant-detail/:id'
          element={
            <SuspensedView>
              <Tenant />
            </SuspensedView>
          }
        />
          <Route
          path='/corporate/tenant-detail'
          element={
            <SuspensedView>
              <Tenant />
            </SuspensedView>
          }
        />
         <Route
          path='/corporate/request/request'
          element={
            <SuspensedView>
              <Request />
            </SuspensedView>
          }
        />
          <Route
          path='/corporate/request/request/:id'
          element={
            <SuspensedView>
              <Request />
            </SuspensedView>
          }
        />
         <Route
          path='/corporate/request/job'
          element={
            <SuspensedView>
              <Job />
            </SuspensedView>
          }
        />
         <Route
          path='/invoiceHtml'
          element={
            <SuspensedView>
              <InvoiceHtml />
            </SuspensedView>
          }
        />
         <Route
          path='/corporate/request/job/:id'
          element={
            <SuspensedView>
              <Job />
            </SuspensedView>
          }
        />

        <Route
          path='/interests'
          element={
            <SuspensedView>
              <Interest />
            </SuspensedView>
          }
        />
        <Route
          path='/interest/:id'
          element={
            <SuspensedView>
              <InterestDetails />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
