import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import moment from 'moment';
import { DatePicker } from 'antd'

const PaymentDetail = () => {
    const navigate = useNavigate()
    const [tableData, setTableData] = React.useState<any>([5])
    const [formData, setFormData] = React.useState<any>()
    const [pageLimit, setPageLimit] = React.useState<any>()
    const [receiptPage, setReceiptPage] = React.useState<any>(1)
    const [accountDetailData, setAccountDetailData] = React.useState<any>()
    const [invoiceData, setInvoiceData] = React.useState<any>()
    const [receiptData, setReceiptData] = React.useState<any>()
    const [receiptLimit, setReceiptLimit] = React.useState<any>()
    const [page, setPage] = React.useState<any>(1)
    const [Invoice, SetInvoice] = React.useState<any>(true)
    const [sorti, Setsorti] = React.useState<any>()
    const [start, setstart] = React.useState<any>()
    const [end, setend] = React.useState<any>()


    const { RangePicker } = DatePicker
    
    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }


    React.useEffect(() => {
        viewAccountDetail()
    }, [])

    const viewAccountDetail = async () => {
        await ApiGet(`admin/corporate/${window.location.pathname?.split('/')[3]}`)
            .then((res) => {
                setAccountDetailData(res?.data?.data[0])
            })
            .catch((e) => {
                console.log('e', e)
            })
    }

    const getInvoiceData = async () => {
        let body:any = {
            page: page,
            limit: 10,
            corporateId: window.location.pathname?.split('/')[3]
        }
        if(sorti) body.sortType = sorti
        if(start) body.startDate = start
        if(end) body.endDate = end

        await ApiPost(`admin/corporate_invoice/get`, body)
            .then((res) => {
                setInvoiceData(res?.data?.data?.corporate_invoice_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    const getReceiptData = async () => {
        const body = {
            page: receiptPage,
            limit: 10,
            corporateId: window.location.pathname?.split('/')[3]
        }
        await ApiPost(`admin/corporate_receipt/get`, body)
            .then((res) => {
                setReceiptData(res?.data?.data?.corporate_receipt_data
                )
                setReceiptLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((e) => {
                console.log('e', e)
            })
    }
    React.useEffect(() => {
       if(window.location.pathname?.split('/')[3]){
        getInvoiceData()
       } 
    }, [page,window.location.pathname?.split('/')[3],sorti,start,end])

    React.useEffect(() => {
        if(window.location.pathname?.split('/')[3]){
        getReceiptData()
        }
    }, [receiptPage,window.location.pathname?.split('/')[3]])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }
    const handleReceiptPageClick = (event: any) => {
        setReceiptPage(event.selected + 1)
    }

    return (
        <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/account-detail/${window.location.pathname?.split('/')[3]}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Account Details
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/payment-detail/${window.location.pathname?.split('/')[3]}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4  active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Payments
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/user-detail/${window.location.pathname?.split('/')[3]}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Users
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/property-detail/${window.location.pathname?.split('/')[3]}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Propertise
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/tenancy-detail/${window.location.pathname?.split('/')[3]}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Tenancies
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/corporate/tenant-detail/${window.location.pathname?.split('/')[3]}`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Tenants
                            </a>
                        </li>
                        <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                        <li
                            className='nav-item'
                            onClick={() => {

                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Troubleshooting
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                SetInvoice(true)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Invoice
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                SetInvoice(false)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                Receipts
                            </a>
                        </li>
                       
                    </ul>
                </div>
                
                {Invoice?<div className='card card-flush'>

                    <div className='card-body pt-0 table-responsive mt-5'>
                        {/* <div className='d-flex'> */}
                            <div>
                            <div
              className='card-header align-items-center py-5 gy-3 row'
              style={{ justifyContent: 'flex-start' }}
            >
                <div className='mb-2 min-w-200px col-md-3 px-10'>
                {/* <label className='form-label fw-semibold'>Development</label> */}
                <div className='d-flex justify-content-between'>
                                    <h6 >Invoice</h6>
                                    
                                </div>
              </div>
              <div className='mb-2 min-w-200px col-md-3 px-10'>
                {/* <label className='form-label fw-semibold'>Development</label> */}
                <div>
                  <select
                    className='form-select'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-dropdown-parent='#kt_menu_631f08e971923'
                    data-allow-clear='true'
                    name='developmentType'
                    onChange={(e) => Setsorti(e.target.value)}
                  >
                    <option value='' selected>
                      Sort
                    </option>
                    <option value='ascending'>Ascending</option>
                    <option value='descending'>Descending</option>
                  </select>
                </div>
              </div>
              <div className='mb-2 min-w-200px col-md-6 px-10'>
              <div>
                            <div className='d-flex align-items-center'>
                                {/* <label htmlFor='exampleFormControlInput1' className='form-label'>
                                    Start - End
                                </label> */}
                                <RangePicker
                                    // defaultValue={[
                                    //     moment(start !== '' ? moment(start) : new Date(), dateFormat),
                                    //     moment(end !== '' ? moment(end) : new Date(), dateFormat),
                                    // ]}
                                    // {...register("start", { required: true })}
                                    // format={dateFormat}
                                    onChange={(e:any)=>{
                                        if(e==null){
                                    setstart("");setend("")
                                    }else{
                                        setstart(e[0]?._d);setend(e[1]?._d)
                                    }}}
                                    style={{"height":"43px"}}
                                    name='start'
                                    className='form-control'
                                />
                            </div>
                        </div>
              </div>
              
            </div>
                                {/* <div className='d-flex justify-content-between'>
                                    <h6 >Invoice</h6>
                                    
                                </div> */}


                                <table
                                    className='table align-middle table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                >
                                    <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                            <th className='min-w-100px'>Invoice </th>
                                            <th className='min-w-100px'> Total Amount</th>
                                            <th className='min-w-70px'>Outstanding</th>
                                            <th className='min-w-70px'>Purchase Order</th>
                                            <th className='min-w-70px'> Issue Date </th>
                                           
                                            <th className='min-w-70px'>Due Date</th>
                                            <th className='min-w-70px'> Invoice Status </th>
                                            <th className='min-w-70px'>Payment Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                        {invoiceData?.length>0 ?invoiceData?.map((v: any, i: any) => {
                                            return (
                                                <tr>
                                                    <td>{v?.id}</td>
                                                    <td>{(v?.totalAmount)?.toFixed(2)}</td>
                                                    <td>{(v?.outstandingAmount)?.toFixed(2)}</td>
                                                    <td>{v?.purchaseOrder}</td>
                                                    <td>{moment(v?.issueDate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(v?.dueDate).format('DD/MM/YYYY')}</td>
                                                    <td>{v?.saveStatus === 0 ? "Not Submitted" : "Submitted" }</td>

                                                    <td>{v?.paymentStatus === 0 ? 'Not Paid'
                                                        : v?.paymentStatus === 1 ? 'Partial Paid' :v?.paymentStatus === 2 ? 'Paid':'-'}</td>
                                                    <td className='text-center'>
                                                        <div className='menu-item px-3'>
                                                            <div
                                                                className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                                onClick={() => navigate(`/corporate/view-generate-receipt/${v?._id}`)}
                                                            >
                                                                View
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : <p>No Data Found</p>
                                        }
                                    </tbody>
                                </table>
                                <div className='row mb-5'>
                                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                                    </div>
                                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                                        <div
                                            className='dataTables_paginate paging_simple_numbers'
                                            id='kt_ecommerce_sales_table_paginate'
                                        >
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                pageClassName="paginate_button page-item +"
                                                pageLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                previousClassName="paginate_button page-item previous disabled"
                                                nextClassName="paginate_button page-item next"
                                                previousLinkClassName="page-link"
                                                nextLinkClassName="page-link"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={pageLimit}
                                                previousLabel="<"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        {/* </div> */}
                    </div>
                </div>:<div className='card card-flush'>

<div className='card-body pt-0 table-responsive mt-5'>
    
        <div>
            <div className='d-flex justify-content-between'>
                <h6 >Receipts</h6>
            </div>
            <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
            >
                <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                        <th className='min-w-100px'>Receipt </th>
                        <th className='min-w-100px'> Invoice</th>
                        <th className='min-w-70px'> Amount Paid</th>
                        <th className='min-w-70px'>Issue Date</th>
                        <th className='min-w-70px'> Payment Date </th>
                        <th className='min-w-70px'> Receipt Status </th>
                        <th className='min-w-70px'>Payment Method</th>
                        <th className='min-w-70px'></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                    {receiptData?.length >0 ? receiptData?.map((v: any, i: any) => {
                        return (
                            <tr>
                                <td>{v?.id}</td>
                                <td>{v?.corporate_invoice[0]?.id}</td>
                                <td>{Number( v?.paidAmount)?.toFixed(2)}</td>
                                <td>{v?.saveStatus === 1 ?
                                    moment(v?.issueDate).format('DD/MM/YYYY') : 'Draft'}</td>
                                <td>{moment(v?.paymentDate).format('DD/MM/YYYY')}</td>
                                <td>{v?.saveStatus === 0 ? "Draft" : "Active" }</td>
                                <td>{v?.paymentMethod === 0 ? 'Bank Transfer' : v?.paymentMethod === 1 ? 'Cheque' : ' Cash'}</td>
                                <td className='text-center'>
                                    <div className='menu-item px-3'>
                                        <div
                                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                            onClick={() => navigate(`/corporate/view-invoice-Data/${v?._id}`)}
                                        >
                                            View
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                    :<p>NO Data Found</p>
                    }
                </tbody>
            </table>
            <div className='row mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                    <div
                        className='dataTables_paginate paging_simple_numbers'
                        id='kt_ecommerce_sales_table_paginate'
                    >
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            pageClassName="paginate_button page-item +"
                            pageLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            previousClassName="paginate_button page-item previous disabled"
                            nextClassName="paginate_button page-item next"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            onPageChange={handleReceiptPageClick}
                            pageRangeDisplayed={5}
                            pageCount={receiptLimit}
                            previousLabel="<"
                        />
                    </div>
                </div>
            </div>
        </div>
    
</div>
</div>}
            </div>

        </div>
    )
}

export default PaymentDetail
