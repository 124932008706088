import React from 'react'

const InvoiceHtml = () => {
    return (
        <>
            <table style={{ maxWidth: '1200px', margin: '50px auto 10px', backgroundColor: '#fff', padding: '50px', }}>

                <tbody>
                    <tr>
                        <td style={{ height: '35px' }}></td>
                    </tr>
                    <tr>

                        <td> <img src='/media/logos/WhatsAppImage.png' style={{ height: '150px' }} /></td>
                        <td> </td>
                        <td> </td>

                        <td style={{ padding: '10px 20px' }}>
                            <h3> TAX INVOICE</h3>

                            <p style={{ fontSize: '14px', margin: '0 0 6px 0' }}><span style={{ fontWeight: 'bold', display: 'inlineBlock', minWidth: '150px' }}>Propertise:</span><b style={{ color: 'green', fontWeight: 'normal', margin: "0" }}>FZCO</b></p>
                            <p style={{ fontSize: '14px', margin: "0 0 6px 0" }}><span style={{ fontWeight: 'bold', display: "inlineBlock", minWidth: "146px" }}>Address:</span> Dubai Silicon Oasis, DDP, Building A2, Dubai, United Arab Emirates</p>
                            <p style={{ fontSize: "14px", margin: "0 0 0 0;" }}><span style={{ fontWeight: 'bold', display: "inlineBlock", minWidth: "146px" }}>Tel:</span> +971 50 351 59 74 </p>
                            <p style={{ fontSize: "14px", margin: "0 0 0 0;" }}><span style={{ fontWeight: 'bold', display: "inlineBlock", minWidth: "146px" }}> Email:</span> Admin@propertise.com  </p>
                            <p style={{ fontSize: "14px", margin: "0 0 0 0;" }}><span style={{ fontWeight: 'bold', display: "inlineBlock", minWidth: "146px" }}>Website:</span> www.propertise.com </p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ height: "35px" }}></td>
                    </tr>
                    <tr>
                        <td style={{ height: '35px' }}></td>
                    </tr>

                    <tr>
                        <td style={{ width: "30%", padding: "20px", verticalAlign: "top" }}>
                            <h3> Billed To</h3>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px;" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Company Name :</span>Palash Basak </p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px;" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Country :</span> palash@gmail.com</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>City :</span> +91-1234567890</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Address :</span> 2556-1259-9842</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>TRN :</span> 2556-1259-9842</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Phone :</span> 2556-1259-9842</p>
                        </td>
                        <td style={{ width: "30%", padding: "20px", verticalAlign: "top" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontWeight: "bold", fontSize: "13px" }}>Invoice Issue Date</span> 11/1/2013</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontWeight: "bold", fontSize: "13px" }}>Payment Due Date</span> 11/1/2013</p>
                        </td>
                        <td style={{ width: "30%", padding: "20px", verticalAlign: "top" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontWeight: "bold", fontSize: "13px" }}>Invoice</span>  ACCOUNT ID_2_0001</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontWeight: "bold", fontSize: "13px" }}>Purchase Order</span> 2</p>
                        </td>
                        <td style={{ width: "30%", padding: "20px", verticalAlign: "top" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontWeight: "bold", fontSize: "13px" }}>Amount Due</span>  ACCOUNT ID_2_0001</p>
                        </td>

                    </tr>
                    <tr>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>Invoice Details</td>

                    </tr>
                    <tr>
                        <td style={{ width: "40%", padding: "20px", verticalAlign: "top" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px;" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Subscription Type:</span>Free Demo </p>
                        </td>
                        <td style={{ width: "40%", padding: "20px", verticalAlign: "top" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "inlineBlock", fontWeight: "bold", fontSize: "13px" }}>Start Date</span> 11/1/2013</p>
                        </td>
                        <td style={{ width: "40%", padding: "20px", verticalAlign: "top" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "inlineBlock", fontWeight: "bold", fontSize: "13px" }}>End Date</span> 11/1/2013</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "40%", padding: "20px", verticalAlign: "top" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px;" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Payment Status:</span>Partially Paid </p>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>ITEM NO</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>DESCRIPTION</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>QTY.</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>UNIT PRICE (AED)</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>AMOUNT (AED)</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>DISCOUNT (%)</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>TAXABLE AMOUNT(AED)</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>VAT RATE(%)</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>VAT AMOUNT (AED)</td>
                        <td style={{ fontSize: "20px", padding: "30px 15px 0 15px" }}>TOTAL AMOUNT (AED)</td>
                    </tr>
                    <tr>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>1</span>
                            </p>

                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>500</span>
                            </p>

                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>2</span>
                            </p>

                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>12</span>
                            </p>

                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>24</span>
                            </p>

                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>24</span> Rs. 3500
                            </p>

                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>24</span>
                            </p>
                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>24</span>
                            </p>

                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>24</span>
                            </p>
                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ fontSize: "14px", margin: "0", padding: "10px", border: "solid 1px #ddd", fontWeight: "bold" }}>
                                <span style={{ display: "block", fontSize: "13px", fontWeight: "normal" }}>24</span>
                            </p>

                        </td>
                    </tr>
                    <tr>
                        <td>

                        </td>
                        <td>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px;" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Sub Total :</span>12200</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px;" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Total VAT :</span>2000 </p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Total Discount :</span> 7890</p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: 'inlineBlock', fontWeight: "bold", fontSize: "13px" }}>Total Amount Due:</span> 9842</p>
                        </td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: "15px" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontWeight: "bold", fontSize: "13px" }}>Terms & conditions</span></p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontSize: "13px" }}> Please make the payment by the due date</span></p>
                        </td>
                        <td style={{ padding: "15px" }}>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontWeight: "bold", fontSize: "13px" }}>Authorised Signatory</span></p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontSize: "13px" }}> Name</span></p>
                            <p style={{ margin: "0 0 10px 0", padding: "0", fontSize: "14px" }}><span style={{ display: "block", fontSize: "13px" }}> Designation</span></p>

                        </td>
                    </tr>

                </tbody>
            </table>

        </>
    )
}

export default InvoiceHtml
