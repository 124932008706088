import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';

const User = () => {
    const navigate = useNavigate()
    const [tableData, setTableData] = React.useState<any>([5])
    const [formData, setFormData] = React.useState<any>()
    const [pageLimit, setPageLimit] = React.useState<any>()

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handlePageClick = () => {

    }
    return (
        <div>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/account-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Account Details
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/payment-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4  ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Payments
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/user-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Users
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/property-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Propertise
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenancy-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenancies
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/tenant-detail/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Tenants
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/corporate/request/request/${window.location.pathname?.split('/')[3]}`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Requests
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {

                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab' style={{ cursor: 'pointer' }}>
                                    Troubleshooting
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='card card-flush'>

                        <div className='card-body pt-0 table-responsive mt-5'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-transform gs-0'>
                                        <th className='min-w-100px'>User ID </th>
                                        <th className='min-w-100px'> User Type </th>
                                        <th className='min-w-70px'>First Name</th>
                                        <th className='min-w-70px'>Last Name </th>
                                        <th className='min-w-70px'>  Email ID  </th>
                                        <th className='min-w-70px'> Password</th>
                                        <th className='min-w-70px'>Last Login</th>
                                        <th className='min-w-70px'>Reset Password Status</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {tableData?.map((v: any, i: any) => {
                                        return (
                                            <tr>
                                                <td>##</td>
                                                <td>##</td>
                                                <td>##</td>
                                                <td>##</td>
                                                <td>##</td>
                                                <td>##</td>
                                                <td>##</td>
                                                <td>##</td>

                                                <td className='text-center'>
                                                    <div className='menu-item px-3'>
                                                        <div
                                                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                        >
                                                            View
                                                        </div>
                                                    </div>

                                                </td>
                                                <td className='text-center'>
                                                    <div className='menu-item px-3'>
                                                        <div
                                                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                        >
                                                            Reset Password
                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className='row mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                    </div>
                    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                        <div
                            className='dataTables_paginate paging_simple_numbers'
                            id='kt_ecommerce_sales_table_paginate'
                        >
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                pageClassName="paginate_button page-item +"
                                pageLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                previousClassName="paginate_button page-item previous disabled"
                                nextClassName="paginate_button page-item next"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageLimit}
                                previousLabel="<"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default User
